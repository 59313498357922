var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('CSpinner',{staticClass:"loading",attrs:{"color":"info"}}),_c('br'),_c('div',{staticClass:"text-center"},[_c('CButton',{attrs:{"color":"secondary","size":"sm"},on:{"click":_vm.reloadPage}},[_vm._v(" Refresh ")])],1)],1):_c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardBody',[_c('Search',{ref:"search",attrs:{"columns":this.search}})],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[(Object.keys(_vm.items).length === 0)?_c('CCard',[_c('CCardBody',[_c('h3',[_vm._v(_vm._s(_vm.$t("system.search"))+":")]),_vm._v(" "+_vm._s(_vm.$t("message.search-empty"))+". ")])],1):_c('CCard',[_c('CCardBody',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t("integration-process.campaign-name"))+" ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t("system.name")))]),_c('th',{staticStyle:{"min-width":"250px"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t("system.description"))+" ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t("integration-process.type")))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t("xml-manager.format")))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t("system.language")))]),_c('th',{attrs:{"scope":"col"}},[_vm._v("URL")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" ")])])]),_c('tbody',[_vm._l((_vm.items),function(item,index){return _vm._l((item),function(key){return _c('tr',{key:key.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{attrs:{"scope":"row"}},[(
                            key.name === null ||
                              key.name === 'null' ||
                              key.name === ''
                          )?[_vm._v(" - ")]:[_vm._v(" "+_vm._s(key.name)+" ")]],2),_c('td',[(
                            key.description === null ||
                              key.description === 'null' ||
                              key.description === ''
                          )?[_vm._v(" - ")]:[_vm._v(" "+_vm._s(_vm._f("truncate")(key.description,40, "..."))+" "),_c('CButton',{attrs:{"color":"info","variant":"ghost","size":"sm"},on:{"click":function($event){key.warningModal = true}}},[_vm._v(" "+_vm._s(_vm.$t("system.more"))+" ")]),_c('CModal',{attrs:{"title":_vm.$t('system.description'),"color":"info","show":key.warningModal},on:{"update:show":function($event){return _vm.$set(key, "warningModal", $event)}}},[_vm._v(" "+_vm._s(key.description)+" ")])]],2),_c('td',[_c('c-badge',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.$store.state.enumerators.enumerators["feed-type"][ key.type ]))])],1),_c('td',[_c('c-badge',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.$store.state.enumerators.enumerators["feed-format"][ key.format ]))])],1),_c('td',[_c('c-badge',{attrs:{"color":"warning"}},[_vm._v(_vm._s(_vm.$store.state.enumerators.enumerators["language"][ key.language ]))])],1),(
                          key.is_secret === 1 &&
                            !_vm.$options.filters.validURL(key.url)
                        )?_c('td',[_c('c-badge',{attrs:{"color":"danger"}},[_vm._v(_vm._s(_vm.$t("xml-manager.access_feed"))+" "+_vm._s(key.url))])],1):_c('td',[_c('input',{ref:'xmlUrl' + key.id,refInFor:true,staticClass:"form-control",attrs:{"disabled":""},domProps:{"value":key.url}}),(
                            key.is_secret === 1 &&
                              _vm.$options.filters.validURL(key.url)
                          )?_c('span',{staticClass:"text-info font-xs"},[_vm._v(_vm._s(_vm.$t("xml-manager.url-info-text")))]):_vm._e()]),_c('td',{staticClass:"text-right"},[(
                            key.is_secret === 0 ||
                              (key.is_secret === 1 &&
                                _vm.$options.filters.validURL(key.url))
                          )?_c('CButton',{staticClass:"mr-2",attrs:{"color":"info","variant":"outline"},on:{"click":function($event){return _vm.copyText(_vm.$refs['xmlUrl' + key.id][0])}}},[_vm._v(_vm._s(_vm.$t("system.copy"))+" URL")]):_vm._e()],1)])})})],2)])])])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }