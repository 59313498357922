import { render, staticRenderFns } from "./MessagingIndex.vue?vue&type=template&id=549e4784&"
import script from "./MessagingIndex.vue?vue&type=script&lang=js&"
export * from "./MessagingIndex.vue?vue&type=script&lang=js&"
import style0 from "./MessagingIndex.vue?vue&type=style&index=0&id=549e4784&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports