<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.xml-manager") }}
            </h4>
            <div class="small text-muted">{{ $t("crud.list") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButton color="success" square size="sm" to="/xml-manager/create">
              +
              {{ $t("system.add") + " " + $t("system.new") + " XML" }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <Search ref="search" v-bind:columns="this.search" />
          </CCol>
          <CCol sm="12">
            <Sort ref="sort" />
            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable
              v-else
              :items="items"
              :fields="fields"
              :sorter="{ external: true, resetable: false }"
              hover
              @update:sorter-value="$refs.sort.sortTable"
            >
              <template #campaign_name="{item}">
                <td>
                  {{ item.campaign_name }}
                  <small>({{ item.campaign_id }})</small>
                </td>
              </template>
              <template #name="{item}">
                <td
                  v-if="
                    item.name === null ||
                      item.name === 'null' ||
                      item.name === ''
                  "
                >
                  -
                </td>
                <td v-else>
                  {{ item.name }}
                </td>
              </template>
              <template #description="{item}">
                <td>
                  <CButton
                    v-if="item.description"
                    color="secondary"
                    variant="outline"
                    square
                    size="sm"
                    @click="toggleDetails(item.id)"
                  >
                    {{
                      Boolean(item._toggled) ? "Hide" : $t("system.description")
                    }}
                  </CButton>
                </td>
              </template>
              <template #url="{item}">
                <td>
                  <CButton
                    color="info"
                    variant="outline"
                    square
                    size="sm"
                    @click="toggleDetails(item.id)"
                  >
                    {{ Boolean(item._toggled) ? "Hide" : $t("system.detail") }}
                  </CButton>
                </td>
              </template>
              <template #details="{item, index}">
                <CCollapse
                  :show="Boolean(item._toggled)"
                  :duration="collapseDuration"
                >
                  <CCardBody>
                    <p v-if="item.description">
                      <strong>{{ $t("system.description") }}:</strong>
                      {{ item.description }}
                    </p>
                    <p></p>
                    <strong>Url: </strong><br />
                    <input
                      class="form-control"
                      :ref="'xmlUrl' + index"
                      :value="item.url"
                    /><br />
                    <CButton
                      v-on:click="copyText($refs['xmlUrl' + index])"
                      size="sm"
                      class="mr-2"
                      color="info"
                      variant="outline"
                      >{{ $t("system.copy") }} URL</CButton
                    >
                    <br />
                  </CCardBody>
                </CCollapse>
              </template>
              <template #type="{item}">
                <td>
                  <CBadge color="info">
                    {{
                      $store.state.enumerators.enumerators["feed-type"][
                        item.type
                      ]
                    }}
                  </CBadge>
                </td>
              </template>
              <template #format="{item}">
                <td>
                  <CBadge color="default">
                    {{
                      $store.state.enumerators.enumerators["feed-format"][
                        item.format
                      ]
                    }}
                  </CBadge>
                </td>
              </template>
              <template #language="{item}">
                <td>
                  <CBadge color="default">
                    {{
                      $store.state.enumerators.enumerators["language"][
                        item.language
                      ]
                    }}
                    <small>{{ item.language }}</small>
                  </CBadge>
                </td>
              </template>
              <template #is_secret="{item}">
                <td>
                  <CBadge color="success" v-if="item.is_secret === 1">
                    <CIcon name="cil-check" />
                  </CBadge>
                  <CBadge color="danger" v-else>
                    <CIcon name="cil-XCircle" />
                  </CBadge>
                </td>
              </template>
              <template #is_in_product_service="{item}">
                <td>
                  <CBadge
                    color="success"
                    v-if="item.is_in_product_service === 1"
                  >
                    <CIcon name="cil-check" />
                  </CBadge>
                  <CBadge color="danger" v-else>
                    <CIcon name="cil-XCircle" />
                  </CBadge>
                </td>
              </template>
              <template #last_download_at="{item}">
                <td>
                  {{ dateFormat(item.download_at) }}
                </td>
              </template>
              <template #updated_at="{item}">
                <td>
                  {{ dateFormat(item.updated_at) }}
                </td>
              </template>
              <template #button="{item, index}">
                <td class="py-2 text-right">
                  <CButton
                    size="sm"
                    color="info"
                    variant="outline"
                    class="ml-1"
                    :to="{ path: '/xml-manager/view/' + item.id }"
                  >
                    {{ $t("system.show") }}
                  </CButton>
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="{ path: '/xml-manager/edit/' + item.id }"
                  >
                    {{ $t("system.update") }}
                  </CButton>
                  <CForm class="d-inline">
                    <CButton
                      size="sm"
                      color="danger"
                      class="ml-1"
                      v-on:click="deleteElement(item.id, index)"
                    >
                      {{ $t("system.delete") }}
                    </CButton>
                  </CForm>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
        <Pagination
          :totalCount="this.page.totalCount"
          :perPage="this.page.perPage"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Pagination from "@/components/ui/Pagination";
import Search from "@/components/ui/Search";
import Sort from "@/components/ui/Sort";
import moment from "moment";

export default {
  name: "Users",
  components: {
    Pagination,
    Search,
    Sort
  },
  data() {
    return {
      loading: true,
      items: [],
      collapseDuration: 0,
      page: {
        totalCount: 0,
        perPage: 0
      },
      search: [
        {
          name: "campaign_id",
          options: [],
          label: i18n.t("integration-process.campaign-name"),
          type: "select2",
          operator: "or",
          like: false
        }
      ]
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    pagination() {
      this.getXml();
    },

    copyText(ref) {
      let textToCopy = ref;
      textToCopy.select();
      document.execCommand("copy");
    },

    dateFormat(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
    },

    toggleDetails(item) {
      let el = this.items.find(x => x.id === item);
      // el._toggled = !el._toggled;
      this.$set(el, "_toggled", !el._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },

    getXml() {
      this.loading = true;
      let self = this;

      let query = this.$refs.search.parseUrlQuery();

      crud.get("/xml-manager/feeds" + query).then(function(response) {
        //get data with deleted or without
        let filter = self.$options.filters.parseUrlQueryFilter(
          self.$route.query
        );

        if (!filter) {
          self.items = response.data.items.filter(function(e) {
            return e.status !== 0;
          });
        } else {
          self.items = response.data.items;
        }

        self.items = self.items.map((item, index) => {
          self.$set(item, "_toggled", false);
          return { ...item, index };
        });

        self.selectSearchOptions();

        self.page.totalCount = response.data._meta.totalCount;
        self.page.perPage = response.data._meta.perPage;

        self.loading = false;
      });
    },

    selectSearchOptions() {
      let self = this;
      crud
        .get("pap/campaigns?fields=id,name&per-page=100000")
        .then(function(response) {
          self.search[0].options = response.data.items;
        });
    },

    deleteElement(id, index) {
      if (confirm(i18n.t("confirmation.delete"))) {
        let self = this;
        crud
          .delete("xml-manager/feeds/" + id)
          .then(function(response) {
            if (response.status === 204) {
              self.items.splice(index, 1);
              self.page.totalCount -= 1;

              self.addNotification({
                type: "success",
                message: i18n.t("success.record-deleted")
              });
            }
          })
          .catch(error => {
            self.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  mounted() {
    if (!this.$route.query.page) this.getXml();
  },
  computed: {
    fields: function() {
      return [
        {
          key: "campaign_name",
          label: i18n.t("integration-process.campaign-name") + " (ID)"
        },
        {
          key: "name",
          label: i18n.t("system.name")
        },
        {
          key: "description",
          label: i18n.t("system.description"),
          sorter: false,
          filter: false
        },
        {
          key: "url",
          label: "Url",
          sorter: false,
          filter: false
        },
        {
          key: "type",
          label: i18n.t("integration-process.type")
        },
        {
          key: "format",
          label: i18n.t("xml-manager.format")
        },
        {
          key: "language",
          label: i18n.t("system.language")
        },
        {
          key: "is_secret",
          label: i18n.t("xml-manager.is-secret")
        },
        {
          key: "is_in_product_service",
          label: i18n.t("xml-manager.is-in-product-service")
        },
        {
          key: "download_at",
          label: i18n.t("xml-manager.download_at")
        },
        {
          key: "updated_at",
          label: i18n.t("system.updated_at")
        },
        {
          key: "button",
          label: "",
          _style: "min-width:250px;"
        }
      ];
    }
  }
};
</script>

<style></style>
