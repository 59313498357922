<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('minimalize/toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('minimalize/toggleSidebarDesktop')"
    />

    <CHeaderNav class="mr-4 ml-auto">
      <CHeaderNavItem class="d-md-down-none mx-2" v-if="user">
        <CHeaderNavLink
          :to="'/users/view/' + user.id"
          :title="$t('users.my-profile')"
        >
          <span v-if="user">{{ user.email }}</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2" v-if="user">
        <CHeaderNavLink
          :to="'/users/edit/' + user.id"
          :title="$t('users.my-profile')"
        >
          <CIcon name="cil-settings" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <a
          href="javascript.void(0)"
          @click.prevent="logOut"
          class="c-header-nav-link"
          :title="$t('login.logout')"
        >
          <CIcon name="cil-lock-locked" />
        </a>
      </CHeaderNavItem>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TheHeader",
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    })
  },
  methods: {
    ...mapActions({
      logOutAction: "auth/logOut"
    }),

    logOut() {
      this.logOutAction().then(() => {
        /*this.$router.replace({
          name: "login"
        });*/
        this.$router.push("/login");
      });
    }
  }
};
</script>
