import store from "@/store";
import Axios from "axios";

//set token, user_id to the localstorage

store.subscribe(mutation => {
  switch (mutation.type) {
    case "auth/SET_TOKEN":
      if (mutation.payload) {
        //Axios.defaults.headers.common["Authorization"] = "Bearer ${mutation.payload}";
        Axios.defaults.headers.common["X-Api-Key"] = "${mutation.payload}";
        localStorage.setItem("token", mutation.payload);
      } else {
        Axios.defaults.headers.common["X-Api-Key"] = null;
        localStorage.removeItem("token");
      }
      break;
  }
  switch (mutation.type) {
    case "auth/SET_USER":
      if (mutation.payload) {
        localStorage.setItem("user_id", mutation.payload.id);
      } else {
        localStorage.removeItem("user_id");
      }
      break;
  }
});
