<template>
  <div>
    <div v-if="loading">
      <CSpinner color="info" class="loading" />
    </div>
    <div v-else>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="4">
                  <CInput
                    label="Name"
                    v-model="form.header.name"
                    :disabled="isView"
                  />
                </CCol>
                <CCol sm="4">
                  <label>
                    {{ $t("system.language") }}
                    <a
                      href="javascript:void(0)"
                      title=""
                      class="text-info"
                      @click="modal.openModal = true"
                      v-if="!isEdit && !isView"
                      >({{ $t("system.change") }})</a
                    >
                  </label>
                  <CInput
                    id="lang"
                    name="lang"
                    :value="
                      $store.state.enumerators.enumerators['language'][
                        form.header.language
                      ]
                    "
                    disabled="true"
                    @change="onChangeLang()"
                  />

                  <CModal
                    :title="$t('system.select-language')"
                    color="warning"
                    :show.sync="modal.openModal"
                  >
                    <div
                      v-for="(value, key) in $store.state.enumerators
                        .enumerators['language']"
                      :key="key"
                    >
                      <label>
                        <input
                          type="radio"
                          :value="key"
                          v-model="modal.language"
                        />
                        {{ value }}
                      </label>
                    </div>

                    <hr />

                    <p>{{ $t("product-service.alert-modal-lang-1") }}.</p>
                    <p>
                      <strong
                        >{{ $t("product-service.alert-modal-lang-2") }}.</strong
                      >
                    </p>
                    <CInput v-model="modal.textModal" />
                    <p v-if="modal.error">
                      <span class="text-danger">{{ $t("error.oops") }}</span>
                    </p>
                    <CButton
                      size="sm"
                      color="success"
                      :disabled="isSubmitting"
                      v-on:click="submitModal"
                      ><CSpinner color="white" size="sm" v-if="isSubmitting" />
                      {{ $t("system.confirm") }}</CButton
                    >
                  </CModal>
                </CCol>
                <CCol sm="4">
                  <p>{{ $t("enum.Active") }}</p>
                  <CSwitch
                    type="checkbox"
                    id="secret"
                    color="info"
                    shape="pill"
                    :disabled="isView"
                    :checked.sync="form.header.active.checked"
                  />
                  <label for="secret" class="switchCheckBox"
                    ><strong>{{ $t("system.yes") }}</strong></label
                  >
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="12" class="text-right">
                  <CButton
                    color="success"
                    class="mr-2"
                    @click="form.step -= 1"
                    :disabled="form.step === 1 ? true : false"
                  >
                    {{ $t("system.previous") }}
                  </CButton>

                  <CButton
                    color="secondary"
                    :variant="form.step === 1 ? '' : 'outline'"
                    class="mr-2"
                    @click="form.step = 1"
                    >1</CButton
                  >
                  <CButton
                    color="secondary"
                    :variant="form.step === 2 ? '' : 'outline'"
                    class="mr-2"
                    @click="form.step = 2"
                    >2</CButton
                  >

                  <CButton
                    color="success"
                    @click="form.step += 1"
                    :disabled="form.step === 2 ? true : false"
                  >
                    {{ $t("system.next") }}
                  </CButton>
                </CCol>
              </CRow>

              <CRow v-if="form.step === 1">
                <!-- Tree -->
                <CCol>
                  <CTabs variant="tabs">
                    <CTab :title="$t('system.category')">
                      <div class="border border-top-0 p-3">
                        <div class="form-inline" v-if="!isView">
                          {{ $t("system.filter") }}:
                          <input
                            type="text"
                            class="form-control ml-2"
                            v-model="searchText"
                          />
                          <CButton
                            color="info"
                            class="ml-2"
                            @click="searchInTree"
                          >
                            {{ $t("system.search") }}
                          </CButton>
                          <CButton
                            color="secondary"
                            class="ml-2"
                            @click="searchInTreeClear"
                          >
                            {{ $t("system.cancel") }}
                          </CButton>
                        </div>
                        <hr />
                        <CRow v-if="selected !== 0">
                          <CCol>
                            Selected: <strong>{{ selected }}</strong>
                          </CCol>
                          <CCol class="text-right">
                            <!--
                          <span
                            @click="setSelectedAllItems"
                            class="text-info"
                            style="cursor: pointer;"
                            >{{ $t("system.select-all") }}</span
                          >
                          |-->
                            <span
                              v-if="!isView"
                              @click="deSelectedAllItems"
                              class="text-info"
                              style="cursor: pointer;"
                              >{{ $t("system.deselect-all") }}</span
                            >
                          </CCol>
                        </CRow>
                        <CRow v-else>
                          <CCol>
                            {{ $t("product-service.info-selected") }}.
                          </CCol>
                        </CRow>
                        <hr />

                        <div v-if="loadingTree">
                          <CSpinner color="info" class="loading" />
                        </div>
                        <div v-else>
                          <Tree
                            id="my-tree-id"
                            ref="tree"
                            :custom-options="myCustomOptions"
                            :custom-styles="myCustomStyles"
                            :nodes="items"
                          ></Tree>
                        </div>

                        <!--div v-if="loadingTree">
                        <CSpinner color="info" class="loading" />
                      </div>
                      <div v-else>
                        <v-jstree
                          class="sameBox300"
                          :data="items"
                          show-checkbox
                          multiple
                          allow-batch
                          whole-row
                          loading-text="loading"
                          @item-click="itemClick"
                          ref="tree"
                        ></v-jstree>
                      </div-->
                      </div>
                    </CTab>
                  </CTabs>
                </CCol>
                <!-- End tree -->

                <!-- Campaign -->
                <CCol>
                  <CTabs variant="tabs">
                    <CTab :title="$t('menu.campaigns')">
                      <div class="border border-top-0 p-3 sameBox500">
                        <div
                          class="form-check"
                          v-for="item in this.form.step1.campaigns"
                          :key="item.campaign_id"
                        >
                          <input
                            type="checkbox"
                            :id="'campaign_' + item.campaign_id"
                            :value="item.id"
                            :disabled="isView"
                            v-model="item.checked"
                          />&nbsp;
                          <label :for="'campaign_' + item.campaign_id"
                            >{{ item.campaign_name }} - {{ item.name }}</label
                          >
                        </div>
                      </div>
                    </CTab>
                  </CTabs>
                </CCol>
                <!-- End campaign -->
              </CRow>

              <CRow v-if="form.step === 2">
                <CCol sm="4">
                  <CInput
                    label="Ref. ID"
                    type="text"
                    v-model="form.step2.ref"
                    disabled="true"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    label="Data 1"
                    type="text"
                    :disabled="isView"
                    v-model="form.step2.data1"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                    label="Data 2"
                    type="text"
                    :disabled="isView"
                    v-model="form.step2.data2"
                  />
                </CCol>
                <CCol sm="12"> <br /><br /> </CCol>
                <CCol>
                  <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('product-service.file-type')">
                      <div class="border border-top-0 p-3">
                        <div
                          v-for="(value, key) in $store.state.enumerators
                            .enumerators['feed-type']"
                          v-bind:key="key"
                        >
                          <input
                            type="radio"
                            :id="'type_' + key"
                            name="type"
                            :value="key"
                            v-model="form.step2.feedType"
                            :disabled="isView"
                            @change="
                              filterColumns(
                                form.step2.feedType,
                                form.step2.feedFormat
                              )
                            "
                            :checked="key === '1' ? true : false"
                          />&nbsp;
                          <label :for="'type_' + key">{{ value }}</label>
                        </div>
                      </div>
                    </CTab>
                  </CTabs>
                </CCol>
                <CCol>
                  <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('xml-manager.format')">
                      <div class="border border-top-0 p-3">
                        <div
                          v-for="(value, key) in $store.state.enumerators
                            .enumerators['feed-format']"
                          v-bind:key="key"
                        >
                          <input
                            type="radio"
                            :id="'format_' + key"
                            name="format"
                            :value="key"
                            :disabled="isView"
                            v-model="form.step2.feedFormat"
                            @change="
                              filterColumns(
                                form.step2.feedType,
                                form.step2.feedFormat
                              )
                            "
                            :checked="key === '1' ? true : false"
                          />&nbsp;
                          <label :for="'format_' + key">{{ value }}</label>
                        </div>
                      </div>
                    </CTab>
                  </CTabs>
                </CCol>
                <CCol>
                  <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('product-service.compression')">
                      <div class="border border-top-0 p-3">
                        <div
                          v-for="(value, key) in $store.state.enumerators
                            .enumerators['compression']"
                          v-bind:key="key"
                        >
                          <input
                            type="radio"
                            :id="'compression_' + key"
                            name="compression"
                            :disabled="isView"
                            :value="key"
                            v-model="form.step2.compression"
                            :checked="key === '1' ? true : false"
                          />&nbsp;
                          <label :for="'compression_' + key">{{ value }}</label>
                        </div>
                      </div>
                    </CTab>
                  </CTabs>
                </CCol>
                <CCol v-if="form.step2.feedFormat === '2'">
                  <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('product-service.field-delimiter')">
                      <div class="border border-top-0 p-3">
                        <div
                          v-for="(value, key) in this.csvDelimiter"
                          v-bind:key="key"
                        >
                          <input
                            type="radio"
                            name="field_delimiter"
                            :id="'field_delimiter_' + key"
                            :value="value"
                            :disabled="isView"
                            v-model="form.step2.csvDelimiter"
                            :checked="key === 0 ? true : false"
                          />&nbsp;

                          <label
                            v-if="value !== 'custom'"
                            :for="'field_delimiter_' + key"
                            >{{ value }}</label
                          >
                          <input
                            v-else
                            type="text"
                            name="field_delimiter_custom"
                            value=""
                            :disabled="isView"
                            class="w-100"
                            v-model="form.step2.csvDelimiterCustom"
                          />
                        </div>
                      </div>
                    </CTab>
                  </CTabs>
                </CCol>
                <CCol>
                  <CTabs variant="tabs" :active-tab="0">
                    <CTab
                      :title="$t('product-service.category-tree-delimiter')"
                    >
                      <div class="border border-top-0 p-3">
                        <p>
                          <small
                            ><strong
                              >{{ $t("product-service.example") }}:</strong
                            >
                            Elektronika > Biela tehcnika > Chladničky</small
                          >
                        </p>
                        <div
                          v-for="(value, key) in this.categoryTreeDelimiter"
                          v-bind:key="key"
                        >
                          <input
                            type="radio"
                            name="field_tree_delimiter"
                            :id="'field_tree_delimiter_' + key"
                            :value="value"
                            :disabled="isView"
                            v-model="form.step2.treeDelimiter"
                            :checked="key === 0 ? true : false"
                          />&nbsp;

                          <label
                            v-if="value !== 'custom'"
                            :for="'field_tree_delimiter_' + key"
                            >{{ value }}</label
                          >
                          <input
                            v-else
                            type="text"
                            name="field_tree_delimiter_custom"
                            class="w-100"
                            value=""
                            :disabled="isView"
                            v-model="form.step2.treeDelimiterCustom"
                          />
                        </div>
                      </div>
                    </CTab>
                  </CTabs>
                </CCol>
                <CCol sm="6" id="columns">
                  <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('product-service.columns')">
                      <div class="border border-top-0 p-3">
                        <div class="columns">
                          <div class="columnsRow">
                            <div v-if="form.step2.columns.length === 0">
                              {{ $t("product-service.format-no-exist") }}.
                            </div>
                            <div
                              class="column"
                              v-for="columns in chunkedColumns"
                              v-bind:key="columns.value"
                            >
                              <div
                                v-for="column in columns"
                                v-bind:key="column.value"
                              >
                                <CInputCheckbox
                                  type="checkbox"
                                  :id="'columns_' + column.name"
                                  :custom="true"
                                  class="mb-2"
                                  :disabled="isView"
                                  :checked.sync="column.checked"
                                  :label="column.value"
                                />&nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CTab>
                  </CTabs>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter v-if="form.step === 2">
              <CButton
                v-if="!isView"
                size="lg"
                color="success"
                :disabled="isSubmitting"
                v-on:click="submit"
                ><CSpinner color="white" size="sm" v-if="isSubmitting" />
                {{ $t("system.save") }}</CButton
              >
              <CButton
                size="lg"
                color="secondary"
                :to="
                  '/pap/product-service?userId=' +
                    this.$route.query.userId +
                    '&session=' +
                    this.$route.query.session +
                    '&role=' +
                    this.$route.query.role
                "
                ><CSpinner color="white" size="sm" v-if="isSubmitting" />
                {{ $t("system.cancel") }}</CButton
              >
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import Tree from "vuejs-tree";
import chunk from "chunk";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import crud from "@/store/modules/crud";

function initialState() {
  return {
    publicPath: process.env.BASE_URL,
    loading: false,
    loadingTree: false,
    isSubmitting: false,
    submitted: false,
    modal: {
      openModal: false,
      language: "sk-SK",
      textModal: "",
      error: false
    },
    xPap: {
      userId: null,
      session: null,
      role: null
    },
    csvDelimiter: [",", "|", ";", "custom"],
    categoryTreeDelimiter: [">", "|", "\\", "/", ";", ",", "custom"],
    jsonColumns: [],
    checkedColumns: {
      type: 0,
      format: 0,
      names: []
    },
    form: {
      step: 1,
      header: {
        name: "",
        language: "sk-SK",
        active: {
          checked: true
        }
      },
      step1: {
        tree: [],
        campaigns: []
      },
      step2: {
        ref: null,
        data1: null,
        data2: null,
        columns: [],
        feedType: 1,
        feedFormat: 1,
        compression: 1,
        csvDelimiter: ",",
        csvDelimiterCustom: "",
        treeDelimiter: ">",
        treeDelimiterCustom: ""
      }
    },
    searchText: "",
    flatItemJson: [],
    selected: 0,
    items: []
  };
}

export default {
  name: "ProductServicePapCreate",
  props: ["isEdit", "isView"],
  components: {
    //VJstree
    Tree
  },
  data() {
    return initialState();
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    init() {
      this.getTreeItems();
      this.getCampaigns();
      this.getColumns();

      /*window.addEventListener("load", () => {
        this.xPap.userId = this.$route.query.userId;
        this.xPap.session = this.$route.query.session;
        this.xPap.role = this.$route.query.role;

        this.loading = false;
      });*/
    },

    verifySession() {
      this.loading = true;
      let self = this;
      crud
        .get(
          "pap/verify-session?userId=" +
            this.xPap.userId +
            "&session=" +
            this.xPap.session +
            "&role=" +
            this.xPap.role
        )
        .then(function(response) {
          self.form.step2.ref = response.data.reference;
          self.loading = false;
        })
        .catch(() => {
          this.$router.push(
            "/pap/product-service?userId=" +
              this.xPap.userId +
              "&session=" +
              this.xPap.session +
              "&role=" +
              this.xPap.role
          );
        });
    },

    submitModal() {
      let oldValue = this.form.header.language;
      let newValue = this.modal.language;

      if (this.modal.textModal === "CHANGE" && oldValue !== newValue) {
        this.resetAll(newValue);
        this.modal.error = false;
      } else {
        this.modal.error = true;
      }
    },

    resetAll(newValue) {
      Object.assign(this.$data, initialState());

      this.form.header.language = newValue;

      this.modal.textModal = "";
      this.modal.openModal = false;

      this.xPap.userId = this.$route.query.userId;
      this.xPap.session = this.$route.query.session;
      this.xPap.role = this.$route.query.role;

      this.init();
    },

    /*** Tree ***/
    getTreeItems() {
      this.loadingTree = true;
      let self = this;

      crud
        .get(
          "product-service/categories/tree?language=" +
            this.form.header.language,
          {
            headers: {
              "X-Pap-UserId": this.xPap.userId,
              "X-Pap-SessionId": this.xPap.session,
              "X-Pap-Role": this.xPap.role
            }
          }
        )
        .then(response => {
          self.items = response.data;
        })
        .finally(() => (self.loadingTree = false));
    },

    /***  Campaigns ***/
    getCampaigns() {
      let self = this;

      crud
        .get(
          "product-service/campaigns?filter[language]=" +
            this.form.header.language +
            "&per-page=100000",
          {
            headers: {
              "X-Pap-UserId": this.xPap.userId,
              "X-Pap-SessionId": this.xPap.session,
              "X-Pap-Role": this.xPap.role
            }
          }
        )
        .then(function(response) {
          let campaigns = [];

          for (const value of response.data.items) {
            campaigns.push({
              id: value["id"],
              campaign_id: value["campaign_id"],
              campaign_name: value["campaign_name"],
              product_service_id: value["product_service_id"],
              name: value["name"],
              format: value["format"],
              language: value["language"],
              type: value["type"],
              checked: false
            });
          }

          self.form.step1.campaigns = campaigns;
        });
    },

    getColumns(reload = false) {
      let self = this;

      crud
        .get("product-service/columns", {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          self.jsonColumns.push(response.data.items);
          if (!self.isEdit || reload) {
            self.filterColumns(
              self.form.step2.feedType,
              self.form.step2.feedFormat
            );
          }
        });
    },

    filterColumns(type, format) {
      let columns = this.jsonColumns;
      let result = [];

      if (columns) {
        if (columns[0][type][format]) {
          let keys = Object.keys(columns[0][type][format]);
          let values = Object.values(columns[0][type][format]);

          for (let i = 0; i < keys.length; i++) {
            result.push({
              name: keys[i],
              value: values[i].toString(),
              checked: false
            });

            /*for (const value of values[i]) {

              result.push({
                name: keys[i],
                value: value,
                checked: false
              });

            }*/
          }
        }
      }
      this.form.step2.columns = result;

      if (
        (this.isEdit || this.isView) &&
        this.checkedColumns.type.toString() === type.toString() &&
        this.checkedColumns.format.toString() === format.toString()
      ) {
        let checkedColumns = this.checkedColumns.names;
        for (let key in this.form.step2.columns) {
          if (checkedColumns.includes(this.form.step2.columns[key].name)) {
            this.form.step2.columns[key].checked = true;
          }
        }
      }
    },

    /**** TREE ****/
    countSelected() {
      let self = this;

      setTimeout(function() {
        self.$nextTick(function() {
          let data = self.$refs["tree"].getCheckedNodes("id");
          self.form.step1.tree = data;
          self.selected = data.length;
        });
      }, 100);
    },
    printObj(obj) {
      for (let key in obj) {
        this.flatItemJson.push({
          id: obj[key].id,
          name: this.$options.filters.slug(obj[key].text)
        });

        if (obj[key].nodes) {
          this.printObj(obj[key].nodes);
        }
      }
    },
    searchInTree() {
      this.printObj(this.items);
      let query = this.searchText.toLowerCase();
      let querySlug = this.$options.filters.slug(query);

      this.flatItemJson.forEach(el => {
        let e = el.name.search(querySlug);
        if (e !== -1) {
          this.$refs["tree"].expandNode(el.id);
          this.$refs["tree"].selectNode(el.id);
        }
      });
    },
    searchInTreeClear() {
      this.searchText = "";
      this.$refs["tree"].collapseAllNodes();
    },
    deSelectedAllItems() {
      this.$refs["tree"].uncheckAllNodes();
      this.form.step1.tree = [];
      this.selected = 0;
    },

    treeInView() {
      // this.printObj(this.items);

      // let treeID = this.form.step1.tree

      let result = ["a"];
      /* this.flatItemJson.forEach(el => {
        if (treeID.includes(el.id) ) {
          result.push(el.name)
        }
      });*/

      return result;
    },

    getUserFeedByID() {
      let self = this;

      crud
        .get("product-service/user-feeds/" + this.$route.params.id, {
          headers: {
            "X-Pap-UserId": this.$route.query.userId,
            "X-Pap-SessionId": this.$route.query.session,
            "X-Pap-Role": this.$route.query.role
          }
        })
        .then(function(response) {
          self.form.header.name = response.data.name;
          self.form.header.language = response.data.settings.language;
          self.form.header.active.checked =
            response.data.is_active === 1 ? true : false;

          self.form.step1.tree = response.data.googleCategories;

          self.form.step2.ref = response.data.pap_user_id;
          self.form.step2.data1 = response.data.settings.data1;
          self.form.step2.data2 = response.data.settings.data2;

          self.checkedColumns.type = response.data.settings.type;
          self.checkedColumns.format = response.data.settings.format;
          self.checkedColumns.names = response.data.columns;

          self.form.step2.feedType = response.data.settings.type;
          self.form.step2.feedFormat = response.data.settings.format;
          self.form.step2.compression = response.data.settings.compression;

          let csvDelimiter = self.csvDelimiter;
          if (csvDelimiter.includes(response.data.settings.csv_delimiter)) {
            self.form.step2.csvDelimiter = response.data.settings.csv_delimiter;
          } else {
            self.form.step2.csvDelimiter = "custom";
            self.form.step2.csvDelimiterCustom =
              response.data.settings.csv_delimiter;
          }

          let treeDelimiter = self.categoryTreeDelimiter;
          if (treeDelimiter.includes(response.data.settings.tree_delimiter)) {
            self.form.step2.treeDelimiter =
              response.data.settings.tree_delimiter;
          } else {
            self.form.step2.treeDelimiter = "custom";
            self.form.step2.treeDelimiterCustom =
              response.data.settings.tree_delimiter;
          }

          //campaigns
          let campaigns = response.data.sourceFeeds;
          for (let key in self.form.step1.campaigns) {
            if (
              campaigns.includes(
                self.form.step1.campaigns[key].product_service_id
              )
            ) {
              self.form.step1.campaigns[key].checked = true;
            }
          }

          self.getColumns(true);
        })
        .finally(() => {
          //tree
          self.$refs["tree"].collapseAllNodes();
          for (let item of self.form.step1.tree) {
            self.$refs["tree"].checkNode(item);
          }

          self.countSelected();

          self.loading = false;
        });
    },

    /***** submit form *****/
    submit() {
      this.isSubmitting = true;

      let validateCampaigns = true;
      let validateColumns = true;

      /*for (let key in this.form.step1.campaigns) {
        if (this.form.step1.campaigns[key].checked === true) {
          validateCampaigns = true;
        }
      }

      for (let key in this.form.step2.columns) {
        if (this.form.step2.columns[key].checked === true) {
          validateColumns = true;
        }
      }*/

      if (validateCampaigns && validateColumns) {
        let data = this.isEdit ? new URLSearchParams() : new FormData();

        //header
        data.append("name", this.form.header.name);

        data.append("is_active", this.form.header.active.checked ? "1" : "0");

        //step1
        for (let key in this.form.step1.tree) {
          data.append("googleCategories[]", this.form.step1.tree[key]);
        }

        //campaign / sourceFeeds
        for (let key in this.form.step1.campaigns) {
          if (this.form.step1.campaigns[key].checked === true) {
            data.append(
              "sourceFeeds[]",
              this.form.step1.campaigns[key].product_service_id
            );
          }
        }

        data.append("settings[language]", this.form.header.language);

        data.append("settings[data1]", this.form.step2.data1);
        data.append("settings[data2]", this.form.step2.data2);
        data.append("settings[type]", this.form.step2.feedType);
        data.append("settings[format]", this.form.step2.feedFormat);
        data.append("settings[compression]", this.form.step2.compression);

        if (this.form.step2.csvDelimiter === "custom") {
          data.append(
            "settings[csv_delimiter]",
            this.form.step2.csvDelimiterCustom
          );
        } else {
          data.append("settings[csv_delimiter]", this.form.step2.csvDelimiter);
        }

        if (this.form.step2.treeDelimiter === "custom") {
          data.append(
            "settings[tree_delimiter]",
            this.form.step2.treeDelimiterCustom
          );
        } else {
          data.append(
            "settings[tree_delimiter]",
            this.form.step2.treeDelimiter
          );
        }

        //step2
        data.append("ref", this.form.step2.ref);

        for (let key in this.form.step2.columns) {
          if (this.form.step2.columns[key].checked === true) {
            data.append("columns[]", this.form.step2.columns[key].name);
          }
        }

        if (this.isEdit) {
          crud
            .put("product-service/user-feeds/" + this.$route.params.id, data, {
              headers: {
                "X-Pap-UserId": this.$route.query.userId,
                "X-Pap-SessionId": this.$route.query.session,
                "X-Pap-Role": this.$route.query.role
              }
            })
            .then(() => {
              this.isSubmitting = false;
              this.$router.push(
                "/pap/product-service?userId=" +
                  this.$route.query.userId +
                  "&session=" +
                  this.$route.query.session +
                  "&role=" +
                  this.$route.query.role
              );
              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-updated")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;
              this.addNotification({
                type: "danger",
                message: error.response
              });
            });
        } else {
          crud
            .post("product-service/user-feeds", data, {
              headers: {
                "X-Pap-UserId": this.$route.query.userId,
                "X-Pap-SessionId": this.$route.query.session,
                "X-Pap-Role": this.$route.query.role
              }
            })
            .then(() => {
              this.isSubmitting = false;
              this.$router.push(
                "/pap/product-service?userId=" +
                  this.$route.query.userId +
                  "&session=" +
                  this.$route.query.session +
                  "&role=" +
                  this.$route.query.role
              );
              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-add")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;
              this.addNotification({
                type: "danger",
                message: error.response.data[0].message
              });
            });
        }
      } else {
        this.addNotification({
          type: "danger",
          message:
            i18n.t("error.fill-form") +
            ": " +
            (validateCampaigns === false
              ? i18n.t("menu.campaigns") + ", "
              : "") +
            (validateColumns === false ? i18n.t("product-service.columns") : "")
        });
      }

      this.isSubmitting = false;
    }
  },
  computed: {
    chunkedColumns() {
      return chunk(this.form.step2.columns, 20);
    },
    myCustomStyles() {
      return {
        tree: {
          height: "auto",
          maxHeight: "300px",
          overflowY: "visible",
          display: "inline-block"
        },
        row: {
          width: "500px",
          cursor: "pointer",
          child: {
            height: "35px"
          }
        },
        selectIcon: {
          class: "fa fa-folder",
          style: {
            color: "#007AD5"
          },
          active: {
            class: "fa fa-folder-open",
            style: {
              color: "black"
            }
          }
        },
        text: {
          style: {},
          active: {
            style: {
              "font-weight": "normal",
              color: "black"
            }
          }
        }
      };
    },
    myCustomOptions() {
      return {
        treeEvents: {
          expanded: {
            state: true
          },
          collapsed: {
            state: false
          },
          selected: {
            state: false
          },
          checked: {
            state: true,
            fn: this.countSelected
          }
        },
        events: {
          expanded: {
            state: true
          },
          selected: {
            state: true
          },
          checked: {
            state: true
          }
        },
        showTags: false
      };
    }
  },
  mounted() {
    this.xPap.userId = this.$route.query.userId;
    this.xPap.session = this.$route.query.session;
    this.xPap.role = this.$route.query.role;

    this.verifySession();
    this.init();
    this.isEdit || this.isView ? this.getUserFeedByID() : "";
  }
};
</script>
<style scoped>
.selected {
  color: green;
  font-weight: bold;
}
.sameBox300 {
  min-height: 300px;
  overflow-y: scroll;
}
.sameBox500 {
  min-height: 455px;
  overflow-y: scroll;
}
.columns {
  display: flex;
  width: 100%;
}
.columnsRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
</style>
<style>
.fa.fa-folder,
.fa.fa-folder-open {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
