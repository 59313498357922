import i18n from "@/i18n";

//Views Messaging
import Messaging from "../views/messaging/MessagingIndex.vue";
import MessagingView from "../views/messaging/MessagingView.vue";
import MessagingSettings from "../views/messaging/MessagingSettingsIndex.vue";
import MessagingSettingsCreate from "../views/messaging/MessagingSettingsCreate.vue";
import MessagingSettingsEdit from "../views/messaging/MessagingSettingsEdit.vue";
import MessagingPap from "../views/messaging/messaging-pap/MessagingPapIndex.vue";
import MessagingPapCreate from "../views/messaging/messaging-pap/MessagingPapCreate.vue";
import MessagingPapView from "../views/messaging/messaging-pap/MessagingPapView.vue";
import MessagingPapPublishers from "../views/messaging/messaging-pap/MessagingPapPublishersIndex.vue";
import MessagingPapPublishersDetail from "../views/messaging/messaging-pap/MessagingPapPublishersView.vue";

export default [
  {
    data: {
      pap: [
        {
          path: "/pap/messaging",
          name: i18n.t("menu.messaging") + "papInbox",
          component: MessagingPap,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/pap/messaging/create",
          name: i18n.t("menu.messaging") + "papCreate",
          component: MessagingPapCreate,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/pap/messaging/view/:campaignId/:affiliateId",
          name: i18n.t("menu.messaging") + "papView",
          component: MessagingPapView,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/pap/messaging/publishers",
          name: i18n.t("menu.messaging") + "papPublishers",
          component: MessagingPapPublishers,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/pap/messaging/publishers/:campaignId/:affiliateId",
          name: i18n.t("menu.messaging") + "papPublishers",
          component: MessagingPapPublishersDetail,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        }
      ],
      admin: [
        {
          path: "/messaging",
          meta: {
            label: i18n.t("menu.messaging")
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/messaging",
              name: i18n.t("menu.messaging") + ": " + i18n.t("crud.list"),
              component: Messaging,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/messaging/view/:campaignId/:affiliateId",
              name: i18n.t("menu.messaging") + ": " + i18n.t("crud.view"),
              component: MessagingView,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/messaging/settings",
              name: i18n.t("menu.messaging") + ": " + i18n.t("system.settings"),
              component: MessagingSettings,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/messaging/settings/create",
              name:
                i18n.t("menu.messaging") +
                ": " +
                i18n.t("messaging.edit-campaign") +
                " " +
                i18n.t("integration-process.campaign"),
              component: MessagingSettingsCreate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/messaging/settings/edit/:id",
              name:
                i18n.t("menu.messaging") +
                ": " +
                i18n.t("messaging.edit-campaign"),
              component: MessagingSettingsEdit,
              meta: {
                requiresAuth: true
              }
            }
            /*
          {
            path: "/integration-process/view/:id",
            name:
              i18n.t("menu.integration-process") +
              ": " +
              i18n.t("crud.view") +
              " " +
              i18n.t("integration-process.campaign"),
            component: IntegrationProcessView,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "/integration-process/view/:id/step/:type",
            name:
              i18n.t("menu.integration-process") +
              ": " +
              i18n.t("crud.edit") +
              " " +
              i18n.t("integration-process.steps"),
            component: IntegrationProcessStepsEdit,
            meta: {
              requiresAuth: true
            }
          }*/
          ]
        }
      ]
    }
  }
];
