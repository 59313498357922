<template>
  <CCol>
    <CRow>
      <CCol sm="12">
        <CForm @submit.prevent="submit">
          <CRow>
            <CCol class="pl-0" v-for="column in columns" :key="column.name">
              <CCol v-if="column.type === 'text'">
                <CInput
                  :label="column.label"
                  v-model="data[column.name]"
                  minlength="3"
                />
              </CCol>
              <CCol v-else-if="column.type === 'select'">
                <label :for="column.name">{{ column.label }}</label>
                <select
                  v-model="data[column.name]"
                  class="form-control"
                  :id="column.name"
                >
                  <option
                    v-bind:value="index"
                    v-for="(value, index) in enums[column.enum]"
                    v-bind:key="value"
                    >{{ value }}</option
                  >
                </select>
              </CCol>
              <CCol v-else-if="column.type === 'select2'">
                <label :for="column.name">{{ column.label }}</label>
                <v-select
                  :options="column.options"
                  label="name"
                  v-model="data[column.name]"
                  :id="column.name"
                  :reduce="name => name.id"
                  :clearable="false"
                >
                </v-select>
              </CCol>
              <CCol v-else-if="column.type === 'checkbox-group'">
                <fieldset class="form-group">
                  <legend class="col-form-label pt-0">
                    {{ column.label }}
                  </legend>
                  <hr class="m-0 pt-2" />
                  <template v-for="(value, key) in column.options">
                    <div
                      class="custom-control custom-checkbox mr-sm-2 d-inline-block"
                      :key="key"
                    >
                      {{ key.options }}
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="column.name + '-' + key"
                        :value="key"
                        v-model="data[column.name]"
                      />
                      <label
                        :for="column.name + '-' + key"
                        class="custom-control-label"
                        >{{ value }}</label
                      >
                    </div>
                  </template>
                </fieldset>
              </CCol>
            </CCol>
            <CCol>
              <CButton
                class="buttonMargin"
                type="submit"
                size="sm"
                color="success"
                :disabled="isSubmitting"
                ><CSpinner color="white" size="sm" v-if="isSubmitting" />
                {{ $t("system.search") }}</CButton
              >
              <CButton
                v-if="cancelBtn"
                class="buttonMargin ml-2"
                type="button"
                size="sm"
                color="secondary"
                :disabled="isSubmitting"
                @click="cancel"
                ><CSpinner color="white" size="sm" v-if="isSubmitting" />
                {{ $t("system.cancel") }}</CButton
              >
            </CCol>
          </CRow>
          <br />
        </CForm>
      </CCol>
    </CRow>
  </CCol>
</template>

<script>
import vSelect from "vue-select";
//import crud from "@/store/modules/crud";

/**
 *  In parent file
 *
 *  <Search ref="search" v-bind:columns="this.search" />
 *
 *   import Search from "@/components/ui/Search";
 *
 *   components: {Search},
 *   data() {
 *   return {
 *     search: [
 *       {
 *         name: "username",
 *         label: i18n.t("login.username"),
 *         type: "text", //text, select
 *         operator: "or", //or,in
 *         like: true //true, false
 *       },
 *       {
 *         name: "email",
 *         label: "E-mail",
 *         type: "text",
 *         operator: "or",
 *         like: true
 *       },
 *       {
 *         name: "status",
 *         label: i18n.t("system.status"),
 *         type: "select",
 *         operator: "or",
 *         like: false,
 *         enum: "user-status"
 *       },
 *       {
 *         name: "campaign_id",
 *         options: [], //options object to select box  [{ id: '0001', name: 'John'}]
 *         label: i18n.t("integration-process.campaign-name"),
 *         type: "select2",
 *         operator: "or",
 *         like: false
 *       },
 *       {
 *         name: "status",
 *         label: i18n.t("system.status"),
 *         options: [], [{ id: '4', name: 'Publish'}]
 *         operator: "in",
 *         type: "checkbox-group",
 *       }
 *     ]
 *   };
 *
 *   methods: {
 *     pagination() {
 *       some functions ... call after click  this.getUsers();
 *    },
 *
 *    //some function
 *    getUsers() {
 *        let query = this.$refs.search.parseUrlQuery();
 *
 *        if (this.$route.query.page) {
 *           crud.get("users" + query).then(function(response) {
 *             self.items = response.data.items;
 *
 *             self.page.totalCount = response.data._meta.totalCount;
 *             self.page.perPage = response.data._meta.perPage;
 *
 *             self.loading = false;
 *           });
 *         }
 *    }
 *   }
 *
 *
 */

export default {
  name: "Search",
  props: ["columns"],
  components: {
    vSelect
  },
  data() {
    return {
      isSubmitting: false,
      cancelBtn: false,
      //enums: [],
      data: []
    };
  },
  methods: {
    init() {
      //create array in data name
      const chg = this.columns.find(item => item.type === "checkbox-group");
      if (chg !== undefined) {
        this.data[chg.name] = [];
      }
    },
    cancel() {
      this.cancelBtn = false;
      this.data = [];
      this.init();
      this.$router.push({ query: { page: "1" } });
      this.$parent.pagination();
      this.isSubmitting = false;
    },

    objectEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }

      return true;
    },

    /*setInput() {
      for (let key in this.$route.query) {
        if (key.includes("filter")) {
          var partsArray = key.split("[")[3].split("]")[0];
          this.data[partsArray] = this.$route.query[key];
          this.cancelBtn = true;
        }
      }
    },*/
    setInput() {
      for (let key in this.$route.query) {
        if (key.includes("filter")) {
          let arrString = key.replace("filter", "");
          let clearArr = arrString
            .split("]")
            .filter(n => n)
            .map(function(a) {
              return a.replace("[", "");
            });

          if (clearArr[1] === "") {
            //add strandart value from url
            this.data[clearArr[2]] = this.$route.query[key].replace(/%/g, "");
          } else {
            //add array from value
            this.data[clearArr[1]][clearArr[3]] = this.$route.query[key];
          }

          this.cancelBtn = true;
        }
      }
    },

    parseUrlQuery() {
      let apiUrl = "?";

      for (let key in this.$route.query) {
        if (key) {
          if (key === "page") {
            apiUrl += "page=" + this.$route.query[key];
          } else if (key.includes("filter") || key === "sort") {
            apiUrl += this.$route.query[key]
              ? "&" + key + "=" + this.$route.query[key]
              : "";
          }
        }
      }
      return apiUrl;
    },

    submit() {
      this.isSubmitting = true;

      if (this.data) {
        this.cancelBtn = true;
        let url = "";
        let query = { page: "1" };

        for (let key in this.data) {
          for (let i = 0; i < this.columns.length; i++) {
            let el = this.columns[i];
            if (
              this.columns[i].type === "checkbox-group" &&
              el.name === key &&
              this.data[key]
            ) {
              //checkbox-group url

              for (let a in Object.keys(this.data[key])) {
                url =
                  '{"filter[' +
                  el.operator +
                  "][" +
                  key +
                  "][in]" +
                  "[" +
                  a +
                  ']" : "' +
                  this.data[key][a] +
                  '"}';

                Object.assign(query, JSON.parse(url));
              }
            } else {
              if (el.name === key && this.data[key]) {
                let like = el.like ? "[like]" : "";
                url =
                  '{"filter[' +
                  el.operator +
                  "][][" +
                  key +
                  "]" +
                  like +
                  '" : "' +
                  this.data[key] +
                  '"}';
                Object.assign(query, JSON.parse(url));
              }
            }
          }
        }

        //compare url and query ... equal is error
        if (!this.objectEqual(this.$route.query, query)) {
          this.$router.push({ query: query });
          this.$parent.pagination();
        }
        this.isSubmitting = false;
      }
    }
  },
  computed: {
    enums: function() {
      return this.$store.state.enumerators.enumerators;
    }
  },
  created() {
    this.init();
    this.setInput();
  }
};
</script>

<style scoped>
.buttonMargin {
  margin-top: 33px !important;
}
</style>
