import crud from "@/store/modules/crud";

export default {
  namespaced: true,
  state: {
    enumerators: {}
  },
  getters: {
    enumerators(state) {
      return state.enumerators;
    }
  },
  mutations: {
    FETCH_ENUMERATORS(state, data) {
      state.enumerators = data;
    }
  },
  actions: {
    fetchEnumerators({ commit }) {
      let query = new URL(location.href);

      if (query.searchParams.get("userId")) {
        crud
          .get("enums", {
            headers: {
              "X-Pap-UserId": query.searchParams.get("userId"),
              "X-Pap-SessionId": query.searchParams.get("session"),
              "X-Pap-Role": query.searchParams.get("role")
            }
          })
          .then(response => {
            commit("FETCH_ENUMERATORS", response.data);
          })
          .catch(response => {
            console.error("Unable to retrieve enumerators");
            console.error(response);
          });
      } else {
        crud
          .get("enums")
          .then(response => {
            commit("FETCH_ENUMERATORS", response.data);
          })
          .catch(response => {
            console.error("Unable to retrieve enumerators");
            console.error(response);
          });
      }
    }
  }
};
