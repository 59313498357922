import i18n from "@/i18n";

//Views IntegrationProcess
import IntegrationProcess from "../views/integration_process/IntegrationProcessIndex.vue";
import IntegrationProcessCreate from "../views/integration_process/IntegrationProcessCreate.vue";
import IntegrationProcessEdit from "../views/integration_process/IntegrationProcessEdit.vue";
import IntegrationProcessView from "../views/integration_process/IntegrationProcessView.vue";
import IntegrationProcessStepsEdit from "../views/integration_process/steps/IntegrationProcessStepsEdit.vue";
import IntegrationProcessPapStepsIndex from "../views/integration_process/steps-pap/IntegrationProcessPapStepsIndex.vue";

export default [
  {
    data: {
      pap: [
        {
          path: "/pap/integration-process/:tab?",
          name: i18n.t("menu.integration-process") + "a",
          component: IntegrationProcessPapStepsIndex,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        }
      ],
      admin: [
        {
          path: "/integration-process",
          meta: {
            label: i18n.t("menu.integration-process")
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/integration-process",
              name:
                i18n.t("menu.integration-process") + ": " + i18n.t("crud.list"),
              component: IntegrationProcess,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/integration-process/create",
              name:
                i18n.t("menu.integration-process") +
                ": " +
                i18n.t("crud.create") +
                " " +
                i18n.t("integration-process.campaign"),
              component: IntegrationProcessCreate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/integration-process/edit/:id",
              name:
                i18n.t("menu.integration-process") +
                ": " +
                i18n.t("crud.edit") +
                " " +
                i18n.t("integration-process.campaign"),
              component: IntegrationProcessEdit,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/integration-process/view/:id",
              name:
                i18n.t("menu.integration-process") +
                ": " +
                i18n.t("crud.view") +
                " " +
                i18n.t("integration-process.campaign"),
              component: IntegrationProcessView,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/integration-process/view/:id/step/:type",
              name:
                i18n.t("menu.integration-process") +
                ": " +
                i18n.t("crud.edit") +
                " " +
                i18n.t("integration-process.steps"),
              component: IntegrationProcessStepsEdit,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
