<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.messaging") }}
            </h4>
            <div class="small text-muted">{{ $t("messaging.perex") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButtonGroup>
              <CButton color="info" variant="outline" to="/messaging">
                {{ $t("messaging.inbox") }}
              </CButton>
              <CButton color="info" to="/messaging/settings">
                {{ $t("system.settings") }}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CRow>
              <CCol sm="6" class="pl-0">
                <Search ref="search" v-bind:columns="this.search" />
              </CCol>
            </CRow>
            <Sort ref="sort" />

            <CRow class="pb-3">
              <CCol sm="6"
                ><h4>{{ $t("messaging.campaign-settings") }}</h4></CCol
              >
              <CCol sm="6" class="text-right">
                <CButton
                  size="sm"
                  color="success"
                  class="ml-1"
                  to="/messaging/settings/create"
                >
                  + {{ $t("messaging.add-new-campaign") }}
                </CButton>
              </CCol>
            </CRow>

            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable
              v-else
              :items="items"
              :fields="fields"
              :sorter="{ external: true, resetable: false }"
              hover
              @update:sorter-value="$refs.sort.sortTable"
            >
              <template v-slot:no-items-view>
                <div class="text-center my-5">
                  <h2>
                    {{ $t("message.search-empty") }}
                  </h2>
                </div>
              </template>
              <template #campaign_name="{item}">
                <td>
                  {{ item.campaign_name }}
                  <small>({{ item.campaign_id }})</small>
                </td>
              </template>
              <template #merchant_recipients="{item}">
                <td>
                  <span v-if="item.merchant_recipients === null">---</span>
                  <span v-else>{{ item.merchant_recipients.toString() }}</span>
                </td>
              </template>
              <template #status="{item}">
                <td>
                  <CBadge :color="getBadge(item.status)">
                    {{
                      $t(
                        "enum." +
                          $store.state.enumerators.enumerators[
                            "campaign-setting-status"
                          ][item.status]
                      )
                    }}
                  </CBadge>
                </td>
              </template>
              <template #m_can_new_message="{item}">
                <td>
                  <CBadge color="success" v-if="item.m_can_new_message === 1">
                    <CIcon name="cil-check" />
                  </CBadge>
                  <CBadge color="danger" v-else>
                    <CIcon name="cil-XCircle" />
                  </CBadge>
                </td>
              </template>
              <template #a_can_new_message="{item}">
                <td>
                  <CBadge color="success" v-if="item.a_can_new_message === 1">
                    <CIcon name="cil-check" />
                  </CBadge>
                  <CBadge color="danger" v-else>
                    <CIcon name="cil-XCircle" />
                  </CBadge>
                </td>
              </template>
              <template #button="{item, index}">
                <td class="py-2 text-right">
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="{ path: '/messaging/settings/edit/' + item.id }"
                  >
                    {{ $t("system.update") }}
                  </CButton>
                  <CForm
                    @submit.prevent="submit"
                    class="d-inline"
                    v-if="$store.state.auth.user"
                  >
                    <CButton
                      size="sm"
                      color="danger"
                      class="ml-1"
                      v-on:click="deleteElement(item.id, index)"
                    >
                      {{ $t("system.delete") }}
                    </CButton>
                  </CForm>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
        <Pagination
          :totalCount="this.page.totalCount"
          :perPage="this.page.perPage"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Pagination from "@/components/ui/Pagination";
import Search from "@/components/ui/Search";
import Sort from "@/components/ui/Sort";

export default {
  name: "IntegrationProcess",
  components: {
    Pagination,
    Search,
    Sort
  },
  data() {
    return {
      loading: true,
      items: [],
      tempItems: [],
      status: 1,
      page: {
        totalCount: 0,
        perPage: 0
      },
      search: [
        {
          name: "campaign_id",
          options: [],
          label: i18n.t("integration-process.campaign-name"),
          type: "select2",
          operator: "or",
          like: false
        }
      ]
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    pagination() {
      this.getMessages();
    },

    sort() {
      this.getMessages();
    },

    selectOptions() {
      let self = this;

      //all campaigns to select box
      crud
        .get("messaging/campaign-settings?per-page=100000")
        .then(function(response) {
          let items = response.data.items;

          let obj = [];

          for (let key in items) {
            obj.push({
              id: items[key].campaign_id,
              name: items[key].campaign_name
            });
          }
          self.search[0].options = obj;
        });
    },

    getMessages() {
      let self = this;
      let query = this.$refs.search.parseUrlQuery();

      crud.get("messaging/campaign-settings" + query).then(function(response) {
        self.items = [];
        for (const key of Object.values(response.data.items)) {
          self.items.push(key);
        }

        self.page.totalCount = response.data._meta.totalCount;
        self.page.perPage = response.data._meta.perPage;

        self.loading = false;
      });

      self.selectOptions();
    },

    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 2:
          return "warning";
        default:
          return "danger";
      }
    },

    deleteElement(id, index) {
      if (confirm(i18n.t("confirmation.delete"))) {
        let self = this;
        crud
          .delete("messaging/campaign-settings/" + id)
          .then(function(response) {
            if (response.status === 204) {
              self.items.splice(index, 1);
              self.page.totalCount -= 1;

              self.addNotification({
                type: "success",
                message: i18n.t("success.record-deleted")
              });
            }
          })
          .catch(error => {
            self.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  mounted() {
    this.getMessages();
  },
  computed: {
    fields: function() {
      return [
        {
          key: "campaign_name",
          label: i18n.t("integration-process.campaign-name"),
          _style: "min-width:100px;"
        },
        {
          key: "merchant_recipients",
          label: i18n.t("messaging.recipients"),
          sorter: false
        },
        {
          key: "status",
          label: i18n.t("integration-process.campaign-status"),
          _style: "min-width:100px;",
          sorter: true
        },
        {
          key: "m_can_new_message",
          label: i18n.t("messaging.m-message"),
          sorter: false
        },
        {
          key: "a_can_new_message",
          label: i18n.t("messaging.p-message"),
          sorter: false
        },
        {
          key: "button",
          label: "",
          _style: "min-width:200px;"
        }
      ];
    }
  }
};
</script>

<style></style>
