<template>
  <div class="notifications-list">
    <notifications-message
      v-for="(notification, index) in notifications"
      :key="index"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationsMessage from "@/components/notifications/NotificationsMessage";
import { mapState } from "vuex";

export default {
  components: {
    NotificationsMessage
  },
  computed: {
    ...mapState("notifications", ["notifications"])
  }
};
</script>
