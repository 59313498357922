<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.roles") }}
            </h4>
            <div class="small text-muted">{{ $t("crud.list") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButton color="success" square size="sm" to="/users/roles/create">
              +
              {{
                $t("system.add") +
                  " " +
                  $t("system.new") +
                  " " +
                  $t("roles.role")
              }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable v-else :items="items" :fields="fields" hover>
              <template #button="{item, index}">
                <td class="py-2 text-right">
                  <CButton
                    size="sm"
                    color="info"
                    variant="outline"
                    class="ml-1"
                    :to="{ path: '/users/roles/view/' + item.name }"
                  >
                    {{ $t("system.show") }}
                  </CButton>
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="{ path: '/users/roles/edit/' + item.name }"
                  >
                    {{ $t("system.update") }}
                  </CButton>
                  <CForm class="d-inline" v-if="$store.state.auth.user">
                    <CButton
                      size="sm"
                      color="secondary"
                      class="ml-1"
                      disabled="disabled"
                      v-if="$store.state.auth.user.roles[item.name]"
                    >
                      {{ $t("system.delete") }}
                    </CButton>
                    <CButton
                      size="sm"
                      color="danger"
                      class="ml-1"
                      v-on:click="deleteElement(item.name, index)"
                      v-else
                    >
                      {{ $t("system.delete") }}
                    </CButton>
                  </CForm>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";

const fields = [
  {
    key: "name",
    label: i18n.t("roles.role"),
    _style: "min-width:200px"
  },
  "description",
  {
    key: "button",
    label: ""
  }
];

export default {
  name: "Roles",
  data() {
    return {
      loading: true,
      items: [],
      fields
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),
    getRoles() {
      var self = this;
      crud.get("auth-roles?filter[type]=1").then(function(response) {
        self.loading = false;
        self.items = response.data.items;
      });
    },
    deleteElement(name, index) {
      if (confirm(i18n.t("confirmation.delete"))) {
        let self = this;
        crud
          .delete("auth-roles/" + name)
          .then(function(response) {
            if (response.status === 204) {
              self.items.splice(index, 1);

              self.addNotification({
                type: "success",
                message: i18n.t("success.record-deleted")
              });
            }
          })
          .catch(error => {
            self.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  created() {
    this.getRoles();
  }
};
</script>

<style>
.loading {
  width: 4rem;
  height: 4rem;
  display: block;
  margin: 40px auto;
}
</style>
