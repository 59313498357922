<template>
  <div>
    <div v-if="loading">
      <CSpinner color="info" class="loading" /><br />
      <div class="text-center">
        <CButton @click="reloadPage" color="secondary" size="sm">
          Refresh
        </CButton>
      </div>
    </div>
    <div v-else-if="forbidden">
      <CAlert
        color="warning"
        closeButton
        v-html="$t('messaging.forbidden')"
      ></CAlert>
    </div>
    <div v-else>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="6">
                  <h4 id="traffic" class="card-title mb-0">
                    {{ $t("menu.messaging") }}
                  </h4>
                  <div class="small text-muted">
                    {{ $t("messaging.perex") }}
                  </div>
                </CCol>
                <CCol
                  v-if="this.xPap.role === 'M'"
                  sm="6"
                  class="text-right pt-2"
                >
                  <CButtonGroup>
                    <CButton
                      color="info"
                      :to="
                        '/pap/messaging?userId=' +
                          this.$route.query.userId +
                          '&session=' +
                          this.$route.query.session +
                          '&role=' +
                          this.$route.query.role
                      "
                    >
                      {{ $t("messaging.inbox") }}
                    </CButton>
                    <CButton
                      color="info"
                      variant="outline"
                      :to="
                        '/pap/messaging/publishers?userId=' +
                          this.$route.query.userId +
                          '&session=' +
                          this.$route.query.session +
                          '&role=' +
                          this.$route.query.role
                      "
                    >
                      {{ $t("system.affiliates") }}
                    </CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <Search ref="search" v-bind:columns="this.search" class="p-0" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="6" class="mb-1">
                  <h4 class="card-title">
                    {{ $t("messaging.inbox") }}
                  </h4>
                </CCol>
                <CCol sm="6" class="mb-1 text-right">
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="
                      'messaging/create?userId=' +
                        this.$route.query.userId +
                        '&session=' +
                        this.$route.query.session +
                        '&role=' +
                        this.$route.query.role
                    "
                  >
                    + {{ $t("messaging.add-new-message") }}
                  </CButton>
                  <AutoRefresh :refresh="60" @reload="getItems" />
                </CCol>
              </CRow>

              <CRow v-if="Object.keys(items).length === 0">
                <CCol sm="12" class="mb-1">
                  <hr />
                  <h3>{{ $t("system.search") }}:</h3>
                  {{ $t("message.search-empty") }}
                </CCol>
              </CRow>
              <CCol v-else class="p-0">
                <CDataTable
                  :loading="reloading"
                  :items="items"
                  :fields="fields"
                  addTableClasses="clicktable"
                  hover
                  @row-clicked="rowClocked"
                >
                  <template v-slot:no-items-view>
                    <div class="text-center my-5">
                      <h2>
                        {{ $t("message.search-empty") }}
                      </h2>
                    </div>
                  </template>
                  <template #affiliate_name="{item}">
                    <td>
                      <strong
                        v-if="
                          item.seen_at === null &&
                            ((item.sender_type === 1 && xPap.role === 'A') ||
                              (item.sender_type === 2 && xPap.role === 'M'))
                        "
                      >
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        {{ item.affiliate_name }}
                      </strong>
                      <span v-else
                        ><i
                          class="fa fa-envelope-open-o"
                          aria-hidden="true"
                        ></i>
                        {{ item.affiliate_name }}</span
                      >
                    </td>
                  </template>
                  <template #campaign_name="{item}">
                    <td>
                      <strong
                        v-if="
                          item.seen_at === null &&
                            ((item.sender_type === 1 && xPap.role === 'A') ||
                              (item.sender_type === 2 && xPap.role === 'M'))
                        "
                      >
                        {{ item.campaign_name }}
                      </strong>
                      <span v-else>{{ item.campaign_name }}</span>
                    </td>
                  </template>
                  <template #body_perex="{item}">
                    <td>{{ item.body_perex }} ...</td>
                  </template>
                  <template #seen_at="{item}">
                    <td>
                      <span v-if="item.seen_at === null">
                        <CBadge color="danger">X</CBadge>
                      </span>
                      <span v-else>{{ dateFormat(item.seen_at) }}</span>
                    </td>
                  </template>
                  <template #created_at="{item}">
                    <td>
                      <span v-if="item.created_at === null">---</span>
                      <span v-else>{{ dateFormat(item.created_at) }}</span>
                    </td>
                  </template>
                </CDataTable>
                <Pagination
                  :totalCount="this.page.totalCount"
                  :perPage="this.page.perPage"
                />
              </CCol>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Search from "@/components/ui/Search";
import moment from "moment";
import AutoRefresh from "@/components/ui/AutoRefresh";
import Pagination from "@/components/ui/Pagination";

export default {
  name: "MessagingSettingsPap",
  components: {
    AutoRefresh,
    Search,
    Pagination
  },
  data() {
    return {
      loading: false,
      reloading: false,
      forbidden: false,
      isSubmitting: false,
      xPap: {
        userId: null,
        session: null,
        role: null
      },
      campaignsId: [],
      items: [],
      selectOptions: [],
      page: {
        totalCount: 0,
        perPage: 0
      }
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification",
      setLang: "auth/setLang"
    }),

    reloadPage() {
      window.location.reload();
    },

    rowClocked(item) {
      this.$router.push(
        "/pap/messaging/view/" +
          item.campaign_id +
          "/" +
          item.affiliate_id +
          "?userId=" +
          this.$route.query.userId +
          "&session=" +
          this.$route.query.session +
          "&role=" +
          this.$route.query.role
      );
    },

    dateFormat(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
    },

    createUrlXPap() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          userId: this.xPap.userId,
          session: this.xPap.session,
          role: this.xPap.role
        })
      });
    },

    pagination() {
      this.checkCampaign();
      this.getItems();
      this.createUrlXPap();
    },

    checkCampaign() {
      let self = this;

      crud
        .get("messaging/recipients/campaigns?per-page=100000", {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          if (response.data.items.length === 0 && self.xPap.role === "M") {
            self.forbidden = true;
          }
        });
    },

    selectSearchOptions() {
      this.loading = true;
      let self = this;

      crud
        .get("messaging/inbox?per-page=100000", {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          let options = [];
          for (const value of response.data.items) {
            if (
              !options.some(options => options["id"] === value["campaign_id"])
            ) {
              options.push({
                id: value["campaign_id"],
                name: value["campaign_name"]
              });
            }
          }

          self.selectOptions = options;
          self.loading = false;
        });
    },

    getItems() {
      let self = this;
      this.forbidden = false;
      this.reloading = true;

      let query = this.$refs.search.parseUrlQuery();

      crud
        .get("messaging/inbox" + query, {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          self.items = [];
          for (const key of Object.values(response.data.items)) {
            self.items.push(key);
          }
          self.page.totalCount = response.data._meta.totalCount;
          self.page.perPage = response.data._meta.perPage;

          self.reloading = false;
        })
        .catch(() => {
          self.forbidden = true;
          self.reloading = false;
        });
    }
  },
  created() {
    this.setLang();
  },
  mounted() {
    this.xPap.userId = this.$route.query.userId;
    this.xPap.session = this.$route.query.session;
    this.xPap.role = this.$route.query.role;
    this.checkCampaign();
    this.selectSearchOptions();
    this.getItems();
  },
  computed: {
    fields: function() {
      return [
        {
          key: "affiliate_name",
          label: i18n.t("system.affiliate"),
          _style: "min-width:200px",
          sorter: false
        },
        {
          key: "campaign_name",
          label: i18n.t("integration-process.campaign-name"),
          sorter: false
        },
        {
          key: "body_perex",
          label: i18n.t("system.preview"),
          sorter: false
        },
        {
          key: "seen_at",
          label: i18n.t("messaging.seen-at"),
          sorter: false
        },
        {
          key: "created_at",
          label: i18n.t("messaging.last-communication"),
          sorter: false
        }
      ];
    },
    search: function() {
      let obj = [
        {
          name: "campaign_id",
          options: this.selectOptions,
          label: i18n.t("integration-process.campaign-name"),
          type: "select2",
          operator: "and",
          like: false
        }
      ];

      if (this.xPap.role === "M") {
        obj.push({
          name: "affiliate_name",
          label: i18n.t("system.affiliate"),
          type: "text",
          operator: "or",
          like: true
        });
      }

      return obj;
    }
  }
};
</script>
