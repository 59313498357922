<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol lg="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.xml-manager") }}
            </h4>
            <div class="small text-muted">{{ $t("crud.create") }}</div>
          </CCol>
          <CCol lg="6" class="text-right pt-2">
            <CButton color="secondary" square size="sm" to="/xml-manager">
              {{ $t("system.cancel") }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <Form></Form>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Form from "./Form";

export default {
  name: "XmlManagerCreate",
  components: {
    Form
  }
};
</script>
