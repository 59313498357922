import i18n from "@/i18n";

//Views Users
import User from "../views/user/UserIndex.vue";
import UserCreate from "../views/user/UserCreate.vue";
import UserEdit from "../views/user/UserEdit.vue";
import UserView from "../views/user/UserView.vue";

export default [
  {
    data: {
      pap: null,
      admin: [
        {
          path: "/users",
          meta: {
            label: i18n.t("menu.users")
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/users",
              name: i18n.t("crud.list"),
              component: User,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/users/create",
              name: i18n.t("crud.create"),
              component: UserCreate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/users/edit/:id",
              name: i18n.t("crud.edit"),
              component: UserEdit,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/users/view/:id",
              name: i18n.t("crud.view"),
              component: UserView,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
