<template>
  <div>
    <div v-if="loading">
      <CSpinner color="info" class="loading" /><br />
      <div class="text-center">
        <CButton @click="reloadPage" color="secondary" size="sm">
          Refresh
        </CButton>
      </div>
    </div>
    <div v-else-if="forbidden">
      <CAlert
        color="warning"
        closeButton
        v-html="$t('messaging.forbidden')"
      ></CAlert>
    </div>
    <div v-else>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="6">
                  <h4 id="traffic" class="card-title mb-0">
                    {{ $t("menu.messaging") }}
                  </h4>
                  <div class="small text-muted">
                    {{ $t("messaging.perex") }}
                  </div>
                </CCol>
                <CCol sm="6" class="text-right pt-2">
                  <CButtonGroup>
                    <CButton
                      color="info"
                      variant="outline"
                      :to="
                        '/pap/messaging?userId=' +
                          this.$route.query.userId +
                          '&session=' +
                          this.$route.query.session +
                          '&role=' +
                          this.$route.query.role
                      "
                    >
                      {{ $t("messaging.inbox") }}
                    </CButton>
                    <CButton
                      color="info"
                      :to="
                        '/pap/messaging/publishers?userId=' +
                          this.$route.query.userId +
                          '&session=' +
                          this.$route.query.session +
                          '&role=' +
                          this.$route.query.role
                      "
                    >
                      {{ $t("system.affiliates") }}
                    </CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <Search ref="search" v-bind:columns="this.search" class="p-0" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="6" class="mb-1">
                  <h4 class="card-title">
                    {{ $t("messaging.total_commissions") }} -
                    {{ campaignName }} / {{ affiliateName }}
                  </h4>
                </CCol>
                <CCol sm="6" class="text-right">
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    variant="outline"
                    :to="
                      '/pap/messaging/publishers?userId=' +
                        this.$route.query.userId +
                        '&session=' +
                        this.$route.query.session +
                        '&role=' +
                        this.$route.query.role
                    "
                  >
                    {{ $t("system.affiliates") }}
                  </CButton>
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="{
                      path:
                        '/pap/messaging/create' +
                        '?campaignId=' +
                        this.$route.params.campaignId +
                        '&affiliateId=' +
                        this.$route.params.affiliateId +
                        '&userId=' +
                        xPap.userId +
                        '&session=' +
                        xPap.session +
                        '&role=' +
                        xPap.role
                    }"
                  >
                    + {{ $t("messaging.write-new-message") }}
                  </CButton>
                </CCol>
              </CRow>
              <Sort ref="sort" />
              <CDataTable :loading="reloading" :items="items" :fields="fields">
                <template v-slot:no-items-view>
                  <div class="text-center my-5">
                    <h2>
                      {{ $t("message.search-empty") }}
                    </h2>
                  </div>
                </template>
                <template #lastclickreferer="{item}">
                  <td v-if="item.lastclickreferer === null">---</td>
                  <td v-else>{{ item.lastclickreferer }}</td>
                </template>
                <template #commission="{item}">
                  <td>{{ item.commission }} €</td>
                </template>
                <template #totalcost="{item}">
                  <td>{{ item.totalcost }} €</td>
                </template>
                <template #dateinserted="{item}">
                  <td>
                    {{ dateFormat(item.dateinserted) }}
                  </td>
                </template>
                <template #rstatus="{item}">
                  <td>
                    <CBadge :color="getBadge(item.rstatus)">
                      {{
                        $t(
                          "enum." +
                            $store.state.enumerators.enumerators[
                              "transaction-rstatus"
                            ][item.rstatus]
                        )
                      }}
                    </CBadge>
                  </td>
                </template>
              </CDataTable>
              <Pagination
                :totalCount="this.page.totalCount"
                :perPage="this.page.perPage"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Search from "@/components/ui/Search";
import moment from "moment";
import Pagination from "@/components/ui/Pagination";
import Sort from "@/components/ui/Sort";

export default {
  name: "MessagingPapPublishersDetail",
  components: {
    Search,
    Pagination,
    Sort
  },
  data() {
    return {
      loading: false,
      reloading: false,
      forbidden: false,
      isSubmitting: false,
      xPap: {
        userId: null,
        session: null,
        role: null
      },
      items: [],
      campaignName: null,
      affiliateName: null,
      selectOptions: [],
      page: {
        totalCount: 0,
        perPage: 0
      }
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification",
      setLang: "auth/setLang"
    }),

    reloadPage() {
      window.location.reload();
    },

    getBadge(status) {
      switch (status) {
        case "P":
          return "info";
        case "D":
          return "danger";
        default:
          return "success";
      }
    },

    dateFormat(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
    },

    createUrlXPap() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          userId: this.xPap.userId,
          session: this.xPap.session,
          role: this.xPap.role
        })
      });
    },

    pagination() {
      this.getItems();
      this.createUrlXPap();
    },

    getItems() {
      let self = this;
      this.loading = true;
      this.forbidden = false;
      this.reloading = true;

      let query = this.$refs.search.parseUrlQuery();

      crud
        .get(
          "messaging/transactions/" +
            this.$route.params.affiliateId +
            "/" +
            this.$route.params.campaignId +
            query,
          {
            headers: {
              "X-Pap-UserId": this.xPap.userId,
              "X-Pap-SessionId": this.xPap.session,
              "X-Pap-Role": this.xPap.role
            }
          }
        )
        .then(function(response) {
          self.items = [];
          for (const key of Object.values(response.data.items)) {
            self.items.push(key);
          }

          self.page.totalCount = response.data._meta.totalCount;
          self.page.perPage = response.data._meta.perPage;

          self.campaignName = response.data._meta.campaign_name;
          self.affiliateName = response.data._meta.affiliate_name;

          self.reloading = false;
          self.loading = false;
        })
        .catch(() => {
          self.forbidden = true;
          self.reloading = false;
          self.loading = false;
        });
    }
  },
  created() {
    this.setLang();
  },
  mounted() {
    this.xPap.userId = this.$route.query.userId;
    this.xPap.session = this.$route.query.session;
    this.xPap.role = this.$route.query.role;
    this.getItems();
  },
  computed: {
    fields: function() {
      return [
        {
          key: "lastclickreferer",
          label: i18n.t("messaging.referer")
        },
        {
          key: "commission",
          label: i18n.t("messaging.commission")
        },
        {
          key: "totalcost",
          label: i18n.t("messaging.totalcost")
        },
        {
          key: "t_orderid",
          label: i18n.t("messaging.t_orderid")
        },
        {
          key: "dateinserted",
          label: i18n.t("system.created_at")
        },
        {
          key: "rstatus",
          label: i18n.t("system.status")
        },
        {
          key: "id",
          label: i18n.t("messaging.id_commission")
        }
      ];
    },
    search: function() {
      let rstatus = this.$store.state.enumerators.enumerators[
        "transaction-rstatus"
      ];
      let status = {};
      for (let i in rstatus) {
        status[i] = i18n.t("enum." + rstatus[i]);
      }

      let erange = this.$store.state.enumerators.enumerators[
        "gpf-filter-range"
      ];
      let range = [];
      for (let i in erange) {
        range.push({ id: i, name: i18n.t("enum." + i) });
      }

      return [
        {
          name: "date_range",
          options: range,
          label: i18n.t("messaging.range"),
          type: "select2",
          operator: "and",
          like: false
        },
        {
          name: "transaction_rstatus",
          label: i18n.t("system.status"),
          options: status,
          type: "checkbox-group",
          operator: "and"
        }
      ];
    }
  }
};
</script>
