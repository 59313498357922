<template>
  <div>
    <div v-if="loading">
      <CSpinner color="info" class="loading" />
    </div>
    <div v-else>
      <CRow v-if="campaignsId.length > 1">
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <CButton
                v-for="(key, index) in campaignsId"
                :key="key[0]"
                color="info"
                size="sm"
                class="m-2"
                v-on:click="clickTab(index)"
                :variant="actualCampaignId[0] === key[0] ? '' : 'outline'"
              >
                {{ $t("integration-process.campaign-name") }}: {{ key[1] }}
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <h2>
                {{ $t("integration-process.campaign-integraton") }} -
                {{ this.items["campaign_name"] }}
              </h2>
              <br />
              <p>
                {{ $t("integration-process.campaign-id") }}:
                {{ this.items["campaign_id"] }}
              </p>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <div v-for="item in items.steps" :key="item.type">
            <StepFiles
              ref="steps"
              :step-item.sync="item"
              :campaign-name="items['campaign_name'] | slug"
            />
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";
import StepFiles from "./IntegrationProcessPapAllSteps.vue";

export default {
  name: "IntegrationProcessPapStepsIndex",
  components: {
    StepFiles
  },
  data() {
    return {
      loading: false,
      collapse: false,
      isSubmitting: false,
      actualCampaignId: [],
      campaignsId: [],
      items: []
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    clickTab(e) {
      if (e !== 0) {
        window.location.href =
          "/pap/integration-process/" +
          e +
          "/?userId=" +
          this.$route.query.userId +
          "&session=" +
          this.$route.query.session +
          "&role=" +
          this.$route.query.role;
      } else {
        window.location.href =
          "/pap/integration-process/?userId=" +
          this.$route.query.userId +
          "&session=" +
          this.$route.query.session +
          "&role=" +
          this.$route.query.role;
      }
    },

    getCampaignsID: function() {
      this.loading = true;
      let self = this;

      crud
        .get(
          "pap/verify-session?userId=" +
            this.$route.query.userId +
            "&session=" +
            this.$route.query.session +
            "&role=" +
            this.$route.query.role +
            "&force=true"
        )
        .then(function(response) {
          /*let campaignIdFromApi = [{
              "00268d4b": "Dognet (bonuses, compensations, etc)",
              "00296b80": "Dognet2"
            }];*/

          //set lang
          //self.$i18n.locale = "en";
          if (response.data.lang) {
            self.$i18n.locale = response.data.lang.split("-")[0];
          }

          let campaignIdFromApi = [response.data.campaigns];

          self.campaignsId = Object.entries(campaignIdFromApi[0]);

          let tab = 0;
          if (self.$route.params.tab) {
            tab = self.$route.params.tab;
          }

          self.actualCampaignId = self.campaignsId[tab];
          self.getCampaignInfo();
        });
    },

    getCampaignInfo() {
      let self = this;

      crud
        .get(
          "integration-process/campaigns?expand=steps&filter[campaign_id]=" +
            this.actualCampaignId[0],
          {
            headers: {
              "X-Pap-UserId": this.$route.query.userId,
              "X-Pap-SessionId": this.$route.query.session
            }
          }
        )
        .then(function(response) {
          if (response.data.items.length > 0) {
            self.items = [];
            self.items = response.data.items[0];
          }
          self.loading = false;
        });
    }
  },
  created() {
    this.getCampaignsID();
  },
  computed: {
    step: function() {
      let ls = localStorage.getItem("vuex")
        ? localStorage.getItem("vuex")
        : "{}";

      return JSON.parse(ls).enumerators.enumerators["step-type"];
    }
  }
};
</script>

<style>
.collapseButton {
  cursor: pointer;
}
.collapseButton:hover {
  background: #f3f3f3;
}
.dropzone {
  background: #e3fff7 !important;
  padding-top: 35px !important;
}
</style>
