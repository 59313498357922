<template>
  <div>
    <CSpinner color="info" class="loading" v-if="loading" />
    <CForm @submit.prevent="submit" v-else>
      <CRow class="mb-3">
        <CCol lg="6">
          <label for="campaign" :class="{ 'text-danger': errorCampaign }">{{
            $t("integration-process.campaign-name")
          }}</label>
          <CInput
            v-if="isEdit"
            :value="form.campaignName"
            disabled="disabled"
          />
          <v-select
            v-else
            :options="selectOptions"
            label="name"
            v-model="form.selected"
            id="campaign"
          ></v-select>
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CCol lg="6">
          <CInput
            :label="$t('messaging.recipients_merchant')"
            id="recipients"
            name="recipients"
            :description="$t('messaging.recipients_description')"
            v-model="form.merchantRecipients"
            :addLabelClasses="{ 'text-danger': errorM }"
            :addInputClasses="{ 'text-danger': errorM }"
          />
        </CCol>
        <CCol lg="6">
          <CInput
            :label="$t('messaging.recipients_network')"
            id="recipients"
            name="recipients"
            :description="$t('messaging.recipients_description')"
            v-model="form.networkRecipients"
            :addLabelClasses="{ 'text-danger': errorD }"
            :addInputClasses="{ 'text-danger': errorD }"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="2">
          <label>
            {{ $t("system.status") }}
          </label>
          <CSelect
            id="status"
            name="status"
            :options="
              $options.filters.objectToSelectBox(
                $store.state.enumerators.enumerators['campaign-setting-status']
              )
            "
            :value.sync="form.status"
          />
        </CCol>
        <CCol lg="2">
          <p>{{ $t("messaging.m-message") }}?</p>
          <CSwitch
            type="checkbox"
            id="messagesM"
            color="success"
            shape="pill"
            :checked.sync="form.messagesM.checked"
          />
          <label for="messagesM" class="switchCheckBox">
            <strong v-if="form.messagesM.checked">{{
              $t("system.yes")
            }}</strong>
            <strong v-if="!form.messagesM.checked">{{
              $t("system.no")
            }}</strong>
          </label>
        </CCol>
        <CCol lg="2">
          <p>{{ $t("messaging.p-message") }}?</p>
          <CSwitch
            type="checkbox"
            id="messagesP"
            color="success"
            shape="pill"
            :checked.sync="form.messagesP.checked"
          />
          <label for="messagesP" class="switchCheckBox">
            <strong v-if="form.messagesP.checked">{{
              $t("system.yes")
            }}</strong>
            <strong v-if="!form.messagesP.checked">{{
              $t("system.no")
            }}</strong>
          </label>
        </CCol>
      </CRow>

      <hr />

      <CButton
        v-if="isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
        ><CSpinner color="white" size="sm" v-if="isSubmitting" />
        {{ $t("system.update") }}</CButton
      >
      <CButton
        v-if="!isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
      >
        <CSpinner color="white" size="sm" v-if="isSubmitting" />
        {{ $t("crud.create") }}
      </CButton>
      <CButton
        size="sm"
        color="secondary"
        class="ml-2"
        to="/messaging/settings"
        >{{ $t("system.cancel") }}</CButton
      >
    </CForm>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import i18n from "@/i18n";

export default {
  name: "MessagingSettingsCreateForm",
  props: ["isEdit"],
  components: {
    vSelect
  },
  data() {
    return {
      loading: true,
      errorM: false,
      errorD: false,
      errorCampaign: false,
      form: {
        campaignName: null,
        status: "1",
        messagesM: {
          checked: false
        },
        messagesP: {
          checked: false
        },
        networkRecipients: null,
        merchantRecipients: null,
        selected: {}
      },
      usedCampaigns: [],
      selectOptions: [],
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getUsedCampaigns() {
      let self = this;

      crud
        .get("messaging/campaign-settings?per-page=100000")
        .then(function(response) {
          self.usedCampaigns = response.data.items.map(function(item) {
            return item["campaign_id"];
          });
          self.getCampaigns();
        });
    },

    getCampaignByID() {
      this.loading = true;
      let self = this;

      crud
        .get("messaging/campaign-settings/" + this.$route.params.id)
        .then(function(response) {
          let data = response.data;
          self.form.campaignName = data.campaign_name;
          self.form.selected.id = data.campaign_id;
          self.form.networkRecipients =
            data.network_recipients === null || data.network_recipients === ""
              ? null
              : data.network_recipients.toString();
          self.form.merchantRecipients =
            data.merchant_recipients === null || data.merchant_recipients === ""
              ? null
              : data.merchant_recipients.toString();
          self.form.status = String(data.status);
          self.form.messagesM.checked = data.m_can_new_message === 1;
          self.form.messagesP.checked = data.a_can_new_message === 1;
          self.loading = false;
        });
    },

    getCampaigns() {
      this.loading = true;
      let self = this;
      crud
        .get("pap/campaigns?fields=id,name&per-page=100000")
        .then(function(response) {
          self.selectOptions = response.data.items.filter(
            value => !self.usedCampaigns.includes(value.id)
          );
          self.loading = false;
        });
    },

    validateEmail(type) {
      let recipients = null;
      if (type === "M") {
        recipients = this.form.merchantRecipients;
      } else {
        recipients = this.form.networkRecipients;
      }
      let recipientsArray = [];
      if (recipients === null || recipients === "") {
        if (type === "M") {
          this.errorM = false;
        } else {
          this.errorD = false;
        }
      } else {
        recipientsArray = recipients.split(",");
        for (let r in recipientsArray) {
          let x = this.$options.filters.validateEmail(recipientsArray[r]);
          if (type === "M" && !x) this.errorM = true;
          if (type === "D" && !x) this.errorD = true;
        }
      }
      this.isSubmitting = false;
      return recipientsArray;
    },

    submit() {
      this.isSubmitting = true;
      this.errorM = false;
      this.errorD = false;
      this.errorCampaign = false;

      let data = this.isEdit ? new URLSearchParams() : new FormData();
      let recipientsArrayM = this.validateEmail("M");
      let recipientsArrayD = this.validateEmail("D");

      if (this.form.selected === null) {
        this.errorCampaign = true;
      } else {
        Object.keys(this.form.selected).length !== 0
          ? data.append("campaign_id", this.form.selected.id)
          : (this.errorCampaign = true);
      }

      if (!this.errorM && !this.errorD && !this.errorCampaign) {
        data.append("merchant_recipients", recipientsArrayM);
        data.append("network_recipients", recipientsArrayD);
        data.append("status", this.form.status);

        let m = this.form.messagesM.checked ? 1 : 0;
        data.append("m_can_new_message", m);

        let p = this.form.messagesP.checked ? 1 : 0;
        data.append("a_can_new_message", p);

        if (this.isEdit) {
          //problem with selected, must be [0]
          data.append("campaign_id", this.form.selected.id);

          crud
            .put("messaging/campaign-settings/" + this.$route.params.id, data)
            .then(() => {
              this.isSubmitting = false;
              this.$router.push("/messaging/settings");
              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-updated")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;
              this.addNotification({
                type: "danger",
                message: error.response.data[0].message
              });
            });
        } else {
          crud
            .post("messaging/campaign-settings", data)
            .then(() => {
              this.isSubmitting = false;
              this.$router.push("/messaging/settings");
              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-add")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;
              this.addNotification({
                type: "danger",
                message: error.response.data[0].message
              });
            });
        }
      } else {
        this.isSubmitting = false;
      }
    }
  },
  created() {
    this.isEdit ? this.getCampaignByID() : this.getUsedCampaigns();
  }
};
</script>
