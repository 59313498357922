<template>
  <div>
    <CSpinner color="info" class="loading" /><br />
    <div class="text-center">
      <CButton @click="reloadPage" color="secondary" size="sm">
        Refresh
      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  methods: {
    reloadPage() {
      window.location.reload();
    }
  }
};
</script>
