<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <h4 class="card-title mb-0">
              {{ $t("menu.messaging") }}
            </h4>
            <div class="small text-muted">{{ $t("messaging.perex") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButtonGroup>
              <CButton color="info" to="/messaging">
                {{ $t("messaging.inbox") }}
              </CButton>
              <CButton color="info" variant="outline" to="/messaging/settings">
                {{ $t("system.settings") }}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CSpinner color="info" class="loading" v-if="loading" />
    <CCard v-else>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 class="card-title mb-0" v-if="item.campaignId">
              {{ item.campaignName }}<small>({{ item.campaignId }})</small> /
              {{ item.affiliateName }} <small>({{ item.affiliateId }})</small>
            </h4>
          </CCol>
          <CCol sm="6" class="text-right">
            <CButton size="sm" color="secondary" class="ml-1" to="/messaging">
              {{ $t("system.close") }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="6" id="chat">
            <div
              v-for="item in items"
              :key="item.id"
              class="container"
              :class="
                item.sender_type === 1
                  ? 'sender-type-right'
                  : 'sender-type-left'
              "
            >
              <div class="bubble">
                <p class="sender">
                  {{
                    item.sender_type === 1
                      ? item.campaign_name
                      : item.affiliate_name
                  }}
                </p>
                <div class="body" v-html="item.body"></div>
                <div v-if="item.seen_at === null">
                  <small
                    ><i class="fa fa-eye-slash" aria-hidden="true"></i
                  ></small>
                </div>
                <div v-else>
                  <small
                    ><i class="fa fa-eye" aria-hidden="true"></i>
                    {{ dateFormat(item.seen_at) }}</small
                  >
                </div>
              </div>
              <p class="date">
                <small
                  >{{ $t("system.sent") }}:
                  {{ dateFormat(item.created_at) }}</small
                >
              </p>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "MessagingView",
  data() {
    return {
      loading: false,
      item: {
        campaignId: null,
        campaignName: null,
        affiliateName: null,
        affiliateId: null
      },
      items: []
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    dateFormat(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
    },

    getMessages() {
      this.loading = true;
      let self = this;

      crud
        .get(
          "messaging/messages?filter[campaign_id]=" +
            this.$route.params.campaignId +
            "&filter[affiliate_id]=" +
            this.$route.params.affiliateId +
            "&sort=-created_at&per-page=100000"
        )
        .then(function(response) {
          self.items = response.data.items;
          if (response.data.items.length > 0) {
            self.item.campaignId = response.data.items[0].campaign_id;
            self.item.campaignName = response.data.items[0].campaign_name;
            self.item.affiliateId = response.data.items[0].affiliate_id;
            self.item.affiliateName = response.data.items[0].affiliate_name;
          }
          self.loading = false;
        });
    }
  },
  mounted() {
    this.getMessages();
  }
};
</script>
