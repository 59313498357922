<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("roles.role") }}
            </h4>
            <div class="small text-muted">{{ $t("crud.view") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            &nbsp;
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <strong>{{ $t("roles.roleName") }}</strong
            >:
            {{ roles.name }}
            <CButton
              color="info"
              square
              size="sm"
              :to="'/users/roles/edit/' + this.$route.params.name"
            >
              {{ $t("crud.edit") }}
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CForm @submit.prevent="submit">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol sm="6">
              <h4 id="traffic" class="card-title mb-0">
                {{ $t("permissions.permissions") }}
              </h4>
            </CCol>
            <CCol sm="6" class="text-right pt-2">
              <input type="checkbox" id="selectAll" v-model="selectAll" />
              <label for="selectAll" class="ml-2">{{
                $t("system.select-all")
              }}</label>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              <br />
              <CSpinner color="info" class="loading" v-if="loading" />
              <CDataTable
                v-else
                :items="permissions"
                :fields="fields"
                hover
                column-filter
              >
                <template #name="{item, index}">
                  <td>
                    <input
                      type="checkbox"
                      :value="item.name"
                      v-model="permissions[index].checked"
                      :id="item.name"
                    />
                    <label :for="item.name" class="ml-2">{{ item.name }}</label>
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton size="sm" class="mr-2" color="success" type="success">
            <CSpinner color="white" size="sm" v-if="isSubmitting" />
            {{ $t("system.update") }}</CButton
          >
          <CButton color="secondary" square size="sm" to="/users/roles">
            {{ $t("system.cancel") }}
          </CButton>
        </CCardFooter>
      </CCard>
    </CForm>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";
import { mapActions } from "vuex";

const fields = [
  {
    key: "name",
    label: i18n.t("permissions.permissionName")
  },
  {
    key: "description",
    label: i18n.t("system.description"),
    filter: false
  }
];

export default {
  name: "RoleView",
  data() {
    return {
      loading: true,
      selected: false,
      roles: [],
      fields,
      permissions: [],
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getRoleInfo() {
      var self = this;
      crud
        .get("auth-roles/" + this.$route.params.name)
        .then(function(response) {
          self.roles = response.data;
        });
    },
    getAllPermissions() {
      let self = this;
      let page = this.$route.query.page
        ? "&page=" + this.$route.query.page
        : "";

      crud
        .get("auth-roles?filter[type]=2&per-page=100000" + page)
        .then(function(response) {
          let res = [];
          for (let j = 0; j < response.data.items.length; j++) {
            res.push({
              name: response.data.items[j].name,
              description: response.data.items[j].description,
              checked: false
            });
          }
          self.permissions = res;
          self.loading = false;
        });
    },
    setCurrentPermissions() {
      var self = this;
      crud
        .get("auth-roles/" + this.$route.params.name + "?expand=children")
        .then(function(response) {
          let permissions = self.permissions;
          let current = response.data.children;
          for (let i = 0; i < permissions.length; i++) {
            for (let j = 0; j < current.length; j++) {
              if (current[j].name === permissions[i].name) {
                self.permissions[i].checked = true;
              }
            }
          }

          self.loading = false;
        });
    },
    submit() {
      this.isSubmitting = true;

      let listOfObjects = Object.keys(this.permissions)
        .map(key => {
          if (this.permissions[key].checked === true)
            return this.permissions[key].name;
        })
        .filter(anyValue => typeof anyValue !== "undefined");

      if (listOfObjects) {
        let data = {};
        data.role = String(this.roles.name);
        data.permissions = listOfObjects;

        crud
          //.post("auth-role-assigns?deleteOld=true", data)
          .post("auth-role-assigns", data)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/users/roles");
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-updated")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  computed: {
    selectAll: {
      get: function() {
        return this.selected ? true : false;
      },
      set: function(value) {
        if (value) {
          for (let i = 0; i < this.permissions.length; i++) {
            this.permissions[i].checked = true;
          }
        } else {
          for (let i = 0; i < this.permissions.length; i++) {
            this.permissions[i].checked = false;
          }
        }
      }
    }
  },
  mounted() {
    this.getRoleInfo();
    this.getAllPermissions();
    this.setCurrentPermissions();
  }
};
</script>

<style></style>
