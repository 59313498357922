<template>
  <div>
    <div v-if="loading">
      <CSpinner color="info" class="loading" /><br />
      <div class="text-center">
        <CButton @click="reloadPage" color="secondary" size="sm">
          Refresh
        </CButton>
      </div>
    </div>
    <div v-else-if="forbidden">
      <CAlert color="warning" closeButton v-html="$t('messaging.forbidden')">
      </CAlert>
    </div>
    <div v-else>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="12">
                  <h4 id="traffic" class="card-title mb-0">
                    {{ $t("menu.messaging") }}
                  </h4>
                  <div class="small text-muted">
                    {{ $t("messaging.perex") }}
                  </div>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>
        </CCol>
      </CRow>

      <CAlert
        v-if="this.$route.query.role === 'A'"
        color="warning"
        closeButton
        v-html="$t('messaging.publisher_warning')"
      ></CAlert>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="6" class="mb-1">
                  <h4 class="card-title">
                    {{ $t("messaging.new-message") }}
                  </h4>
                </CCol>
                <CCol sm="6" class="mb-1 text-right">
                  <CButton
                    size="sm"
                    color="secondary"
                    class="ml-1"
                    :to="
                      '/pap/messaging?userId=' +
                        this.$route.query.userId +
                        '&session=' +
                        this.$route.query.session +
                        '&role=' +
                        this.$route.query.role
                    "
                  >
                    {{ $t("system.cancel") }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="6">
                  <CSelect
                    :label="$t('menu.campaigns')"
                    :placeholder="
                      selectOptionsCampaign.length === 0
                        ? $t('messaging.no_campaign')
                        : ''
                    "
                    :disabled="selectOptionsCampaign.length === 0"
                    id="campaigns"
                    name="campaigns"
                    :options="selectOptionsCampaign"
                    :value.sync="form.selectedCampaign"
                    @change="selectAffiliateOptions"
                  />
                </CCol>
                <CCol lg="6" v-if="xPap.role === 'M'">
                  <span v-if="loadingAffil">
                    <label>{{ $t("system.affiliate") }}</label
                    ><br />
                    <CSpinner color="info" size="sm" />
                  </span>
                  <CSelect
                    v-else
                    :label="$t('system.affiliate')"
                    id="affiliate"
                    name="affiliate"
                    :options="selectOptionsAffiliate"
                    :value.sync="form.selectedAffiliate"
                    :disabled="selectOptionsAffiliate.length > 0 === false"
                  />
                </CCol>
                <CCol lg="12">
                  <WysiwygEditor
                    :button-title="$t('system.send')"
                    :button-clear-title="$t('system.clear')"
                    :is-submitting="isSubmitting"
                    @save="send"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import crud from "@/store/modules/crud";
import WysiwygEditor from "@/components/ui/WysiwygEditor";
import i18n from "@/i18n";

export default {
  name: "MessagingPapCreate",
  components: {
    WysiwygEditor
  },
  data() {
    return {
      loading: false,
      sendLoading: false,
      loadingAffil: false,
      forbidden: false,
      isSubmitting: false,
      xPap: {
        userId: null,
        session: null,
        role: null
      },
      selectOptionsCampaign: [],
      selectOptionsAffiliate: [],
      form: {
        selectedCampaign: null,
        selectedAffiliate: null
      }
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification",
      setLang: "auth/setLang"
    }),

    reloadPage() {
      window.location.reload();
    },

    selectCampaignOptions() {
      this.loading = true;
      let self = this;

      crud
        .get("messaging/recipients/campaigns?per-page=100000", {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          let optionsCampaign = [];

          for (const value of response.data.items) {
            if (
              !optionsCampaign.some(
                options => options["value"] === value["campaign_id"]
              ) &&
              value["status"] === 1
            ) {
              optionsCampaign.push({
                value: value["campaign_id"],
                label:
                  value["campaign_name"] + " - (" + value["campaign_id"] + ")"
              });
            }
          }

          self.selectOptionsCampaign = optionsCampaign;
          if (optionsCampaign.length > 0) {
            if (self.$route.query.campaignId) {
              self.form.selectedCampaign = self.$route.query.campaignId;
            } else {
              self.form.selectedCampaign = optionsCampaign[0].value;
            }
            self.selectAffiliateOptions();
          }
          self.loading = false;
        });
    },

    selectAffiliateOptions() {
      let self = this;

      if (self.xPap.role === "M") {
        this.loadingAffil = true;
        crud
          .get(
            "messaging/recipients/campaign/" +
              this.form.selectedCampaign +
              "?per-page=100000",
            {
              headers: {
                "X-Pap-UserId": this.xPap.userId,
                "X-Pap-SessionId": this.xPap.session,
                "X-Pap-Role": this.xPap.role
              }
            }
          )
          .then(function(response) {
            let optionsAffiliate = [
              {
                value: "",
                label: ""
              }
            ];

            for (const value of response.data.items) {
              if (
                !optionsAffiliate.some(
                  options => options["value"] === value["userid"]
                )
              ) {
                optionsAffiliate.push({
                  value: value["userid"],
                  label: value["fullname"] + " - (" + value["userid"] + ")"
                });
              }
            }

            self.selectOptionsAffiliate = optionsAffiliate;
            self.form.selectedAffiliate = null;
            if (optionsAffiliate.length > 0) {
              if (self.$route.query.affiliateId) {
                self.form.selectedAffiliate = self.$route.query.affiliateId;
              } else {
                self.form.selectedAffiliate = optionsAffiliate[0].value;
              }
            }
            self.loadingAffil = false;
          });
      }
    },

    send(editor) {
      let self = this;
      self.isSubmitting = true;
      let obj =
        this.xPap.role === "A"
          ? {
              campaign_id: this.form.selectedCampaign,
              affiliate_id: this.xPap.userId,
              body: editor
            }
          : {
              campaign_id: this.form.selectedCampaign,
              affiliate_id: this.form.selectedAffiliate,
              body: editor
            };

      if (
        obj.campaign_id !== null &&
        (obj.affiliate_id !== null || obj.affiliate_id !== "") &&
        obj.body !== ""
      ) {
        crud
          .post("messaging/messages", obj, {
            headers: {
              "X-Pap-UserId": this.xPap.userId,
              "X-Pap-SessionId": this.xPap.session,
              "X-Pap-Role": this.xPap.role
            }
          })
          .then(function(response) {
            if (response.status >= 200 && response.status < 300) {
              self.isSubmitting = false;
              self.$router.push(
                "/pap/messaging?userId=" +
                  self.$route.query.userId +
                  "&session=" +
                  self.$route.query.session +
                  "&role=" +
                  self.$route.query.role
              );

              self.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-add")
                },
                { root: true }
              );
            } else {
              self.isSubmitting = false;
              self.addNotification({
                type: "danger",
                message: i18n.t("error.oops")
              });
            }
          })
          .catch(() => {
            self.isSubmitting = false;
            self.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          });
      } else {
        self.isSubmitting = false;
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      }
    }
  },
  created() {
    this.setLang();
  },
  mounted() {
    this.xPap.userId = this.$route.query.userId;
    this.xPap.session = this.$route.query.session;
    this.xPap.role = this.$route.query.role;

    this.selectCampaignOptions();
  },

  watch: {
    "item.selectOptionsCampaign": function(newVal) {
      console.log(newVal);
      if (newVal !== null) {
        this.selectAffiliateOptions();
      }
    }
  }
};
</script>
