// Views - auth
import LogIn from "../views/auth/LogIn.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPasswordForm from "../views/auth/ResetPasswordForm.vue";
import Loading from "../views/auth/Loading.vue";

// Views
import Dashboard from "../views/Dashboard.vue";

export default [
  {
    data: {
      pap: [
        {
          path: "/login",
          name: "login",
          component: LogIn,
          meta: {
            requiresAuth: false
          }
        },
        {
          path: "/auth/forgot-password",
          name: "forgot-password",
          component: ForgotPassword,
          meta: {
            requiresAuth: false
          }
        },
        {
          path: "/auth/reset-password/:token",
          name: "reset-password-form",
          component: ResetPasswordForm,
          meta: {
            requiresAuth: false
          }
        },
        {
          path: "/loading",
          name: "loading",
          component: Loading,
          meta: {
            requiresAuth: true
          }
        }
      ],
      admin: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  }
];
