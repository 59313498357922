import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import i18n from "./i18n";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import filters from "./components/filters.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "font-awesome/css/font-awesome.min.css";
//import crud from "@/store/modules/crud";

require("@/store/subscriber");

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.validateStatus = status => {
  return status >= 200 && status < 300;
};

Vue.config.productionTip = false;

Vue.use(CoreuiVue);

let auth = store.dispatch("auth/attempt", {
  token: localStorage.getItem("token"),
  user_id: localStorage.getItem("user_id")
});

auth.then(() => {
  let promises = [];

  /*
    let query = new URL(location.href);

    if (store.getters["auth/authenticated"] || query.searchParams.get("userId")) {
      promises.push(store.dispatch("enumerators/fetchEnumerators", {}));
    }*/

  //set lang brom admin/ or set lang from PAP in store/auth.js
  let lang = store.getters["auth/authenticated"];
  if (lang) {
    i18n.locale = lang.language.split("-")[0];
  }

  promises.push(store.dispatch("enumerators/fetchEnumerators", {}));

  Promise.all(promises).then(() => {
    new Vue({
      router,
      store,
      i18n,
      icons,
      filters,
      render: h => h(App)
    }).$mount("#app");
  });
});
