<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.messaging") }}
            </h4>
            <div class="small text-muted">{{ $t("messaging.perex") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButtonGroup>
              <CButton color="info" to="/messaging">
                {{ $t("messaging.inbox") }}
              </CButton>
              <CButton color="info" variant="outline" to="/messaging/settings">
                {{ $t("system.settings") }}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CRow>
              <CCol sm="6" class="pl-0">
                <Search ref="search" v-bind:columns="this.search" />
              </CCol>
              <CCol sm="6" class="text-right">
                <br />
              </CCol>
            </CRow>

            <Sort ref="sort" />
            <h4>{{ $t("messaging.inbox") }}</h4>
            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable
              v-else
              :items="items"
              :fields="fields"
              :sorter="{ external: true, resetable: false }"
              hover
              addTableClasses="clicktable"
              @row-clicked="rowClocked"
              @update:sorter-value="$refs.sort.sortTable"
            >
              <template v-slot:no-items-view>
                <div class="text-center my-5">
                  <h2>
                    {{ $t("message.search-empty") }}
                  </h2>
                </div>
              </template>
              <template #message_count="{item}">
                <td>
                  <CBadge color="success">
                    {{ item.message_count }}
                  </CBadge>
                </td>
              </template>
              <template #created_at="{item}">
                <td>
                  {{ dateFormat(item.created_at) }}
                </td>
              </template>
              <template #button="{item, index}">
                <td class="py-2 text-right">
                  <CButton
                    size="sm"
                    color="info"
                    variant="outline"
                    class="ml-1"
                    :to="{ path: '/integration-process/view/' + item.id }"
                  >
                    {{ $t("system.show") }}
                  </CButton>
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="{ path: '/integration-process/edit/' + item.id }"
                  >
                    {{ $t("system.update") }}
                  </CButton>
                  <CForm
                    @submit.prevent="submit"
                    class="d-inline"
                    v-if="$store.state.auth.user"
                  >
                    <CButton
                      size="sm"
                      color="danger"
                      class="ml-1"
                      v-on:click="deleteElement(item.id, index)"
                    >
                      {{ $t("system.delete") }}
                    </CButton>
                  </CForm>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
        <Pagination
          :totalCount="this.page.totalCount"
          :perPage="this.page.perPage"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Pagination from "@/components/ui/Pagination";
import Search from "@/components/ui/Search";
import Sort from "@/components/ui/Sort";
import moment from "moment";

export default {
  name: "Messaging",
  components: {
    Pagination,
    Search,
    Sort
  },
  data() {
    return {
      loading: true,
      items: [],
      status: 1,
      page: {
        totalCount: 0,
        perPage: 0
      },
      search: [
        {
          name: "campaign_id",
          options: [],
          label: i18n.t("integration-process.campaign-name"),
          type: "select2",
          operator: "or",
          like: false
        }
      ]
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    rowClocked(item) {
      this.$router.push(
        "/messaging/view/" + item.campaign_id + "/" + item.affiliate_id
      );
    },

    dateFormat(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
    },

    pagination() {
      this.getMessages();
    },

    sort() {
      this.getMessages();
    },

    selectOptions() {
      let self = this;

      //all campaigns to select box
      crud.get("messaging/inbox?per-page=100000").then(function(response) {
        let items = response.data.items;

        let obj = [];

        for (let key in items) {
          if (!obj.some(obj => obj["id"] === items[key].campaign_id)) {
            obj.push({
              id: items[key].campaign_id,
              name: items[key].campaign_name
            });
          }
        }
        self.search[0].options = obj;
      });
    },

    getMessages() {
      let self = this;
      let query = this.$refs.search.parseUrlQuery();

      crud.get("messaging/inbox" + query).then(function(response) {
        self.items = [];
        for (const key of Object.values(response.data.items)) {
          self.items.push(key);
        }

        self.page.totalCount = response.data._meta.totalCount;
        self.page.perPage = response.data._meta.perPage;

        self.loading = false;
      });

      self.selectOptions();
    }
  },
  mounted() {
    this.getMessages();
  },
  computed: {
    fields: function() {
      return [
        {
          key: "affiliate_name",
          label: i18n.t("system.affiliate")
        },
        {
          key: "campaign_name",
          label: i18n.t("integration-process.campaign-name")
        },
        {
          key: "body_perex",
          label: i18n.t("system.preview"),
          sorter: false
        },
        {
          key: "message_count",
          label: i18n.t("system.total-count"),
          sorter: false
        },
        {
          key: "created_at",
          label: i18n.t("messaging.last-communication")
        }
      ];
    }
  }
};
</script>
<style>
.table.table-hover.clicktable tbody tr:hover {
  cursor: pointer;
  background: #9aceff;
}
</style>
