<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.integration-process") }} /
              <span v-if="status === 3">{{
                $t("integration-process.campaigns-closed")
              }}</span>
              <span v-else-if="status === 2">{{
                $t("integration-process.campaigns-waiting")
              }}</span>
              <span v-else>{{
                $t("integration-process.campaigns-opened")
              }}</span>
            </h4>
            <div class="small text-muted">{{ $t("crud.list") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButton
              color="success"
              square
              size="sm"
              to="/integration-process/create"
            >
              +
              {{ $t("crud.create") + " " + $t("system.new") }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CRow>
              <CCol sm="6">
                <Search ref="search" v-bind:columns="this.search" />
              </CCol>
              <CCol sm="6" class="text-right">
                <br />
                <CButton
                  size="sm"
                  color="info"
                  class="ml-1"
                  :variant="status !== 1 ? 'ghost' : ''"
                  v-on:click="tabs(1)"
                >
                  {{ $t("integration-process.campaigns-opened") }}
                </CButton>
                <!--CButton
                  size="sm"
                  color="info"
                  class="ml-1"
                  :variant="status !== 2 ? 'ghost' : ''"
                  v-on:click="tabs(2)"
                >
                  {{ $t("integration-process.campaigns-waiting") }}
                </CButton-->
                <CButton
                  size="sm"
                  color="info"
                  class="ml-1"
                  :variant="status !== 3 ? 'ghost' : ''"
                  v-on:click="tabs(3)"
                >
                  {{ $t("integration-process.campaigns-closed") }}
                </CButton>
                <CButton
                  size="sm"
                  color="info"
                  class="ml-1"
                  :variant="status !== 0 ? 'ghost' : ''"
                  v-on:click="tabs(0)"
                >
                  {{ $t("integration-process.campaigns-all") }}
                </CButton>
              </CCol>
            </CRow>

            <Sort ref="sort" />
            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable
              v-else
              :items="items"
              :fields="fields"
              :sorter="{ external: true, resetable: false }"
              hover
              @update:sorter-value="$refs.sort.sortTable"
            >
              <template #status="{item}">
                <td>
                  <CBadge :color="getBadge(item.status)">
                    <span v-if="item.status === 3">{{
                      $t("system.closed")
                    }}</span>
                    <span v-else-if="item.status === 2">{{
                      $t("system.waiting")
                    }}</span>
                    <span v-else>{{ $t("system.open") }}</span>
                  </CBadge>
                </td>
              </template>
              <template #tasks_status="{item}">
                <td>
                  <span
                    v-for="(value, index) in getTasksStatus(item)"
                    :key="index"
                  >
                    <CBadge :color="computedStatus(index + 1)" class="mr-1">
                      <span
                        >{{
                          $t(
                            "enum." +
                              $store.state.enumerators.enumerators[
                                "step-status"
                              ][3 - index]
                          )
                        }}: {{ value }}</span
                      >
                    </CBadge>
                  </span>
                </td>
              </template>
              <template #button="{item, index}">
                <td class="py-2 text-right">
                  <CButton
                    size="sm"
                    color="info"
                    variant="outline"
                    class="ml-1"
                    :to="{ path: '/integration-process/view/' + item.id }"
                  >
                    {{ $t("system.show") }}
                  </CButton>
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="{ path: '/integration-process/edit/' + item.id }"
                  >
                    {{ $t("system.update") }}
                  </CButton>
                  <CForm
                    @submit.prevent="submit"
                    class="d-inline"
                    v-if="$store.state.auth.user"
                  >
                    <CButton
                      size="sm"
                      color="danger"
                      class="ml-1"
                      v-on:click="deleteElement(item.id, index)"
                    >
                      {{ $t("system.delete") }}
                    </CButton>
                  </CForm>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
        <Pagination
          :totalCount="this.page.totalCount"
          :perPage="this.page.perPage"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Pagination from "@/components/ui/Pagination";
import Search from "@/components/ui/Search";
import Sort from "@/components/ui/Sort";

export default {
  name: "IntegrationProcess",
  components: {
    Pagination,
    Search,
    Sort
  },
  data() {
    return {
      loading: true,
      items: [],
      tempItems: [],
      status: 1,
      page: {
        totalCount: 0,
        perPage: 0
      },
      search: [
        {
          name: "campaign_id",
          options: [],
          label: i18n.t("integration-process.campaign-name"),
          type: "select2",
          operator: "or",
          like: false
        }
      ]
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    pagination() {
      this.getActiveCampaign();
    },

    sort() {
      this.getActiveCampaign();
    },

    selectOptions() {
      let self = this;

      //all campaigns to select box
      crud
        .get("integration-process/campaigns?per-page=10000")
        .then(function(response) {
          let items = response.data.items;

          let obj = [];

          for (let key in items) {
            obj.push({
              id: items[key].campaign_id,
              name: items[key].campaign_name
            });
          }
          self.search[0].options = obj;
        });
    },

    getActiveCampaign() {
      let self = this;
      let query = this.$refs.search.parseUrlQuery();

      if (query.includes("&filter[or][][campaign_id]=")) this.status = 0;
      let statusFilter =
        this.status !== 0 ? "&filter[status]=" + this.status : "";

      crud
        .get(
          "integration-process/campaigns" +
            query +
            statusFilter +
            "&expand=steps"
        )
        .then(function(response) {
          self.tempItems = [];
          for (const key of Object.values(response.data.items)) {
            self.tempItems.push(key);
          }

          self.page.totalCount = response.data._meta.totalCount;
          self.page.perPage = response.data._meta.perPage;

          self.loading = false;

          self.campaignFilter();

          self.selectOptions();
        });
    },

    campaignFilter() {
      this.items = [];

      for (let key in this.tempItems) {
        let item = this.tempItems[key];
        let countAllSteps = item.steps.length;
        let countSteps = 0;

        for (let i = 0; i < countAllSteps; i++) {
          if (this.tempItems[key].steps[i].status === 3) {
            countSteps += 1;
          }
        }

        if (this.status === 0) {
          this.items.push(this.tempItems[key]);
        } else if (this.status !== 3) {
          if (countAllSteps !== countSteps) {
            this.items.push(this.tempItems[key]);
          }
        } else {
          if (countAllSteps === countSteps) {
            this.items.push(this.tempItems[key]);
          }
        }
      }
    },

    tabs(tab) {
      this.status = tab;
      if (Object.keys(this.$route.query).length > 0) {
        this.$refs.search.data = [];
        this.$router.push({ path: "integration-process" });
      }
      this.getActiveCampaign();
    },

    getBadge(status) {
      switch (status) {
        case 3:
          return "success";
        case 2:
          return "warning";
        default:
          return "danger";
      }
    },

    getTasksStatus(item) {
      let result = [0, 0, 0]; //todo,review,done

      for (const key of item.steps) {
        if (key.status === 1) {
          result[2] += 1;
        } else if (key.status === 2) {
          result[1] += 1;
        } else {
          result[0] += 1;
        }
      }
      return result;
    },

    computedStatus(status) {
      switch (status) {
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "danger";
        default:
          return "primary";
      }
    },

    deleteElement(id, index) {
      if (confirm(i18n.t("confirmation.delete"))) {
        let self = this;
        crud
          .delete("integration-process/campaigns/" + id)
          .then(function(response) {
            if (response.status === 204) {
              self.items.splice(index, 1);
              self.page.totalCount -= 1;

              self.addNotification({
                type: "success",
                message: i18n.t("success.record-deleted")
              });
            }
          })
          .catch(error => {
            self.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  mounted() {
    this.getActiveCampaign();
  },
  computed: {
    fields: function() {
      return [
        {
          key: "campaign_id",
          label: i18n.t("integration-process.campaign-id")
        },
        {
          key: "campaign_name",
          label: i18n.t("integration-process.campaign-name"),
          _style: "min-width:100px;",
          sorter: false
        },
        {
          key: "status",
          label: i18n.t("integration-process.campaign-status"),
          _style: "min-width:100px;",
          sorter: true
        },
        {
          key: "tasks_status",
          label: i18n.t("integration-process.tasks-status"),
          sorter: false
        },
        {
          key: "button",
          label: ""
        }
      ];
    }
  }
};
</script>

<style></style>
