<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm="4">
              <h4>{{ title }}</h4>
            </CCol>
            <CCol sm="8">
              <CNav class="top-menu ml-auto" style="justify-content: right;">
                <CNavItem
                  :to="{ path: item.link }"
                  :class="
                    $route.path +
                      '?userId=' +
                      $route.query.userId +
                      '&session=' +
                      $route.query.session +
                      '&role=' +
                      $route.query.role ===
                    item.link
                      ? 'active'
                      : ''
                  "
                  v-for="item in menu"
                  :key="item.name"
                  >{{ item.name }}</CNavItem
                >
              </CNav>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import i18n from "@/i18n";

export default {
  name: "ProductServiceTopMenu",
  props: ["title"],
  data() {
    return {
      menu: [
        {
          link:
            "/pap/product-service?userId=" +
            this.$route.query.userId +
            "&session=" +
            this.$route.query.session +
            "&role=" +
            this.$route.query.role,
          name: i18n.t("product-service.my-feeds")
        },
        {
          link:
            "/pap/product-service/create?userId=" +
            this.$route.query.userId +
            "&session=" +
            this.$route.query.session +
            "&role=" +
            this.$route.query.role,
          name: i18n.t("product-service.create-feed")
        }
      ]
    };
  }
};
</script>
