<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.messaging") }}
            </h4>
            <div class="small text-muted">{{ $t("messaging.perex") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButtonGroup>
              <CButton color="info" variant="outline" to="/messaging">
                {{ $t("messaging.inbox") }}
              </CButton>
              <CButton color="info" to="/messaging/settings">
                {{ $t("system.settings") }}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CRow>
              <CCol sm="12">
                <h5>{{ $t("messaging.add-new-campaign") }}</h5>
              </CCol>
            </CRow>
            <hr />
            <Form></Form>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Form from "@/views/messaging/MessagingSettingsForm";

export default {
  name: "MessagingSettingsCreate",
  components: {
    Form
  }
};
</script>

<style></style>
