import axios from "axios";
import i18n from "@/i18n";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    token: null,
    user: null
  },

  getters: {
    authenticated(state) {
      return state.token && state.user;
    },

    user(state) {
      return state.user;
    }
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_USER(state, data) {
      state.user = data;
    }
  },

  actions: {
    async logIn({ dispatch }, credentials) {
      let response = await axios.post("auth/login", credentials);

      return dispatch("attempt", {
        token: response.data._token.key,
        user_id: response.data._user.id
      });
    },

    async attempt({ commit, state, dispatch }, obj) {
      if (obj !== null) {
        if (obj.token) {
          commit("SET_TOKEN", obj.token);
        }
      }

      if (!state.token) {
        return;
      }

      try {
        await axios
          .get("users/" + obj.user_id, {
            headers: {
              "X-Api-Key": obj.token
            }
          })
          .then(response => {
            commit("SET_USER", response.data);
            if (response.data) {
              dispatch("enumerators/fetchEnumerators", null, { root: true });
            }
          });
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },

    logOut({ commit, dispatch, state }) {
      return axios
        .post("auth/logout", "", {
          headers: {
            "X-Api-Key": state.token
          }
        })
        .then(() => {
          dispatch(
            "notifications/addNotification",
            { type: "success", message: i18n.t("success.user-logout") },
            { root: true }
          );

          commit("SET_TOKEN", null);
          commit("SET_USER", null);
        });
    },

    async forgotPassword({ dispatch }, credentials) {
      return await axios
        .post("auth/forgot-password", {
          username: credentials.username
        })
        .then(result => {
          this.response = result.data;

          dispatch(
            "notifications/addNotification",
            { type: "success", message: i18n.t("success.email-sent") },
            { root: true }
          );
        })
        .catch(() => {
          dispatch(
            "notifications/addNotification",
            { type: "danger", message: i18n.t("error.username-incorrect") },
            { root: true }
          );
        });
    },

    async resetPassword({ dispatch }, credentials) {
      return await axios
        .post("auth/reset-password", {
          token: credentials.token,
          username: credentials.username,
          password1: credentials.password,
          password2: credentials.password_confirmation
        })
        .then(result => {
          console.log(result.data);
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          dispatch(
            "notifications/addNotification",
            { type: "danger", message: i18n.t("error.login-incorrect") },
            { root: true }
          );
        });
    },

    async setLang({ dispatch }) {
      let query = new URL(location.href);

      if (query.searchParams.get("userId")) {
        return await axios
          .get(
            "pap/verify-session?userId=" +
              query.searchParams.get("userId") +
              "&session=" +
              query.searchParams.get("session") +
              "&role=" +
              query.searchParams.get("role") +
              "&force=true"
          )
          .then(response => {
            if (response.data.lang) {
              i18n.locale = response.data.lang.split("-")[0];
            }
          })
          .catch(error => {
            if (error.response.status === 403) {
              //this.$router.push({ name: "loading" });
              router.push("/loading");
              dispatch(
                "notifications/addNotification",
                { type: "danger", message: i18n.t("error.login-incorrect") },
                { root: true }
              );
            }
          });
      }
    }
  }
};
