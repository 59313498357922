var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"4"}},[_c('h4',[_vm._v(_vm._s(_vm.title))])]),_c('CCol',{attrs:{"sm":"8"}},[_c('CNav',{staticClass:"top-menu ml-auto",staticStyle:{"justify-content":"right"}},_vm._l((_vm.menu),function(item){return _c('CNavItem',{key:item.name,class:_vm.$route.path +
                    '?userId=' +
                    _vm.$route.query.userId +
                    '&session=' +
                    _vm.$route.query.session +
                    '&role=' +
                    _vm.$route.query.role ===
                  item.link
                    ? 'active'
                    : '',attrs:{"to":{ path: item.link }}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }