var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.forbidden)?_c('div',[_c('CAlert',{attrs:{"color":"warning","closeButton":""},domProps:{"innerHTML":_vm._s(_vm.$t('messaging.forbidden'))}})],1):_c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('h4',{staticClass:"card-title mb-0",attrs:{"id":"traffic"}},[_vm._v(" "+_vm._s(_vm.$t("menu.messaging"))+" ")]),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("messaging.perex"))+" ")])])],1)],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{staticClass:"mb-1",attrs:{"sm":"6"}},[(_vm.loading)?_c('span',{staticClass:"skeleton-loader"}):_c('h4',{staticClass:"card-title mb-0"},[_vm._v(" "+_vm._s(_vm.item.campaignName)),_c('small',[_vm._v("("+_vm._s(_vm.item.campaignId)+")")]),_vm._v(" / "+_vm._s(_vm.item.affiliateName)+" "),_c('small',[_vm._v("("+_vm._s(_vm.item.affiliateId)+")")])])]),_c('CCol',{staticClass:"mb-1 text-right",attrs:{"sm":"6"}},[_c('AutoRefresh',{attrs:{"refresh":60},on:{"reload":_vm.getItem}}),_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"secondary","to":'/pap/messaging?userId=' +
                      this.$route.query.userId +
                      '&session=' +
                      this.$route.query.session +
                      '&role=' +
                      this.$route.query.role}},[_vm._v(" "+_vm._s(_vm.$t("system.close"))+" ")])],1)],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CButton',{staticClass:"mb-2",attrs:{"color":"info"},on:{"click":function($event){_vm.collapse = !_vm.collapse}}},[(!_vm.collapse)?[_vm._v(_vm._s(_vm.$t("messaging.reply")))]:[_vm._v(_vm._s(_vm.$t("system.close")))]],2),_c('CCollapse',{attrs:{"show":_vm.collapse}},[_c('hr'),_c('WysiwygEditor',{ref:"editor",attrs:{"button-title":_vm.$t('system.send'),"button-clear-title":_vm.$t('system.clear')},on:{"save":_vm.send}}),_c('hr')],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6","id":"chat"}},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"container",class:(item.sender_type === 1 && _vm.xPap.role === 'M') ||
                    (item.sender_type === 2 && _vm.xPap.role === 'A')
                      ? 'sender-type-right'
                      : 'sender-type-left'},[_c('div',{staticClass:"bubble"},[_c('p',{staticClass:"sender"},[_vm._v(" "+_vm._s(item.sender_type === 1 ? item.campaign_name : item.affiliate_name)+" ")]),_c('div',{staticClass:"body",domProps:{"innerHTML":_vm._s(item.body)}}),(item.seen_at === null)?_c('div',[_c('small',[_c('i',{staticClass:"fa fa-eye-slash",attrs:{"aria-hidden":"true"}})])]):_c('div',[_c('small',[_c('i',{staticClass:"fa fa-eye",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.dateFormat(item.seen_at)))])])]),_c('p',{staticClass:"date"},[_c('small',[_vm._v(_vm._s(_vm.$t("system.sent"))+": "+_vm._s(_vm.dateFormat(item.created_at)))])])])}),0)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }