<template>
  <div>
    <div
      v-if="
        $route.params.type === '1' ||
          $route.params.type === '2' ||
          $route.params.type === '3'
      "
    >
      <p v-if="items.length > 0">
        <CButton
          class="btn btn-secondary float-right"
          size="sm"
          @click="
            downloadMedia(
              '/integration-process/steps/' + stepId + '/download',
              'download_' +
                stepName.toLowerCase() +
                '_' +
                campaignName.toLowerCase() +
                '.zip'
            )
          "
        >
          <CSpinner v-if="loadingMedia" color="default" size="sm" />
          {{ $t("system.download-all") }} </CButton
        ><br /><br />
      </p>

      <CSpinner color="info" class="loading" v-if="loading" />
      <CDataTable v-else :items="items" :fields="fields" hover>
        <template #button="{item}">
          <td class="py-2 text-right">
            <CButton
              size="sm"
              color="danger"
              class="ml-1"
              @click="downloadMedia(item.url, item.original_filename)"
              target="_blank"
            >
              {{ $t("system.download") }}
            </CButton>
          </td>
        </template>
      </CDataTable>
    </div>
    <div v-else-if="$route.params.type === '4'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <CInputCheckbox
          :checked="body.checkbox ? true : false"
          disabled=""
          :label="$t('integration-process.step4-text-3')"
        />
        <br />
        <CTextarea name="s" :value="body.textarea" rows="10" />
      </div>
    </div>
    <div v-else-if="$route.params.type === '5'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <p>{{ $t("integration-process.step5-title") }}</p>
        <p>
          <strong v-if="body.radiobutton === 'Y'">{{
            $t("integration-process.step5-y")
          }}</strong
          ><strong v-else>{{ $t("integration-process.step5-n") }}</strong>
        </p>
      </div>
    </div>
    <div v-else-if="$route.params.type === '6'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <p>
          {{ $t("integration-process.step6-title-1") }}.<br />
          <strong v-if="body.permission1 === 'Y'">{{
            $t("integration-process.step6-agree")
          }}</strong
          ><strong v-else>{{
            $t("integration-process.step6-dont-agree")
          }}</strong>
        </p>

        <p>
          {{ $t("integration-process.step6-title-5") }}.<br />
          <strong v-if="body.permission5 === 'Y'">{{
            $t("integration-process.step6-agree")
          }}</strong
          ><strong v-else>{{
            $t("integration-process.step6-dont-agree")
          }}</strong>
        </p>

        <p>
          {{ $t("integration-process.step6-title-2") }}<br />
          <strong v-if="body.permission2 === 'Y'">{{
            $t("integration-process.step6-agree")
          }}</strong
          ><strong v-else>{{
            $t("integration-process.step6-dont-agree")
          }}</strong>
        </p>
        <p>
          {{ $t("integration-process.step6-title-3") }}.<br />
          <strong v-if="body.permission3 === 'Y'">{{
            $t("integration-process.step6-coupon-have")
          }}</strong
          ><strong v-else>{{
            $t("integration-process.step6-coupon-dont-have")
          }}</strong>
        </p>
        <CTextarea
          v-if="body.permission3 === 'Y'"
          name="s"
          :value="body.couponInfo"
          rows="10"
        />
        <p>
          {{ $t("integration-process.step6-title-4") }}<br />
          <strong v-if="body.permission4 === 'Y'">{{
            $t("integration-process.step6-agree")
          }}</strong
          ><strong v-else>{{
            $t("integration-process.step6-dont-agree")
          }}</strong>
        </p>
      </div>
    </div>
    <div v-else-if="$route.params.type === '7'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <CInputRadio
          name="n1"
          disabled="true"
          :checked="body.utm === 'Y' ? true : false"
          :label="$t('integration-process.step7-label-1')"
        />
        <CInputRadio
          name="n3"
          disabled="true"
          :checked="body.utm === 'M' ? true : false"
          :label="$t('integration-process.step7-label-3')"
        /><br />
        <CInput v-if="body.utm === 'M'" name="i" :value="body.utmID" />
        <CInputRadio
          name="n2"
          disabled="true"
          :checked="body.utm === 'N' ? true : false"
          :label="$t('integration-process.step7-label-2')"
        /><br />
        <CTextarea
          v-if="body.utm === 'N'"
          name="s"
          :value="body.utmCode"
          rows="10"
        />
      </div>
    </div>
    <div v-else-if="$route.params.type === '8'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <CInputRadio
          name="n1"
          disabled="true"
          :checked="body.trackingCode === 'N' ? true : false"
          :label="$t('integration-process.step8-label-1')"
        />
        <CInputRadio
          name="n2"
          disabled="true"
          :checked="body.trackingCode === 'Y' ? true : false"
          :label="$t('integration-process.step8-label-2')"
        /><br />

        <CForm @submit.prevent="submit">
          <CRow>
            <CCol lg="6">
              <p><strong>ClickTracking</strong></p>
              <textarea
                ref="clickTrack"
                rows="25"
                class="form-control"
                v-model="body.clickTracking"
              ></textarea>
            </CCol>
            <CCol>
              <p><strong>SaleTracking</strong></p>
              <textarea
                ref="saleTrack"
                rows="25"
                class="form-control"
                v-model="body.saleTracking"
              ></textarea>
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CButton
                type="submit"
                size="sm"
                class="mr-2"
                color="success"
                :disabled="isSubmitting"
                >{{ $t("system.save") }}</CButton
              >
              <CButton
                size="sm"
                color="info"
                class="ml-2"
                :to="{ path: '/integration-process/view/' + $route.params.id }"
                >{{ $t("system.close") }}</CButton
              >
            </CCol>
          </CRow>
        </CForm>
      </div>
    </div>
    <div v-else-if="$route.params.type === '9'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <p>{{ $t("integration-process.step9-title") }}</p>
        <CTextarea name="s" :value="body.blockedIp" rows="10" />
      </div>
    </div>
    <div v-else-if="$route.params.type === '10'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <div v-html="$t('integration-process.step10-text')"></div>
        <hr />
        <span v-if="status !== 1"
          ><strong>{{
            $t("integration-process.submit-accepted")
          }}</strong></span
        >
      </div>
    </div>
    <div v-else-if="$route.params.type === '11'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <p v-html="$t('integration-process.step11-text')"></p>
        <hr />
        <span v-if="status !== 1"
          ><strong>{{
            $t("integration-process.submit-accepted")
          }}</strong></span
        >
      </div>
    </div>
    <div v-else-if="$route.params.type === '12'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <CInputRadio
          name="n1"
          disabled="true"
          :checked="body.accept === 'N' ? true : false"
          :label="$t('integration-process.step12-n')"
        />
        <CInputRadio
          name="n2"
          disabled="true"
          :checked="body.accept === 'Y' ? true : false"
          :label="$t('integration-process.step12-y')"
        /><br />
      </div>
    </div>
    <div v-else-if="$route.params.type === '13'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <CInputRadio
          name="n1"
          disabled="true"
          :checked="body.permission === '1' ? true : false"
          :label="$t('integration-process.step13-n')"
        />
        <CInputRadio
          name="n2"
          disabled="true"
          :checked="body.permission === '2' ? true : false"
          :label="$t('integration-process.step13-v1')"
        />
        <CInputRadio
          name="n3"
          disabled="true"
          :checked="body.permission === '3' ? true : false"
          :label="$t('integration-process.step13-v2')"
        /><br />
      </div>
    </div>
    <div v-else-if="$route.params.type === '14'">
      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <p v-html="$t('integration-process.step14-text')"></p>
        <hr />
        <span v-if="status !== 1"
          ><strong>{{
            $t("integration-process.submit-accepted")
          }}</strong></span
        >
      </div>
    </div>
    <div v-else-if="$route.params.type === '15'">

      <CSpinner color="info" class="loading" v-if="loading" />
      <div v-else>
        <p v-html="$t('integration-process.step15-text')"></p>

        <CForm @submit.prevent="submitCampaignUrlNotes">
          <CRow>
            <CCol lg="6">
              <CInput v-model="body.campaignUrlNotes" />
              <p><small>E.g.: https://www.dognet.sk/kampane/moja-kampan</small></p>
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CButton
                  type="submit"
                  size="sm"
                  class="mr-2"
                  color="success"
                  :disabled="isSubmitting"
              >{{ $t("system.save") }}</CButton
              >
              <CButton
                  size="sm"
                  color="info"
                  class="ml-2"
                  :to="{ path: '/integration-process/view/' + $route.params.id }"
              >{{ $t("system.close") }}</CButton
              >
            </CCol>
          </CRow>
        </CForm>

        <span v-if="status !== 1"
        ><strong>{{
            $t("integration-process.submit-accepted")
          }}</strong></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import crud from "@/store/modules/crud";
import moment from "moment";
import axios from "axios";
import store from "@/store";
import { mapActions } from "vuex";

const fields = [
  {
    key: "original_filename",
    label: i18n.t("integration-process.original-filename")
  },
  {
    key: "mime",
    label: i18n.t("integration-process.type")
  },
  {
    key: "size",
    label: i18n.t("system.size")
  },
  {
    key: "updated_at",
    label: i18n.t("system.updated_at")
  },
  {
    key: "button",
    label: ""
  }
];

export default {
  name: "IntegrationProcessEditSteps",
  props: ["stepId", "status", "campaignName", "stepName"],
  data() {
    return {
      isSubmitting: false,
      loadingMedia: false,
      loading: true,
      body: [],
      items: [],
      fields
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getFiles() {
      let self = this;
      crud
        .get("integration-process/steps/" + this.stepId + "?expand=files")
        .then(function(response) {
          self.items = [];

          if (response.data.body) {
            self.body = response.data.body;
          } else {
            self.body = {
              checkbox: "",
              textarea: ""
            };
            self.loading = false;
          }

          for (const key of Object.values(response.data.files)) {
            self.items.push({
              original_filename: key.original_filename,
              mime: key.mime,
              size: self.$options.filters.prettyBytes(key.size),
              updated_at: moment(key.updated_at).format("DD.MM.YYYY HH:mm"),
              url: key.url
            });
          }

          self.loading = false;
        });
    },
    async downloadMedia(url, filename) {
      let self = this;
      this.loadingMedia = true;
      await axios
        .get(url, {
          headers: {
            "X-Api-Key": store.state.auth.token
          },
          responseType: "blob"
        })
        .then(function(response) {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
          self.loadingMedia = false;
        });
    },
    htmlDecode(value) {
      return value
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"');
    },
    submit() {
      this.isSubmitting = true;

      let data = {
        status: this.status,
        body: {
          trackingCode: this.body.trackingCode,
          clickTracking: this.body.clickTracking,
          saleTracking: this.body.saleTracking
        }
      };

      crud
        .put("integration-process/steps/" + this.stepId, data)
        .then(() => {
          this.isSubmitting = false;
          this.addNotification(
            {
              type: "success",
              message: i18n.t("success.record-updated")
            },
            { root: true }
          );
        })
        .catch(error => {
          this.isSubmitting = false;
          this.addNotification({
            type: "danger",
            message: error
          });
        });

      this.isSubmitting = false;
    },
    submitCampaignUrlNotes() {
      this.isSubmitting = true;

      let data = {
        status: this.status,
        body: {
          campaignUrlNotes: this.body.campaignUrlNotes,
        }
      };

      crud
          .put("integration-process/steps/" + this.stepId, data)
          .then(() => {
            this.isSubmitting = false;
            this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-updated")
                },
                { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error
            });
          });

      this.isSubmitting = false;
    },
  },
  mounted() {},
  watch: {
    stepId() {
      this.$emit("update:stepId", this.stepId); //after load ID from parent
      this.getFiles();
    }
  }
};
</script>

<style></style>
