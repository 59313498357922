export default {
  namespaced: true,

  state: {
    notifications: [],
    active: undefined,
    timeoutId: undefined
  },
  mutations: {
    PUSH_NOTIFICATION(state, notification) {
      state.notifications.push({
        ...notification
      });
    },
    REMOVE_NOTIFICATION(state) {
      state.notifications = [];
    }
  },
  actions: {
    addNotification({ commit, dispatch }, notification) {
      commit("PUSH_NOTIFICATION", notification);

      // Remove notification
      setTimeout(() => {
        dispatch("resetNotification", { root: true });
      }, 3000);
    },
    resetNotification({ commit }) {
      commit("REMOVE_NOTIFICATION");
    }
  }
};
