import Vue from "vue";
import store from "@/store";

export default {};

/*
 * call
 * this.$options.filters.capitalize(value)
 * {{ string | capitalize }}
 */
Vue.filter("capitalize", function(value) {
  if (!value) {
    return "";
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("arrayValue", function(value, array) {
  return array[value];
});

/**
 * $options.filters.enumerator(<enumerator_name>, <value to be formatted>, <return type>);
 * example:
 * $options.filters.enumerator('user-status', user.status, 'plain');
 */
Vue.filter("enumerator", function(enumerator, value, returnAs = "plain") {
  if (!store.state.enumerators[enumerator]) {
    return null;
  }

  let result = null;
  let _returnAs = !returnAs ? "plain" : returnAs;

  switch (_returnAs) {
    case "color":
      // @TODO: create some universal coloring map
      break;

    case "plain":
      result = store.state.enumerators[enumerator][value];
      break;

    case "html":
      result =
        "<span>" + store.state.enumerators[enumerator][value] + "</span>";
      break;
  }

  return result;
});

/**
 * Will convert given object into an object suitable for CoreUI's CSelect component.
 * e.g.
 * {a: 1, b: 2}
 * will become:
 * [{value:a, label:1}, {value:b, label:2}]
 */
Vue.filter("objectToSelectBox", function(object) {
  if (object === undefined) {
    return [];
  }

  let response = [];

  for (const [key, value] of Object.entries(object)) {
    response.push({
      value: key,
      label: value
    });
  }

  return response;
});

// usage: {{ file.size | prettyBytes }}
Vue.filter("prettyBytes", function(num) {
  if (typeof num !== "number" || isNaN(num)) {
    throw new TypeError("Expected a number");
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? "-" : "") + num + " B";
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  );
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? "-" : "") + num + " " + unit;
});

//feed parser, return [type(heureka,...), extension(xml,), lang(sk-SK)]
Vue.filter("feedParser", function(feed, type, format, lang) {
  let extension = null;
  let returnType = null;
  let langArr = {};
  let returnLang = null;
  let extension_feed = feed
    .split(/[#?]/)[0]
    .split(".")
    .pop()
    .trim();
  let feedDomainTrim = new URL(feed);

  //set format xml, csv,json
  for (const index in format) {
    if (format[index].toLowerCase() === extension_feed) {
      //extension = format[index];
      extension = index;
      break;
    }
  }

  //set format google, heureka, etc
  for (const index in type) {
    let explode = String(type[index]).split(" ");
    explode = explode[0].split(".");
    explode = explode[0].toLowerCase();

    let regex = new RegExp(explode);
    if (regex.test(feed)) {
      returnType = index;
      break;
    }
  }

  //create lang array from enumerator (cs = cz, etc)
  for (const index in lang) {
    let explode = String(index).split("-");
    langArr[explode[0]] = index;
    langArr[explode[1].toLowerCase()] = index;
  }

  //set lang
  for (const index in langArr) {
    let explode = String(index).split("-");
    explode = explode[0].toLowerCase();

    let regexLang = new RegExp(explode);
    if (regexLang.test(feedDomainTrim.pathname)) {
      returnLang = langArr[index];
    }
  }

  return [returnType, extension, returnLang];
});

//validate URL, return true/false
Vue.filter("validURL", function(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
});

Vue.filter("parseUrlQueryFilter", function(object) {
  if (object === undefined) {
    return [];
  }

  let filter = false;
  for (let q in object) {
    if (q.includes("filter")) {
      filter = true;
    }
  }

  return filter;
});

Vue.filter("truncate", function(text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

/* remove accent */
Vue.filter("slug", function(string) {
  if (!string) {
    return "";
  }

  let removeAccent = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return removeAccent
    .toString()
    .toLowerCase()
    .trim()
    .replace(/&/g, "-and-")
    .replace(/[\s\W]/g, "-")
    .replace(/--+/g, "-")
    .replace(/^-+|-+$/g, "-");
});

/* remove accent */
Vue.filter("validateEmail", function(string) {
  if (!string || string === null || string === "") {
    return false;
  }

  let re = /\S+@\S+\.\S+/;

  if (string && !re.test(string)) {
    return false;
  } else if (string && re.test(string)) {
    return true;
  }
});
