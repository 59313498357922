<template>
  <div>
    <hr />
    <CRow>
      <CCol sm="6">
        <strong>{{ $t("system.total-count") }}</strong
        >: {{ this.totalCount }}
      </CCol>
      <CCol sm="6">
        <CPagination
          v-if="pages > 0"
          :activePage.sync="page"
          :pages="pages"
          :limit="perPage"
          size="sm"
          align="end"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
/**
 *  In parent file
 *
 *  <Pagination :totalCount="this.page.totalCount" :perPage="this.page.perPage" />
 *
 *   import Pagination from "@/components/ui/Pagination";
 *
 *   components: {Pagination},
 *   data() {
 *   return {
 *     page: {
 *         totalCount: 0,
 *         perPage: 0
 *     }
 *   };
 *
 *   methods: {
 *     pagination() {
 *       some functions ... call after click
 *    },
 *   }
 *
 *   !!!!
 *   set  totalCount and perPage
 *   self.page.totalCount = response.data._meta.totalCount;
 *    self.page.perPage = response.data._meta.perPage;
 *
 */

export default {
  name: "Pagination",
  props: {
    totalCount: Number,
    perPage: Number
  },
  data() {
    return {
      page:
        this.$route.query.page && this.$route.query.page !== "NaN"
          ? Number(this.$route.query.page)
          : 1
    };
  },
  mounted() {
    this.setCurrentPage();
  },
  watch: {
    page: function() {
      window.scrollTo(0, 0);

      // eslint-disable-next-line no-unused-vars
      let filter = false;
      for (let key in this.$route.query) {
        if (key.includes("filter") || key === "sort") {
          filter = true;
        }
      }

      if (filter) {
        let query = Object.assign(
          this.$route.query,
          JSON.parse('{ "page": ' + this.page + "}")
        );
        this.$router.push({ path: this.$route.path });
        this.$router.push({ query: query });
      } else {
        if (!this.page) {
          this.$router.push({ path: this.$route.path });
        } else if (Number(this.$route.query.page) !== Number(this.page)) {
          this.$router.push({
            path: this.$route.path,
            query: { page: this.page }
          });
        }
      }

      this.$parent.pagination();
    },
    "$route.query.page"() {
      //set pagination to [1]

      this.page = Number(this.$route.query.page ? this.$route.query.page : 1);
    }
  },
  methods: {
    setCurrentPage() {
      this.page = Number(this.page ? this.page : 1);
    }
  },
  computed: {
    pages: function() {
      return Math.ceil(this.totalCount / this.perPage);
    }
  }
};
</script>

<style></style>
