var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('CSpinner',{staticClass:"loading",attrs:{"color":"info"}}),_c('br'),_c('div',{staticClass:"text-center"},[_c('CButton',{attrs:{"color":"secondary","size":"sm"},on:{"click":_vm.reloadPage}},[_vm._v(" Refresh ")])],1)],1):(_vm.forbidden)?_c('div',[_c('CAlert',{attrs:{"color":"warning","closeButton":""},domProps:{"innerHTML":_vm._s(_vm.$t('messaging.forbidden'))}})],1):_c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('h4',{staticClass:"card-title mb-0",attrs:{"id":"traffic"}},[_vm._v(" "+_vm._s(_vm.$t("menu.messaging"))+" ")]),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("messaging.perex"))+" ")])])],1)],1)],1)],1)],1),(this.$route.query.role === 'A')?_c('CAlert',{attrs:{"color":"warning","closeButton":""},domProps:{"innerHTML":_vm._s(_vm.$t('messaging.publisher_warning'))}}):_vm._e(),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{staticClass:"mb-1",attrs:{"sm":"6"}},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("messaging.new-message"))+" ")])]),_c('CCol',{staticClass:"mb-1 text-right",attrs:{"sm":"6"}},[_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"secondary","to":'/pap/messaging?userId=' +
                      this.$route.query.userId +
                      '&session=' +
                      this.$route.query.session +
                      '&role=' +
                      this.$route.query.role}},[_vm._v(" "+_vm._s(_vm.$t("system.cancel"))+" ")])],1)],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CSelect',{attrs:{"label":_vm.$t('menu.campaigns'),"placeholder":_vm.selectOptionsCampaign.length === 0
                      ? _vm.$t('messaging.no_campaign')
                      : '',"disabled":_vm.selectOptionsCampaign.length === 0,"id":"campaigns","name":"campaigns","options":_vm.selectOptionsCampaign,"value":_vm.form.selectedCampaign},on:{"update:value":function($event){return _vm.$set(_vm.form, "selectedCampaign", $event)},"change":_vm.selectAffiliateOptions}})],1),(_vm.xPap.role === 'M')?_c('CCol',{attrs:{"lg":"6"}},[(_vm.loadingAffil)?_c('span',[_c('label',[_vm._v(_vm._s(_vm.$t("system.affiliate")))]),_c('br'),_c('CSpinner',{attrs:{"color":"info","size":"sm"}})],1):_c('CSelect',{attrs:{"label":_vm.$t('system.affiliate'),"id":"affiliate","name":"affiliate","options":_vm.selectOptionsAffiliate,"value":_vm.form.selectedAffiliate,"disabled":_vm.selectOptionsAffiliate.length > 0 === false},on:{"update:value":function($event){return _vm.$set(_vm.form, "selectedAffiliate", $event)}}})],1):_vm._e(),_c('CCol',{attrs:{"lg":"12"}},[_c('WysiwygEditor',{attrs:{"button-title":_vm.$t('system.send'),"button-clear-title":_vm.$t('system.clear'),"is-submitting":_vm.isSubmitting},on:{"save":_vm.send}})],1)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }