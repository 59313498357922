<template>
  <div>
    <router-view />
    <Notifications />
  </div>
</template>

<script>
import Notifications from "@/components/notifications/NotificationsList";

export default {
  name: "App",
  components: {
    Notifications
  }
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
