import i18n from "@/i18n";

//Views XMLManager
import XmlManager from "../views/xml_manager/XmlManagerIndex.vue";
import XmlManagerCreate from "../views/xml_manager/XmlManagerCreate.vue";
import XmlManagerEdit from "../views/xml_manager/XmlManagerEdit.vue";
import XmlManagerView from "../views/xml_manager/XmlManagerView.vue";
import XmlManagerPapIndex from "../views/xml_manager/xml-pap/XmlManagerPapIndex.vue";
import XmlManagerTestFeedIndex from "../views/xml_manager/xml-test/XmlManagerTestFeedIndex.vue";

export default [
  {
    data: {
      pap: [
        {
          path: "/pap/xml-manager",
          name: i18n.t("menu.xml-manager"),
          component: XmlManagerPapIndex,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/xml-manager/test-feed",
          name: i18n.t("menu.xml-manager") + "Test",
          component: XmlManagerTestFeedIndex,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        }
      ],
      admin: [
        {
          path: "/xml-manager",
          meta: {
            label: i18n.t("menu.xml-manager")
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/xml-manager",
              name: i18n.t("menu.xml-manager") + ": " + i18n.t("crud.list"),
              component: XmlManager,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/xml-manager/create",
              name: i18n.t("menu.xml-manager") + ": " + i18n.t("crud.create"),
              component: XmlManagerCreate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/xml-manager/edit/:id",
              name: i18n.t("menu.xml-manager") + ": " + i18n.t("crud.edit"),
              component: XmlManagerEdit,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/xml-manager/view/:id",
              name: i18n.t("menu.xml-manager") + ": " + i18n.t("crud.view"),
              component: XmlManagerView,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
