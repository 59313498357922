<template>
  <span></span>
</template>
<script>
/**
 *
 *  <Sort ref="sort"/>
 *  <CDataTable :sorter='{ external: true, resetable: false }' @update:sorter-value="$refs.sort.sortTable">
 *
 *  import Sort from "@/components/ui/Sort";
 *
 *  components: {
 *    Sort
 *  }
 *
 *   methods: {
 *     sort() {
 *       some functions ... call after click
 *    },
 *   }
 *
 */

export default {
  name: "Sort",
  methods: {
    sortTable(event) {
      let sort = !event.asc ? event.column : "-" + event.column;
      sort = { sort };

      let query = this.$route.query;
      delete query.sort;

      let employee = event.column !== null ? Object.assign(query, sort) : query;

      this.$router.push({ path: this.$route.path });
      this.$router.push({ query: employee });

      this.$parent.sort();
    }
  }
};
</script>
