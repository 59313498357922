<template>
  <div>
    <ProductServiceTopMenu :title="$t('product-service.create-feed')" />
    <Form></Form>
  </div>
</template>

<script>
import ProductServiceTopMenu from "@/components/product_service/TopMenu";
import Form from "./Form";

export default {
  name: "ProductServicePapCreate",
  components: {
    Form,
    ProductServiceTopMenu
  }
};
</script>
