<template>
  <div>
    <CForm @submit.prevent="submit">
      <CRow>
        <CCol lg="6">
          <CRow>
            <CCol lg="6">
              <CInput
                :label="$t('login.username')"
                id="username"
                name="username"
                required="true"
                v-model="form.username"
              />
            </CCol>

            <CCol lg="6">
              <CInput
                type="email"
                label="E-mail"
                id="email"
                name="email"
                autoComplete="email"
                required="true"
                v-model="form.email"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol lg="6">
              <CSelect
                :label="$t('system.status')"
                id="status"
                name="status"
                :disabled="myProfile"
                :options="this.enums['user-status']"
                :value.sync="form.status"
              />
            </CCol>
            <CCol lg="6">
              <CSelect
                :label="$t('system.language')"
                id="language"
                name="language"
                :options="this.enums.language"
                :value.sync="form.language"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol lg="12">
              <hr />
            </CCol>
            <CCol lg="12 pt-2" v-if="isEdit">
              <input
                type="checkbox"
                :value="1"
                v-model="changePass"
                id="changePass"
              />
              <label class="checkbox-label ml-2" for="changePass">
                {{ $t("login.change-password") }}
              </label>
              <hr />
            </CCol>

            <CCol lg="4">
              <CInput
                :type="passwordVisible ? 'text' : 'password'"
                :label="$t('login.password')"
                id="password"
                name="password"
                minlength="6"
                required="true"
                @blur="validate"
                :disabled="!changePass && isEdit ? true : false"
                v-model="form.password"
              />
            </CCol>

            <CCol lg="4">
              <CInput
                :type="passwordVisible ? 'text' : 'password'"
                :label="$t('system.confirm') + ' ' + $t('login.password')"
                id="confirmPassword"
                name="confirmPassword"
                minlength="6"
                required="true"
                @blur="validate"
                :disabled="!changePass && isEdit ? true : false"
                v-model="confirmPassword"
              />
            </CCol>
            <CCol lg="4" class="psw">
              <div
                v-if="(!changePass && !isEdit) || (changePass && isEdit)"
                class="visibility btn btn-secondary"
                tabindex="-1"
                @click="togglePasswordVisibility"
                :arial-label="
                  passwordVisible ? 'Hide password' : 'Show password'
                "
              >
                {{ passwordVisible ? $t("system.hide") : $t("system.show") }}
                {{ $t("login.password") }}
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol lg="12" v-if="showError">
              <CAlert color="danger">
                <CIcon class="text-danger" name="cilBan" />
                <small>{{ $t("error.confirm-password") }}</small>
              </CAlert>
            </CCol>
          </CRow>
        </CCol>

        <CCol lg="6">
          <label>{{ $t("roles.role") }}</label
          ><br />

          <span v-for="item in selectRoles" v-bind:key="item.label">
            <input
              type="checkbox"
              :value="item.value"
              v-model="form.roles"
              :id="item.value"
              :disabled="myProfile"
            />
            <label class="checkbox-label pl-2" :for="item.value">
              {{ item.label }}
            </label>
            <br />
          </span>
        </CCol>
      </CRow>

      <hr />

      <CButton
        v-if="isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
        >{{ $t("system.update") }}</CButton
      >
      <CButton
        v-if="!isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
        >{{ $t("crud.create") }}</CButton
      >
      <CButton size="sm" color="secondary" class="ml-2" to="/users">{{
        $t("system.cancel")
      }}</CButton>
      <CSpinner
        v-if="this.isSubmitting === true"
        class="ml-2"
        color="info"
        size="sm"
      />
    </CForm>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import axios from "axios";
import store from "@/store";
import i18n from "@/i18n";

export default {
  name: "UserCreate",
  data() {
    return {
      form: {
        username: "",
        password: "",
        email: "",
        status: "10",
        language: "sk-SK",
        roles: []
      },
      changePass: false,
      item: [],
      isSubmitting: false,
      confirmPassword: "",
      showError: false,
      passwordVisible: false,
      selectRoles: [],
      myProfile: false,
      enums: []
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getUser() {
      let self = this;
      crud.get("users/" + this.$route.params.id).then(function(response) {
        self.form.username = response.data.username;
        self.form.email = response.data.email;
        self.form.status = String(response.data.status);
        self.form.language = response.data.language;
        self.form.roles = Object.keys(response.data.roles).map(key => {
          return key;
        });
      });
    },

    meUser() {
      if (
        this.isEdit &&
        Number(this.$store.state.auth.user.id) === Number(this.$route.params.id)
      ) {
        this.myProfile = true;
      }
    },

    validate() {
      if (this.changePass) {
        this.showError = this.form.password !== this.confirmPassword;
      } else {
        return false;
      }
    },

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },

    async getRole() {
      let result = await axios.get("auth-roles?per-page=100000", {
        headers: {
          "X-Api-Key": store.state.auth.token
        }
      });

      //only type 1
      const roles = result.data.items.filter(item => {
        return item.type === 1;
      });

      for (const property in roles) {
        this.selectRoles.push({
          label: roles[property]["name"],
          value: roles[property]["name"]
        });
      }
    },

    addPasswordEdit() {
      this.form.password = this.changePass ? this.form.password : "";
    },

    submit() {
      this.isSubmitting = true;

      if (!this.validate() && this.form.roles.length > 0) {
        if (this.isEdit) {
          this.addPasswordEdit();

          crud
            .put("users/" + this.$route.params.id, this.form)
            .then(() => {
              this.isSubmitting = false;
              this.$router.push("/users");
              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-updated")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;

              this.addNotification({
                type: "danger",
                message: error.response.data.message
              });
            });
        } else {
          crud
            .post("users", this.form)
            .then(() => {
              this.isSubmitting = false;
              this.$router.push("/users");
              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-add")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;

              this.addNotification({
                type: "danger",
                message: error.response.data[0].message
              });
            });
        }
      } else {
        let messageVal = "";
        let messageRol = "";

        if (this.validate()) {
          messageVal = i18n.t("error.confirm-password") + ".";
        }

        if (this.form.roles.length === 0) {
          messageRol = i18n.t("error.roles-empty") + ".";
        }

        this.isSubmitting = false;
        this.addNotification({
          type: "danger",
          message: messageVal + messageRol
        });
      }
    },

    /**
     * @param {String} property
     * @param {Object} payload
     */
    updateEnumeratorProperty(property, payload) {
      this.enums[property] = this.$options.filters.objectToSelectBox(
        payload[property]
      );
    }
  },
  computed: {
    isEdit() {
      return !!Number.parseInt(this.$route.params.id);
    }
  },
  created() {
    this.meUser();
    this.isEdit ? this.getUser() : "";
    this.getRole();

    // Fill if enums were already loaded
    this.updateEnumeratorProperty(
      "user-status",
      this.$store.state.enumerators.enumerators
    );
    this.updateEnumeratorProperty(
      "language",
      this.$store.state.enumerators.enumerators
    );

    // If no enums yet, subscribe and wait
    this.destroySubscription = this.$store.subscribe(mutation => {
      if (mutation.type === "enumerators/FETCH_ENUMERATORS") {
        this.updateEnumeratorProperty("user-status", mutation.payload);
        this.updateEnumeratorProperty("language", mutation.payload);

        // Destroy self
        this.destroySubscription();
      }
    });
  },
  beforeDestroy() {
    this.destroySubscription();
  }
};
</script>

<style>
.psw {
  padding-top: 28px;
}
</style>
