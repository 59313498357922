var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('CSpinner',{staticClass:"loading",attrs:{"color":"info"}}),_c('br'),_c('div',{staticClass:"text-center"},[_c('CButton',{attrs:{"color":"secondary","size":"sm"},on:{"click":_vm.reloadPage}},[_vm._v(" Refresh ")])],1)],1):(_vm.forbidden)?_c('div',[_c('CAlert',{attrs:{"color":"warning","closeButton":""},domProps:{"innerHTML":_vm._s(_vm.$t('messaging.forbidden'))}})],1):_c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('h4',{staticClass:"card-title mb-0",attrs:{"id":"traffic"}},[_vm._v(" "+_vm._s(_vm.$t("menu.messaging"))+" ")]),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("messaging.perex"))+" ")])]),_c('CCol',{staticClass:"text-right pt-2",attrs:{"sm":"6"}},[_c('CButtonGroup',[_c('CButton',{attrs:{"color":"info","variant":"outline","to":'/pap/messaging?userId=' +
                        this.$route.query.userId +
                        '&session=' +
                        this.$route.query.session +
                        '&role=' +
                        this.$route.query.role}},[_vm._v(" "+_vm._s(_vm.$t("messaging.inbox"))+" ")]),_c('CButton',{attrs:{"color":"info","to":'/pap/messaging/publishers?userId=' +
                        this.$route.query.userId +
                        '&session=' +
                        this.$route.query.session +
                        '&role=' +
                        this.$route.query.role}},[_vm._v(" "+_vm._s(_vm.$t("system.affiliates"))+" ")])],1)],1)],1)],1),_c('CCardBody',[_c('Search',{ref:"search",staticClass:"p-0",attrs:{"columns":this.search}})],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[(!_vm.selectedCampaign)?_c('CAlert',{attrs:{"color":"warning","closeButton":""},domProps:{"innerHTML":_vm._s(_vm.$t('messaging.publisher_warning_search'))}}):_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"mb-1",attrs:{"sm":"12"}},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("system.affiliates"))+" ")])])],1),_vm._v(" "+_vm._s(this.$route.query.campaignId)+" "),_c('CDataTable',{attrs:{"loading":_vm.reloading,"items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center my-5"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("message.search-empty"))+" ")])])]},proxy:true},{key:"fullname",fn:function({item}){return [_c('td',[_c('router-link',{attrs:{"to":{
                      path:
                        '/pap/messaging/publishers/' +
                        _vm.campaignsId +
                        '/' +
                        item.userid +
                        '?userId=' +
                        _vm.xPap.userId +
                        '&session=' +
                        _vm.xPap.session +
                        '&role=' +
                        _vm.xPap.role
                    }}},[_vm._v(_vm._s(item.fullname)+" ("+_vm._s(item.userid)+")")])],1)]}},{key:"button",fn:function({item}){return [_c('td',{staticClass:"py-2 text-right"},[_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"info","variant":"outline","to":{
                      path:
                        '/pap/messaging/publishers/' +
                        _vm.campaignsId +
                        '/' +
                        item.userid +
                        '?userId=' +
                        _vm.xPap.userId +
                        '&session=' +
                        _vm.xPap.session +
                        '&role=' +
                        _vm.xPap.role
                    }}},[_c('i',{staticClass:"fa fa-eur",attrs:{"aria-hidden":"true"}})]),_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"info","to":{
                      path:
                        '/pap/messaging/create' +
                        '?campaignId=' +
                        _vm.campaignsId +
                        '&affiliateId=' +
                        item.id +
                        '&userId=' +
                        _vm.xPap.userId +
                        '&session=' +
                        _vm.xPap.session +
                        '&role=' +
                        _vm.xPap.role
                    }}},[_c('i',{staticClass:"fa fa-envelope-o",attrs:{"aria-hidden":"true"}})])],1)]}}])}),_c('Pagination',{attrs:{"totalCount":this.page.totalCount,"perPage":this.page.perPage}})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }