var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('CSpinner',{staticClass:"loading",attrs:{"color":"info"}}),_c('br'),_c('div',{staticClass:"text-center"},[_c('CButton',{attrs:{"color":"secondary","size":"sm"},on:{"click":_vm.reloadPage}},[_vm._v(" Refresh ")])],1)],1):(_vm.forbidden)?_c('div',[_c('CAlert',{attrs:{"color":"warning","closeButton":""},domProps:{"innerHTML":_vm._s(_vm.$t('messaging.forbidden'))}})],1):_c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('h4',{staticClass:"card-title mb-0",attrs:{"id":"traffic"}},[_vm._v(" "+_vm._s(_vm.$t("menu.messaging"))+" ")]),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("messaging.perex"))+" ")])]),(this.xPap.role === 'M')?_c('CCol',{staticClass:"text-right pt-2",attrs:{"sm":"6"}},[_c('CButtonGroup',[_c('CButton',{attrs:{"color":"info","to":'/pap/messaging?userId=' +
                        this.$route.query.userId +
                        '&session=' +
                        this.$route.query.session +
                        '&role=' +
                        this.$route.query.role}},[_vm._v(" "+_vm._s(_vm.$t("messaging.inbox"))+" ")]),_c('CButton',{attrs:{"color":"info","variant":"outline","to":'/pap/messaging/publishers?userId=' +
                        this.$route.query.userId +
                        '&session=' +
                        this.$route.query.session +
                        '&role=' +
                        this.$route.query.role}},[_vm._v(" "+_vm._s(_vm.$t("system.affiliates"))+" ")])],1)],1):_vm._e()],1)],1),_c('CCardBody',[_c('Search',{ref:"search",staticClass:"p-0",attrs:{"columns":this.search}})],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"mb-1",attrs:{"sm":"6"}},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("messaging.inbox"))+" ")])]),_c('CCol',{staticClass:"mb-1 text-right",attrs:{"sm":"6"}},[_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"info","to":'messaging/create?userId=' +
                      this.$route.query.userId +
                      '&session=' +
                      this.$route.query.session +
                      '&role=' +
                      this.$route.query.role}},[_vm._v(" + "+_vm._s(_vm.$t("messaging.add-new-message"))+" ")]),_c('AutoRefresh',{attrs:{"refresh":60},on:{"reload":_vm.getItems}})],1)],1),(Object.keys(_vm.items).length === 0)?_c('CRow',[_c('CCol',{staticClass:"mb-1",attrs:{"sm":"12"}},[_c('hr'),_c('h3',[_vm._v(_vm._s(_vm.$t("system.search"))+":")]),_vm._v(" "+_vm._s(_vm.$t("message.search-empty"))+" ")])],1):_c('CCol',{staticClass:"p-0"},[_c('CDataTable',{attrs:{"loading":_vm.reloading,"items":_vm.items,"fields":_vm.fields,"addTableClasses":"clicktable","hover":""},on:{"row-clicked":_vm.rowClocked},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center my-5"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("message.search-empty"))+" ")])])]},proxy:true},{key:"affiliate_name",fn:function({item}){return [_c('td',[(
                        item.seen_at === null &&
                          ((item.sender_type === 1 && _vm.xPap.role === 'A') ||
                            (item.sender_type === 2 && _vm.xPap.role === 'M'))
                      )?_c('strong',[_c('i',{staticClass:"fa fa-envelope",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.affiliate_name)+" ")]):_c('span',[_c('i',{staticClass:"fa fa-envelope-open-o",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.affiliate_name))])])]}},{key:"campaign_name",fn:function({item}){return [_c('td',[(
                        item.seen_at === null &&
                          ((item.sender_type === 1 && _vm.xPap.role === 'A') ||
                            (item.sender_type === 2 && _vm.xPap.role === 'M'))
                      )?_c('strong',[_vm._v(" "+_vm._s(item.campaign_name)+" ")]):_c('span',[_vm._v(_vm._s(item.campaign_name))])])]}},{key:"body_perex",fn:function({item}){return [_c('td',[_vm._v(_vm._s(item.body_perex)+" ...")])]}},{key:"seen_at",fn:function({item}){return [_c('td',[(item.seen_at === null)?_c('span',[_c('CBadge',{attrs:{"color":"danger"}},[_vm._v("X")])],1):_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.seen_at)))])])]}},{key:"created_at",fn:function({item}){return [_c('td',[(item.created_at === null)?_c('span',[_vm._v("---")]):_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.created_at)))])])]}}])}),_c('Pagination',{attrs:{"totalCount":this.page.totalCount,"perPage":this.page.perPage}})],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }