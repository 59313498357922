<template>
  <span>
    <CButton size="sm" color="warning" class="ml-1" @click="resetCountDown">
      <i class="fa fa-refresh" aria-hidden="true"></i> {{ countDown }}
    </CButton>
  </span>
</template>

<script>
export default {
  name: "AutoRefresh",
  props: {
    refresh: Number
  },
  data() {
    return {
      countDown: this.refresh
    };
  },
  created() {
    this.countDownTimer();
  },
  methods: {
    resetCountDown() {
      this.countDown = this.refresh;
      this.$emit("reload");
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else if (this.countDown === 0) {
        this.resetCountDown();
        this.countDownTimer();
      }
    }
  }
};
</script>
