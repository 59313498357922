<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("users.user") }}
            </h4>
            <div class="small text-muted">{{ $t("crud.view") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButton
              :to="'/users/edit/' + this.$route.params.id"
              size="sm"
              color="info"
              class="mr-2"
              >{{ $t("system.update") }}</CButton
            >

            <CButton color="secondary" square size="sm" to="/users">
              {{ $t("system.cancel") }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <br />
            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable v-else :items="viewItems" :fields="fields" hover>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";

const fields = [
  {
    key: "data",
    label: i18n.t("system.label")
  },
  {
    key: "value",
    label: i18n.t("system.value")
  }
];

export default {
  name: "Users",
  data() {
    return {
      loading: true,
      items: [],
      fields,
      status: [],
      selectLanguage: []
    };
  },
  methods: {
    getUserInfo() {
      var self = this;
      crud.get("users/" + this.$route.params.id).then(function(response) {
        self.items = response.data;
        self.loading = false;
      });
    }
  },
  computed: {
    viewItems: function() {
      let obj = [];
      let roles = [];

      Object.keys(this.items).forEach(key => {
        if (typeof this.items[key] === "object") {
          for (let role in this.items[key]) {
            roles.push(role);
          }

          obj.push({ data: key, value: roles });
        } else {
          let item = this.items[key];

          switch (key) {
            case "status":
              item = this.$store.state.enumerators.enumerators["user-status"][
                this.items[key]
              ];
              break;
            case "language":
              item = this.$store.state.enumerators.enumerators["language"][
                this.items[key]
              ];
              break;
            case "created_at":
              key = i18n.t("system.created_at");
              item = new Date(item);
              break;
            case "updated_at":
              key = i18n.t("system.updated_at");
              item = new Date(item);
              break;
          }

          obj.push({ data: key, value: item });
        }
      });

      return obj;
    }
  },
  created() {
    this.getUserInfo();
  }
};
</script>

<style></style>
