<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="
      value => $store.commit('minimalize/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="this.nav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="
        $store.commit('minimalize/set', ['sidebarMinimize', !minimize])
      "
    />
  </CSidebar>
</template>

<script>
import axios from "axios";
import i18n from "@/i18n";

export default {
  name: "TheSidebar",
  data() {
    return {
      nav: []
    };
  },
  computed: {
    show() {
      return this.$store.state.minimalize.sidebarShow;
    },
    minimize() {
      return this.$store.state.minimalize.sidebarMinimize;
    }
  },
  mounted: function() {
    this.getMenu();
  },
  methods: {
    getMenu() {
      return axios
        .get("navigations/main", {
          headers: {
            "X-Api-Key": this.$store.state.auth.token
          }
        })
        .then(result => {
          var obj = [];

          result.data.forEach(item => {
            if (item.items.length) {
              var items = [];

              item.items.forEach(sub => {
                //generate submenu
                items.push({
                  name: i18n.t("menu." + sub.label),
                  to: "/" + sub.jsRoute
                });
              });

              //menu with items
              let route = item.jsRoute.split("/");
              obj.push({
                _name: "CSidebarNavDropdown",
                name: i18n.t("menu." + item.label),
                to: "/" + item.jsRoute,
                icon: item.icon,
                items: items,
                route: route[0]
              });
            } else {
              //menu without items
              let route = item.jsRoute.split("/");
              obj.push({
                _name: "CSidebarNavItem",
                name: i18n.t("menu." + item.label),
                to: "/" + item.jsRoute,
                icon: item.icon,
                route: route[0]
              });
            }
          });

          this.nav = [{ _name: "CSidebarNav", _children: obj }];
        });
    }
  }
};
</script>
