<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader @click="collapse = !collapse" class="collapseButton">
          <CRow>
            <CCol lg="6">
              <h4 id="traffic" class="card-title mb-0">
                {{ $t("integration-process." + step[stepItem.type]) }}
              </h4>
              <c-badge v-if="this.isEdit === 1" color="danger">{{
                $t("integration-process.pap-status-open")
              }}</c-badge>
              <c-badge v-if="this.isEdit === 2" color="warning">{{
                $t("integration-process.pap-status-waiting")
              }}</c-badge>
              <c-badge v-if="this.isEdit === 3" color="success">{{
                $t("integration-process.pap-status-close")
              }}</c-badge>
            </CCol>
            <CCol lg="6" class="text-right pt-2">
              <CButton color="info" size="sm" class="m-2" v-if="isEdit === 1">
                {{ $t("crud.edit") }}
              </CButton>
              <CButton
                color="info"
                size="sm"
                class="m-2"
                variant="outline"
                v-else
              >
                {{ $t("crud.view") }}
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCollapse :show="collapse">
          <CForm @submit.prevent="validate">
            <!-- step 1,2,3,5 files -->
            <CCardBody v-if="fileType.includes(stepItem.type)">
              <p v-if="stepItem.type === 1">
                <strong>{{ $t("integration-process.step1-title") }}.</strong
                ><br />
                {{ $t("integration-process.step1-text") }}
              </p>
              <p v-if="stepItem.type === 2">
                <strong>{{ $t("integration-process.step2-title") }}.</strong
                ><br />
                {{ $t("integration-process.step2-text") }}<br /><br />
                {{ $t("integration-process.step2-text-2") }}
                <a
                  :href="$t('integration-process.step2-url')"
                  target="_blank"
                  :title="$t('integration-process.step2-url')"
                  >{{ $t("integration-process.step2-url") }}</a
                >
              </p>
              <p v-if="stepItem.type === 3">
                <strong>{{ $t("integration-process.step3-title") }}.</strong
                ><br />
                <a
                  :href="
                    publicPath +
                      'files/' +
                      $t('integration-process.step3-pdf-url')
                  "
                  target="_blank"
                >
                  {{ $t("integration-process.step3-text") }}</a
                >
                - {{ $t("integration-process.step3-text-2") }} <br /><br />
                {{ $t("integration-process.step3-text-3") }}
                <br /><br />
                <strong>{{ $t("integration-process.step3-text-4") }}</strong>
              </p>

              <CRow>
                <CCol sm="4" v-if="isEdit === 1">
                  <p>
                    <strong
                      >{{ $t("integration-process.upload-files") }}:</strong
                    >
                  </p>
                  <vue-dropzone
                    v-if="dropOptions !== null"
                    id="dropzone"
                    ref="VueDropzone"
                    :options="dropOptions"
                    v-on:vdropzone-queue-complete="updateFiles"
                    :useCustomSlot="true"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{ $t("system.drag_drop") }}
                      </h3>
                      <div class="subtitle">
                        {{ $t("system.content-in-computer") }}
                      </div>
                    </div>
                  </vue-dropzone>
                </CCol>
                <CCol sm="8">
                  <p class="ml-4">
                    <strong v-if="files.length > 0"
                      >{{ $t("integration-process.file-list") }}:</strong
                    >
                  </p>
                  <ul>
                    <li
                      v-for="(item, index) in files"
                      :key="item.id"
                      v-bind:item="item"
                    >
                      <strong>{{ item.original_filename }}</strong>
                      <span
                        v-on:click="
                          downloadMedia(item.url, item.original_filename)
                        "
                        target="_blank"
                      >
                        <CIcon
                          class="ml-2 mr-2 text-success pointer"
                          name="cil-cloud-download"
                        />
                      </span>
                      <span
                        v-on:click="removeFile(index, item.id)"
                        v-if="isEdit === 1"
                      >
                        <CIcon class="text-danger" name="cil-trash" />
                      </span>
                    </li>
                  </ul>
                </CCol>
              </CRow>
            </CCardBody>
            <!-- end step 1,2,3,5 -->

            <CCardBody v-if="stepItem.type === 4">
              <p>
                <strong>{{ $t("integration-process.step4-title") }}</strong
                ><br /><br />
                <i>{{ $t("integration-process.step4-text") }}</i>
              </p>
              <CTextarea
                :placeholder="$t('integration-process.step4-text-2')"
                rows="4"
                v-model="body[4].textarea"
                :disabled="isEdit === 1 ? false : true"
              ></CTextarea>
              <CInputCheckbox
                :label="$t('integration-process.step4-text-3')"
                id="checkbox"
                :checked.sync="body[4].checkbox"
                :disabled="isEdit === 1 ? false : true"
              />
            </CCardBody>

            <CCardBody v-if="stepItem.type === 5">
              <p>
                <strong>{{ $t("integration-process.step5-title") }}</strong
                ><br />
                <i>{{ $t("integration-process.step5-text") }}</i>
              </p>
              <CInputRadio
                id="Y"
                :label="$t('integration-process.step5-y')"
                name="pr"
                :checked="body[5].radiobutton === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[5].radiobutton = 'Y'"
              />
              <CInputRadio
                id="N"
                :label="$t('integration-process.step5-n')"
                name="pr"
                :checked="body[5].radiobutton === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[5].radiobutton = 'N'"
              />

              <br />

              <CRow v-if="body[5].radiobutton === 'Y'">
                <CCol sm="4" v-if="isEdit === 1">
                  <p>
                    <strong
                      >{{ $t("integration-process.upload-files") }}:</strong
                    >
                  </p>
                  <vue-dropzone
                    v-if="dropOptions !== null"
                    id="dropzone5"
                    ref="VueDropzone"
                    :options="dropOptions"
                    v-on:vdropzone-queue-complete="updateFiles"
                  ></vue-dropzone>
                </CCol>
                <CCol sm="8">
                  <p class="ml-4">
                    <strong v-if="files.length > 0"
                      >{{ $t("integration-process.file-list") }}:</strong
                    >
                  </p>
                  <ul>
                    <li
                      v-for="(item, index) in files"
                      :key="item.id"
                      v-bind:item="item"
                    >
                      <strong>{{ item.original_filename }}</strong>
                      <span
                        v-on:click="removeFile(index, item.id)"
                        v-if="isEdit === 1"
                      >
                        <CIcon class="text-danger" name="cil-trash" />
                      </span>
                    </li>
                  </ul>
                </CCol>
              </CRow>
            </CCardBody>

            <CCardBody v-if="stepItem.type === 6">
              <p>
                <strong>{{ $t("integration-process.step6-title-1") }}.</strong
                ><br />
                <i v-html="$t('integration-process.step6-text-1')"></i>
              </p>
              <CInputRadio
                :label="$t('integration-process.step6-agree')"
                name="permission1"
                :checked="body[6].permission1 === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission1 = 'Y'"
              />
              <CInputRadio
                :label="$t('integration-process.step6-dont-agree')"
                name="permission1"
                :checked="body[6].permission1 === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission1 = 'N'"
              />
              <br />
              <p>
                <strong>{{ $t("integration-process.step6-title-5") }}.</strong
                ><br />
                <i v-html="$t('integration-process.step6-text-5')"></i>
              </p>
              <CInputRadio
                :label="$t('integration-process.step6-agree')"
                name="permission5"
                :checked="body[6].permission5 === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission5 = 'Y'"
              />
              <CInputRadio
                :label="$t('integration-process.step6-dont-agree')"
                name="permission5"
                :checked="body[6].permission5 === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission5 = 'N'"
              />
              <br />
              <p>
                <strong>{{ $t("integration-process.step6-title-2") }}.</strong
                ><br />
                <i>{{ $t("integration-process.step6-text-2") }}</i>
              </p>
              <CInputRadio
                :label="$t('integration-process.step6-agree')"
                name="permission2"
                :checked="body[6].permission2 === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission2 = 'Y'"
              />
              <CInputRadio
                :label="$t('integration-process.step6-dont-agree')"
                name="permission2"
                :checked="body[6].permission2 === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission2 = 'N'"
              />
              <br />
              <p>
                <strong>{{ $t("integration-process.step6-title-3") }}.</strong
                ><br />
                <span v-html="$t('integration-process.step6-text-3')"></span>
              </p>
              <CInputRadio
                :label="$t('integration-process.step6-coupon-have')"
                name="permission3"
                :checked="body[6].permission3 === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission3 = 'Y'"
              />
              <CInputRadio
                :label="$t('integration-process.step6-coupon-dont-have')"
                name="permission3"
                :checked="body[6].permission3 === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission3 = 'N'"
              />
              <br />
              <CTextarea
                v-if="body[6].permission3 === 'Y'"
                :placeholder="$t('integration-process.step6-coupon-info')"
                rows="4"
                v-model="body[6].couponInfo"
                :disabled="isEdit === 1 ? false : true"
              ></CTextarea>
              <br />
              <p>
                <strong>{{ $t("integration-process.step6-title-4") }}.</strong
                ><br />
                <i v-html="$t('integration-process.step6-text-4')"></i>
              </p>
              <CInputRadio
                :label="$t('integration-process.step6-agree')"
                name="permission4"
                :checked="body[6].permission4 === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission4 = 'Y'"
              />
              <CInputRadio
                :label="$t('integration-process.step6-dont-agree')"
                name="permission4"
                :checked="body[6].permission4 === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[6].permission4 = 'N'"
              />
            </CCardBody>

            <CCardBody v-if="stepItem.type === 7">
              <p>
                <strong>{{ $t("integration-process.step7-title") }}</strong>
              </p>
              <p>
                {{ $t("integration-process.step7-text") }}
              </p>
              <CInputRadio
                :label="$t('integration-process.step7-label-1')"
                name="utm"
                :checked="body[7].utm === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[7].utm = 'Y'"
              />
              <CInputRadio
                :label="$t('integration-process.step7-label-3')"
                name="utm"
                :checked="body[7].utm === 'M'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[7].utm = 'M'"
              />
              <CInput
                :label="$t('integration-process.step7-label-3-input')"
                class="py-3"
                v-if="body[7].utm === 'M'"
                v-model="body[7].utmID"
              />
              <CInputRadio
                :label="$t('integration-process.step7-label-2')"
                name="utm"
                :checked="body[7].utm === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[7].utm = 'N'"
              />
              <br />
              <CTextarea
                v-if="body[7].utm === 'N'"
                :placeholder="
                  $t('integration-process.step7-label-2-placeholder')
                "
                rows="4"
                v-model="body[7].utmCode"
                :disabled="isEdit === 1 ? false : true"
              ></CTextarea>
            </CCardBody>

            <CCardBody v-if="stepItem.type === 8">
              <p>
                <strong>{{ $t("integration-process.step8-title") }}.</strong
                ><br />{{ $t("integration-process.step8-text-1") }}
              </p>
              <p>
                {{ $t("integration-process.step8-text-2") }}<br />{{
                  $t("integration-process.step8-text-3")
                }}
              </p>
              <p>
                <strong>{{ $t("integration-process.step8-text-4") }}</strong
                ><br /><a
                  :href="$t('integration-process.step8-text-5')"
                  target="_blank"
                  >{{ $t("integration-process.step8-text-5") }}</a
                >
              </p>
              <p>{{ $t("integration-process.step8-text-6") }}</p>
              <p v-html="$t('integration-process.step8-text-7')"></p>

              <CInputRadio
                :label="$t('integration-process.step8-label-1')"
                name="trackingCode"
                :checked="body[8].trackingCode === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[8].trackingCode = 'N'"
              />
              <CInputRadio
                :label="$t('integration-process.step8-label-2')"
                name="trackingCode"
                :checked="body[8].trackingCode === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[8].trackingCode = 'Y'"
              />

              <br /><br />

              <CRow>
                <CCol lg="6">
                  <p><strong>ClickTracking</strong></p>
                  <CButton
                    v-on:click="copyText($refs.clickTrack)"
                    size="sm"
                    class="mr-2"
                    color="info"
                    variant="outline"
                    :disabled="isSubmitting"
                    >{{ $t("integration-process.copy-code") }}</CButton
                  >
                  <CButton
                    v-on:click="
                      downloadFile(
                        'clicktracking-' + campaignName + '.txt',
                        htmlDecode(body[8].clickTracking)
                      )
                    "
                    size="sm"
                    color="info"
                    :disabled="isSubmitting"
                    >{{ $t("integration-process.download-code") }}</CButton
                  ><br /><br />
                  <textarea
                    ref="clickTrack"
                    rows="25"
                    class="form-control"
                    v-html="htmlDecode(body[8].clickTracking)"
                  ></textarea>
                </CCol>
                <CCol>
                  <p><strong>SaleTracking</strong></p>
                  <CButton
                    v-on:click="copyText($refs.saleTrack)"
                    size="sm"
                    class="mr-2"
                    color="info"
                    variant="outline"
                    :disabled="isSubmitting"
                    >{{ $t("integration-process.copy-code") }}</CButton
                  >
                  <CButton
                    v-on:click="
                      downloadFile(
                        'saletracking-' + campaignName + '.txt',
                        htmlDecode(body[8].saleTracking)
                      )
                    "
                    size="sm"
                    color="info"
                    :disabled="isSubmitting"
                    >{{ $t("integration-process.download-code") }}</CButton
                  ><br /><br />
                  <textarea
                    ref="saleTrack"
                    rows="25"
                    class="form-control"
                    v-html="htmlDecode(body[8].saleTracking)"
                  ></textarea>
                </CCol>
              </CRow>
            </CCardBody>

            <CCardBody v-if="stepItem.type === 9">
              <p>
                <strong>{{ $t("integration-process.step9-title") }}</strong
                ><br />
                {{ $t("integration-process.step9-text-1") }}
              </p>
              <p>
                {{ $t("integration-process.step9-text-2") }}:
                <a
                  href="https://www.google.com/search?q=what+is+my+ip"
                  target="_blank"
                  >https://www.google.com/search?q=what+is+my+ip</a
                >
              </p>
              <i>{{ $t("integration-process.step9-text-3") }}</i>
              <CTextarea
                :placeholder="$t('integration-process.step9-text-4')"
                rows="4"
                v-model="body[9].blockedIp"
                :disabled="isEdit === 1 ? false : true"
              ></CTextarea>
            </CCardBody>

            <CCardBody v-if="stepItem.type === 10">
              <div v-html="$t('integration-process.step10-text')"></div>

              <p v-if="isEdit !== 1" class="text-success">
                <CIcon name="cil-check" />
                {{ $t("integration-process.submit-accepted") }}
              </p>
            </CCardBody>

            <CCardBody v-if="stepItem.type === 11">
              <div v-html="$t('integration-process.step11-text')"></div>
              <p v-if="isEdit !== 1" class="text-success">
                <CIcon name="cil-check" />
                {{ $t("integration-process.submit-accepted") }}
              </p>
            </CCardBody>

            <CCardBody v-if="stepItem.type === 12">
              <div v-html="$t('integration-process.step12-text')"></div>

              <CInputRadio
                :label="$t('integration-process.step12-y')"
                name="accept"
                :checked="body[12].accept === 'Y'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[12].accept = 'Y'"
              />
              <CInputRadio
                :label="$t('integration-process.step12-n')"
                name="accept"
                :checked="body[12].accept === 'N'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[12].accept = 'N'"
              />
            </CCardBody>

            <CCardBody v-if="stepItem.type === 13">
              <div v-html="$t('integration-process.step13-text')"></div>
              <CInputRadio
                :label="$t('integration-process.step13-n')"
                name="permission"
                :checked="body[13].permission === '1'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[13].permission = '1'"
              />
              <CInputRadio
                :label="$t('integration-process.step13-v1')"
                name="permission"
                :checked="body[13].permission === '2'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[13].permission = '2'"
              />
              <CInputRadio
                :label="$t('integration-process.step13-v2')"
                name="permission"
                :checked="body[13].permission === '3'"
                :disabled="isEdit === 1 ? false : true"
                @change="body[13].permission = '3'"
              />
            </CCardBody>

            <CCardBody v-if="stepItem.type === 14">
              <div v-html="$t('integration-process.step14-text')"></div>

              <p v-if="isEdit !== 1" class="text-success">
                <CIcon name="cil-check" />
                {{ $t("integration-process.submit-accepted") }}
              </p>
            </CCardBody>

            <CCardBody v-if="stepItem.type === 15">
              <div v-html="$t('integration-process.step15-text')"></div>
              <a :href="body[15].campaignUrlNotes" target="_blank">{{ body[15].campaignUrlNotes }}</a>



              <p v-if="isEdit !== 1" class="text-success">
                <CIcon name="cil-check" />
                {{ $t("integration-process.submit-accepted") }}
              </p>
            </CCardBody>

            <CCardFooter v-if="isEdit === 1">
              <CButton
                type="submit"
                size="sm"
                color="success"
                :disabled="isSubmitting"
                >{{
                  stepItem.type === 10 ||
                  stepItem.type === 11 ||
                  stepItem.type === 14 ||
                  stepItem.type === 15
                    ? $t("integration-process.submit-accept")
                    : $t("integration-process.submit-review")
                }}</CButton
              >
            </CCardFooter>
          </CForm>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import i18n from "@/i18n";
import { mapActions } from "vuex";
import vueDropzone from "vue2-dropzone";
import crud from "@/store/modules/crud";
import axios from "axios";

export default {
  name: "IntegrationProcessPapAllSteps",
  props: ["stepItem", "campaignName"],
  components: {
    vueDropzone
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      fileType: [1, 2, 3],
      isSubmitting: false,
      collapse: this.stepItem.type === 1 ? true : false,
      files: [],
      isEdit: this.stepItem.status,
      body: {
        4: {
          //xmlFeed
          textarea: "",
          checkbox: false
        },
        5: {
          //pr
          radiobutton: "" //N
        },
        6: {
          //Permissions
          permission1: "", //N
          permission2: "", //N
          permission3: "", //N
          couponInfo: "",
          permission4: "", //N
          permission5: ""
        },
        7: {
          //UTM
          utm: "", //Y
          utmCode: "",
          utmID: ""
        },
        8: {
          //trackingCode
          trackingCode: "", //N
          clickTracking: "",
          saleTracking: ""
        },
        9: {
          //IP
          blockedIp: ""
        },
        12: {
          accept: "" //N
        },
        13: {
          permission: "" //1,2,3
        },
        15: {
          campaignUrlNotes: ""
        }
      },
      dropOptions: {
        url:
          process.env.VUE_APP_BASE_URL +
          "integration-process/files?step=" +
          this.stepItem.id,
        headers: {
          "X-Pap-UserId": this.$route.query.userId,
          "X-Pap-SessionId": this.$route.query.session,
          "X-Pap-Role": "M"
        }
      },
      dictDefaultMessage: i18n.t("integration-process.drop-files")
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    copyText(ref) {
      let textToCopy = ref;
      textToCopy.select();
      document.execCommand("copy");
    },

    async downloadMedia(url, filename) {
      await axios
        .get(url, {
          headers: {
            "X-Pap-UserId": this.$route.query.userId,
            "X-Pap-SessionId": this.$route.query.session
          },
          responseType: "blob"
        })
        .then(function(response) {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },

    htmlDecode(value) {
      return value
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"');
    },

    downloadFile: function(fileName, body) {
      let fileType = "text/plain";
      let blob = new Blob([body], { type: fileType });
      let a = document.createElement("a");
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function() {
        URL.revokeObjectURL(a.href);
      }, 1500);
    },

    getFiles(updateFiles) {
      let self = this;
      crud
        .get(
          "integration-process/steps/" + this.stepItem.id + "?expand=files",
          {
            headers: {
              "X-Pap-UserId": this.$route.query.userId,
              "X-Pap-SessionId": this.$route.query.session
            }
          }
        )
        .then(function(response) {
          if (
            self.fileType.includes(self.stepItem.type) ||
            self.stepItem.type === 5
          ) {
            self.files = response.data.files;
          }

          if (response.data.body && !updateFiles) {
            self.body[self.stepItem.type] = response.data.body;
          }
          self.loading = false;
        });
    },

    updateFiles(/*file, xhr, formData*/) {
      this.getFiles(true);
    },

    removeFile(index, id) {
      this.isSubmitting = true;

      crud
        .delete("integration-process/files/" + id, {
          headers: {
            "X-Pap-UserId": this.$route.query.userId,
            "X-Pap-SessionId": this.$route.query.session
          }
        })
        .then(() => {
          this.isSubmitting = false;
          this.files.splice(index, 1);
          this.$refs.VueDropzone.removeAllFiles();
          this.addNotification(
            {
              type: "success",
              message: i18n.t("success.record-deleted")
            },
            { root: true }
          );
        })
        .catch(error => {
          this.isSubmitting = false;
          this.addNotification({
            type: "danger",
            message: error.response.data[0].message
          });
        });
    },

    validate() {
      if (this.stepItem.type === 5 && this.body[5].radiobutton === "") {
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      } else if (
        this.stepItem.type === 6 &&
        (this.body[6].permission1 === "" ||
          this.body[6].permission2 === "" ||
          this.body[6].permission3 === "" ||
          this.body[6].permission4 === "" ||
          this.body[6].permission5 === "")
      ) {
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      } else if (
        this.stepItem.type === 7 &&
        (this.body[7].utm === "" ||
          (this.body[7].utm === "M" && this.body[7].utmID === "") ||
          (this.body[7].utm === "N" && this.body[7].utmCode === ""))
      ) {
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      } else if (
        this.stepItem.type === 8 &&
        (this.body[8].trackingCode === "" || !this.body[8].trackingCode)
      ) {
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      } else if (this.stepItem.type === 12 && this.body[12].accept === "") {
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      } else if (this.stepItem.type === 13 && this.body[13].permission === "") {
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      } else if (
        this.fileType.includes(this.stepItem.type) &&
        this.files.length === 0
      ) {
        this.addNotification({
          type: "danger",
          message: i18n.t("error.file-empty")
        });
      } else if (
        this.stepItem.type === 4 &&
        this.body[4].checkbox === false &&
        !this.body[4].textarea
      ) {
        //XML feed
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      } else if (
        this.stepItem.type === 5 &&
        this.body[5].radiobutton === "Y" &&
        this.files.length === 0
      ) {
        //PR
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      } else {
        this.submit();
      }
    },

    setData() {
      let data = null;

      switch (this.stepItem.type) {
        case 4: //xmlFeed
          data = {
            textarea: this.body[4].textarea,
            checkbox: this.body[4].checkbox
          };
          break;
        case 5: //PR
          data = { radiobutton: this.body[5].radiobutton };
          break;
        case 6: //Publishing permissions
          data = {
            permission1: this.body[6].permission1,
            permission2: this.body[6].permission2,
            permission3: this.body[6].permission3,
            couponInfo: this.body[6].couponInfo,
            permission4: this.body[6].permission4,
            permission5: this.body[6].permission5
          };
          break;
        case 7: //UTM
          data = {
            utm: this.body[7].utm,
            utmCode: this.body[7].utmCode,
            utmID: this.body[7].utmID
          };
          break;
        case 8: //tracking codes
          data = {
            trackingCode: this.body[8].trackingCode,
            clickTracking: this.body[8].clickTracking,
            saleTracking: this.body[8].saleTracking
          };
          break;
        case 9: //Block tracking by IP address
          data = {
            blockedIp: this.body[9].blockedIp
          };
          break;
        case 12:
          data = {
            accept: this.body[12].accept
          };
          break;
        case 13:
          data = {
            permission: this.body[13].permission
          };
          break;
      }

      return data;
    },

    submit() {
      this.isSubmitting = true;

      let data = { status: 2, body: this.setData() };

      crud
        .put("integration-process/steps/" + this.stepItem.id, data, {
          headers: {
            "X-Pap-UserId": this.$route.query.userId,
            "X-Pap-SessionId": this.$route.query.session
          }
        })
        .then(() => {
          this.isSubmitting = false;
          this.isEdit = 2;
          this.addNotification(
            {
              type: "success",
              message: i18n.t("success.record-updated")
            },
            { root: true }
          );
        })
        .catch(error => {
          this.isSubmitting = false;
          this.addNotification({
            type: "danger",
            message: error.response.data[0].message
          });
        });

      this.isSubmitting = false;
    }
  },
  mounted() {
    //this.fileType.includes(this.stepItem.type) ? this.getFiles() : "";
    this.getFiles();
  },
  computed: {
    step: function() {
      let ls = localStorage.getItem("vuex")
        ? localStorage.getItem("vuex")
        : "{}";

      return JSON.parse(ls).enumerators.enumerators["step-type"];
    }
  }
};
</script>
<style scoped>
.text-danger,
.pointer {
  cursor: pointer;
}
</style>
