<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.dashboard") }}
            </h4>
            <div class="small text-muted">{{ month }}</div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol sm="12" md="6">
        <StatsWidget />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import StatsWidget from "@/components/dashboard/StatsWidget";
import moment from "moment";

export default {
  name: "dashboard",
  components: {
    StatsWidget
  },
  data() {
    return {};
  },
  computed: {
    month: function() {
      return moment().format("MMMM YYYY");
    }
  }
};
</script>
