<template>
  <div>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-6">
          <div class="card card-default">
            <div class="card-body">
              <h1>{{ $t("system.new") }} {{ $t("login.password") }}</h1>
              <hr />
              <!-- <ul v-if="errors">
              <li v-for="error in errors" v-bind:key="error">{{ msg }}</li>
            </ul> -->
              <form autocomplete="off" @submit.prevent="submit" method="post">
                <div class="form-group">
                  <label for="username">{{ $t("login.username") }}</label>
                  <input
                    type="text"
                    id="username"
                    class="form-control"
                    v-model="form.username"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="email">{{ $t("login.password") }}</label>
                  <input
                    type="password"
                    id="password"
                    class="form-control"
                    placeholder=""
                    v-model="form.password"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="email"
                    >{{ $t("system.confirm") }}
                    {{ $t("login.password") }}</label
                  >
                  <input
                    type="password"
                    id="password_confirmation"
                    class="form-control"
                    placeholder=""
                    v-model="form.password_confirmation"
                    required
                  />
                </div>
                <button type="submit" class="btn btn-info">
                  {{ $t("system.update") }}
                </button>
                <router-link
                  :to="{
                    name: 'login'
                  }"
                  class="btn btn-light ml-1"
                  >{{ $t("system.cancel") }}</router-link
                >
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "reset-password-form",
  components: {
    //
  },

  data() {
    return {
      form: {
        token: null,
        username: null,
        password: null,
        password_confirmation: null,
        has_error: false
      }
    };
  },

  methods: {
    ...mapActions({
      resetPassword: "auth/resetPassword"
    }),

    submit() {
      this.form.token = this.$route.params.token;

      this.resetPassword(this.form).then(() => {
        /*this.$router.replace({
          name: "dashboard"
        });*/
      });
    }
  }
};
</script>
