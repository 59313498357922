import i18n from "@/i18n";

//Product service
import ProductServicePapIndex from "../views/product_service/ps-pap/ProductServicePapIndex.vue";
import ProductServicePapCreate from "../views/product_service/ps-pap/ProductServicePapCreate.vue";
import ProductServicePapEdit from "../views/product_service/ps-pap/ProductServicePapEdit.vue";
import ProductServicePapDuplicate from "../views/product_service/ps-pap/ProductServicePapDuplicate.vue";
import ProductServicePapView from "../views/product_service/ps-pap/ProductServicePapView.vue";

export default [
  {
    data: {
      pap: [
        {
          path: "/pap/product-service",
          name: i18n.t("menu.product-service"),
          component: ProductServicePapIndex,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/pap/product-service/create",
          name: i18n.t("menu.product-service") + ": " + i18n.t("crud.create"),
          component: ProductServicePapCreate,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/pap/product-service/edit/:id",
          name: i18n.t("menu.product-service") + ": " + i18n.t("crud.edit"),
          component: ProductServicePapEdit,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/pap/product-service/duplicate/:id",
          name:
            i18n.t("menu.product-service") + ": " + i18n.t("system.duplicate"),
          component: ProductServicePapDuplicate,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        },
        {
          path: "/pap/product-service/view/:id",
          name: i18n.t("menu.product-service") + ": " + i18n.t("crud.view"),
          component: ProductServicePapView,
          meta: {
            nonRedirect: true,
            requiresAuth: false
          }
        }
      ],
      admin: null
    }
  }
];
