<template>
  <div>
    <div v-if="loading">
      <CSpinner color="info" class="loading" /><br />
      <div class="text-center">
        <CButton @click="reloadPage" color="secondary" size="sm">
          Refresh
        </CButton>
      </div>
    </div>
    <div v-else>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardBody>
              <Search ref="search" v-bind:columns="this.search" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard v-if="Object.keys(items).length === 0">
            <CCardBody>
              <h3>{{ $t("system.search") }}:</h3>
              {{ $t("message.search-empty") }}.
            </CCardBody>
          </CCard>
          <CCard v-else>
            <CCardBody>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        {{ $t("integration-process.campaign-name") }}
                      </th>
                      <th scope="col">{{ $t("system.name") }}</th>
                      <th scope="col" style="min-width: 250px;">
                        {{ $t("system.description") }}
                      </th>
                      <th scope="col">{{ $t("integration-process.type") }}</th>
                      <th scope="col">{{ $t("xml-manager.format") }}</th>
                      <th scope="col">{{ $t("system.language") }}</th>
                      <th scope="col">URL</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, index) in items">
                      <tr v-for="key in item" :key="key.id">
                        <th scope="row">
                          {{ index }}
                        </th>
                        <td scope="row">
                          <template
                            v-if="
                              key.name === null ||
                                key.name === 'null' ||
                                key.name === ''
                            "
                          >
                            -
                          </template>
                          <template v-else>
                            {{ key.name }}
                          </template>
                        </td>
                        <td>
                          <template
                            v-if="
                              key.description === null ||
                                key.description === 'null' ||
                                key.description === ''
                            "
                          >
                            -
                          </template>
                          <template v-else>
                            {{ key.description | truncate(40, "...") }}
                            <CButton
                              @click="key.warningModal = true"
                              color="info"
                              variant="ghost"
                              size="sm"
                            >
                              {{ $t("system.more") }}
                            </CButton>
                            <CModal
                              :title="$t('system.description')"
                              color="info"
                              :show.sync="key.warningModal"
                            >
                              {{ key.description }}
                            </CModal>
                          </template>
                        </td>
                        <td>
                          <c-badge color="success">{{
                            $store.state.enumerators.enumerators["feed-type"][
                              key.type
                            ]
                          }}</c-badge>
                        </td>
                        <td>
                          <c-badge color="info">{{
                            $store.state.enumerators.enumerators["feed-format"][
                              key.format
                            ]
                          }}</c-badge>
                        </td>
                        <td>
                          <c-badge color="warning">{{
                            $store.state.enumerators.enumerators["language"][
                              key.language
                            ]
                          }}</c-badge>
                        </td>
                        <td
                          v-if="
                            key.is_secret === 1 &&
                              !$options.filters.validURL(key.url)
                          "
                        >
                          <c-badge color="danger"
                            >{{ $t("xml-manager.access_feed") }}
                            {{ key.url }}</c-badge
                          >
                        </td>
                        <td v-else>
                          <input
                            class="form-control"
                            :ref="'xmlUrl' + key.id"
                            :value="key.url"
                            disabled
                          />
                          <span
                            class="text-info font-xs"
                            v-if="
                              key.is_secret === 1 &&
                                $options.filters.validURL(key.url)
                            "
                            >{{ $t("xml-manager.url-info-text") }}</span
                          >
                        </td>
                        <td class="text-right">
                          <CButton
                            v-if="
                              key.is_secret === 0 ||
                                (key.is_secret === 1 &&
                                  $options.filters.validURL(key.url))
                            "
                            v-on:click="copyText($refs['xmlUrl' + key.id][0])"
                            class="mr-2"
                            color="info"
                            variant="outline"
                            >{{ $t("system.copy") }} URL</CButton
                          >
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Search from "@/components/ui/Search";

export default {
  name: "XmlManagerPapIndex",
  components: {
    Search
  },
  data() {
    return {
      loading: false,
      isSubmitting: false,
      xPap: {
        userId: null,
        session: null,
        role: null
      },
      campaignsId: [],
      items: [],
      selectOptions: []
      /*search: [
        {
          name: "campaign_id",
          options: [],
          label: i18n.t("integration-process.campaign-name"),
          type: "select2",
          operator: "and",
          like: false
        },
        {
          name: "type",
          label: i18n.t("integration-process.type"),
          type: "select",
          operator: "and",
          like: false,
          enum: "feed-type"
        },
        {
          name: "format",
          label: i18n.t("xml-manager.format"),
          type: "select",
          operator: "and",
          like: false,
          enum: "feed-format"
        },
        {
          name: "language",
          label: i18n.t("system.language"),
          type: "select",
          operator: "and",
          like: false,
          enum: "language"
        }
      ]*/
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification",
      setLang: "auth/setLang"
    }),

    reloadPage() {
      window.location.reload();
    },

    copyText(ref) {
      let textToCopy = ref;
      textToCopy.disabled = false;
      textToCopy.select();
      textToCopy.style.background = "#b6f89f";
      document.execCommand("copy");
      textToCopy.disabled = true;
      setTimeout(() => {
        textToCopy.style.background = "transparent";
      }, 1500);
    },

    createUrlXPap() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          userId: this.xPap.userId,
          session: this.xPap.session,
          role: this.xPap.role
        })
      });
    },

    pagination() {
      this.getXml();
      this.createUrlXPap();
    },

    groupBy(array, key) {
      const result = {};
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });

      //resort by KEY
      // Object.keys(result).forEach(k => self[k] = result[k]);

      return result;
    },

    selectSearchOptions() {
      let self = this;

      crud
        .get("xml-manager/feeds?per-page=100000", {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          let options = [];

          for (const value of response.data.items) {
            if (
              !options.some(options => options["id"] === value["campaign_id"])
            ) {
              options.push({
                id: value["campaign_id"],
                name: value["campaign_name"]
              });
            }
          }
          self.selectOptions = options;
          //self.search[0].options = options;
        });
    },

    getXml() {
      this.loading = true;
      let self = this;

      let query = this.$refs.search.parseUrlQuery();

      let afterQuery = "";
      if (query !== "?") afterQuery = "&";

      crud
        .get("xml-manager/feeds" + query + afterQuery + "per-page=100000", {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          //set modal
          for (const key of Object.entries(response.data.items)) {
            self.$set(response.data.items[key[0]], "warningModal", false);
          }

          let unordered = self.groupBy(response.data.items, "campaign_name");

          const ordered = {};
          Object.keys(unordered)
            .sort(function(a, b) {
              return a.toLowerCase().localeCompare(b.toLowerCase());
            })
            .forEach(function(key) {
              ordered[key] = unordered[key];
            });

          self.items = ordered;

          self.selectSearchOptions();

          //console.log(self.items);
          self.loading = false;
        });
    }
  },
  created() {
    this.setLang();
  },
  mounted() {
    this.xPap.userId = this.$route.query.userId;
    this.xPap.session = this.$route.query.session;
    this.xPap.role = this.$route.query.role;

    this.getXml();
  },
  computed: {
    search: function() {
      return [
        {
          name: "campaign_id",
          options: this.selectOptions,
          label: i18n.t("integration-process.campaign-name"),
          type: "select2",
          operator: "and",
          like: false
        },
        {
          name: "type",
          label: i18n.t("integration-process.type"),
          type: "select",
          operator: "and",
          like: false,
          enum: "feed-type"
        },
        {
          name: "format",
          label: i18n.t("xml-manager.format"),
          type: "select",
          operator: "and",
          like: false,
          enum: "feed-format"
        },
        {
          name: "language",
          label: i18n.t("system.language"),
          type: "select",
          operator: "and",
          like: false,
          enum: "language"
        }
      ];
    }
  }
};
</script>
