<template>
  <CFooter :fixed="false">
    <div class="mfs-auto">
      <a class="mr-1" href="https://www.dognet.sk/" target="_blank">Panel 2</a>
      <span>&copy; {{ new Date().getFullYear() }} Dognet.sk.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>
