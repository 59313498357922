<template>
  <div>
    <CForm @submit.prevent="submit">
      <CRow>
        <CCol lg="6">
          <label for="campaign">{{
            $t("integration-process.campaign-name")
          }}</label>
          <v-select
            :options="select_options"
            label="name"
            v-model="form.selected"
            id="campaign"
            required
            :disabled="isEdit ? true : false"
          ></v-select>
        </CCol>
        <CCol lg="3">
          <CInput
            :label="$t('system.name')"
            id="name"
            name="name"
            v-model="form.name"
          />
        </CCol>
        <CCol lg="3">
          <CInput
            :label="$t('system.description')"
            id="description"
            name="description"
            v-model="form.description"
          />
        </CCol>
        <CCol lg="12">
          <CInput
            label="URL"
            id="url"
            name="url"
            v-model="form.url"
            v-on:change="parseUrl"
          />
        </CCol>
        <CCol lg="3" ref="blinkType">
          <label>
            {{ $t("integration-process.type") }}
            <CBadge color="success" v-if="autoSet.type">{{
              $t("xml-manager.auto-set")
            }}</CBadge>
          </label>
          <CSelect
            id="type"
            name="type"
            :options="
              $options.filters.objectToSelectBox(
                $store.state.enumerators.enumerators['feed-type']
              )
            "
            :value.sync="form.type"
          />
        </CCol>
        <CCol lg="2" ref="blinkFormat">
          <label>
            {{ $t("xml-manager.format") }}
            <CBadge color="success" v-if="autoSet.format">{{
              $t("xml-manager.auto-set")
            }}</CBadge>
          </label>
          <CSelect
            id="format"
            name="format"
            :options="
              $options.filters.objectToSelectBox(
                $store.state.enumerators.enumerators['feed-format']
              )
            "
            :value.sync="form.format"
          />
        </CCol>

        <CCol lg="3" ref="blinkLang">
          <label>
            {{ $t("system.language") }}
            <CBadge color="success" v-if="autoSet.lang">{{
              $t("xml-manager.auto-set")
            }}</CBadge>
          </label>
          <CSelect
            id="lang"
            name="lang"
            :options="
              $options.filters.objectToSelectBox(
                $store.state.enumerators.enumerators['language']
              )
            "
            :value.sync="form.language"
          />
        </CCol>

        <CCol lg="2">
          <p>{{ $t("xml-manager.is-secret") }}?</p>
          <CSwitch
            type="checkbox"
            id="secret"
            color="info"
            shape="pill"
            :checked.sync="form.secret.checked"
          />
          <label for="secret" class="switchCheckBox"
            ><strong>{{ $t("system.yes") }}</strong></label
          >
        </CCol>
        <CCol lg="2">
          <p>{{ $t("xml-manager.is-in-product-service") }}?</p>
          <CSwitch
            type="checkbox"
            id="productService"
            color="info"
            shape="pill"
            :checked.sync="form.productService.checked"
          />
          <label for="productService" class="switchCheckBox"
            ><strong>{{ $t("system.yes") }}</strong></label
          >
        </CCol>
        <CCol lg="5">
          <CInput
            :label="$t('xml-manager.contact_email')"
            id="contact_email"
            name="contact_email"
            v-model="form.contact_email"
          />
        </CCol>
        <CCol lg="3">
          <label>&nbsp;</label><br />
          <a
            href="javascript:void(0)"
            v-if="isEdit && form.secret.checked"
            class="mt-2 btn btn-success btn-xs"
            @click="addUsersModal = true"
            ><CSpinner color="white" size="sm" v-if="isSubmitting" />
            {{ $t("xml-manager.add-secret-users") }} ({{
              usersSelected.length
            }})</a
          >
          <CModal
            :title="$t('xml-manager.add-secret-users')"
            color="warning"
            size="xl"
            :show.sync="addUsersModal"
          >
            <CInput
              :label="$t('xml-manager.search-user')"
              id="userSearch"
              name="userSearch"
              v-model="userSearch"
              v-on:keyup.enter="addUsersModal = true"
            />

            <CButton
              v-if="userSearch"
              size="sm"
              color="secondary"
              class="mb-2"
              @click="userSearch = ''"
              >{{ $t("system.cancel") }}</CButton
            >

            <table
              class="table table-dark"
              v-if="userSearch.length >= 3 && filteredList.length > 0"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">REFID</th>
                  <th scope="col">{{ $t("system.name") }}</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">{{ $t("system.status") }}</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredList" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.refid }}</td>
                  <td>{{ item.firstname + " " + item.lastname }}</td>
                  <td>{{ item.username }}</td>
                  <td>
                    {{
                      $t(
                        "enum." +
                          $store.state.enumerators.enumerators[
                            "affiliate-account-rstatus"
                          ][item.rstatus]
                      )
                    }}
                  </td>
                  <td class="text-right">
                    <CButton
                      v-if="usersSelected.find(el => el.id === item.id)"
                      class="btn btn-outline-dark"
                      size="sm"
                      disabled="disabled"
                    >
                      <CIcon name="cil-check" class="text-success" />
                    </CButton>
                    <CButton
                      v-else
                      :disabled="userAddLoading === item.id"
                      size="sm"
                      color="success"
                      class="mb-2"
                      @click="saveUser(item.id)"
                    >
                      <CSpinner
                        color="white"
                        size="sm"
                        v-if="userAddLoading === item.id"
                      />
                      <span v-else>{{ $t("system.add") }}</span>
                    </CButton>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">REFID</th>
                  <th scope="col">{{ $t("system.name") }}</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">{{ $t("system.status") }}</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in usersSelected" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.refid }}</td>
                  <td>{{ item.firstname + " " + item.lastname }}</td>
                  <td>{{ item.username }}</td>
                  <td>
                    {{
                      $t(
                        "enum." +
                          $store.state.enumerators.enumerators[
                            "affiliate-account-rstatus"
                          ][item.rstatus]
                      )
                    }}
                  </td>
                  <td class="text-right">
                    <CButton
                      size="sm"
                      color="danger"
                      class="mb-2"
                      :disabled="userDelLoading === item.id"
                      @click="deleteUser(item.id)"
                    >
                      <CSpinner
                        color="white"
                        size="sm"
                        v-if="userDelLoading === item.id"
                      />
                      <span v-else>{{ $t("system.delete") }}</span>
                    </CButton>
                  </td>
                </tr>
              </tbody>
            </table>

            <template v-slot:footer>
              <button
                type="button"
                class="btn btn-secondary"
                @click="addUsersModal = false"
              >
                {{ $t("system.close") }}
              </button>
            </template>
          </CModal>
        </CCol>
      </CRow>

      <hr />

      <CButton
        v-if="isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
        ><CSpinner color="white" size="sm" v-if="isSubmitting" />
        {{ $t("system.update") }}</CButton
      >
      <CButton
        v-if="!isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
      >
        <CSpinner color="white" size="sm" v-if="isSubmitting" />
        {{ $t("crud.create") }}
      </CButton>
      <CButton size="sm" color="secondary" class="ml-2" to="/xml-manager">{{
        $t("system.cancel")
      }}</CButton>
    </CForm>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import i18n from "@/i18n";

export default {
  name: "IntegrationProcessCreate",
  props: ["isEdit", "isView"],
  components: {
    vSelect
  },
  data() {
    return {
      deleteText: "",
      deleteError: false,
      autoSet: {
        type: false,
        format: false,
        lang: false
      },
      form: {
        type: "13",
        format: "1",
        language: "sk-SK",
        name: null,
        description: null,
        url: null,
        contact_email: null,
        secret: {
          checked: false
        },
        productService: {
          checked: false
        },
        selected: []
      },
      users: [],
      userSearch: "",
      usersSelected: [],
      userAddLoading: 0,
      userDelLoading: 0,
      select_options: [],
      isSubmitting: false,
      addUsersModal: false
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getUsers() {
      let self = this;

      crud.get("pap/affiliates?per-page=10000").then(function(response) {
        self.users = response.data.items;
      });
    },

    getSelectedUsers() {
      let self = this;

      crud
        .get(
          "xml-manager/feeds/" + this.$route.params.id + "?expand=affiliates"
        )
        .then(function(response) {
          self.usersSelected = response.data.affiliates;
        });
    },

    saveUser(userId) {
      this.userAddLoading = userId;

      let self = this;

      let data = {
        feed_id: this.$route.params.id,
        affiliates: [userId]
      };

      crud
        .post("xml-manager/feeds/link-affiliates", data)
        .then(function(response) {
          if (response.status === 200) {
            self.getSelectedUsers();
            self.userAddLoading = 0;
          }
        });
    },

    deleteUser(userId) {
      this.userDelLoading = userId;

      let self = this;

      let data = {
        feed_id: this.$route.params.id,
        affiliates: [userId]
      };

      crud
        .post("xml-manager/feeds/unlink-affiliates", data)
        .then(function(response) {
          if (response.status === 200) {
            self.getSelectedUsers();
            self.userDelLoading = 0;
          }
        });
    },

    parseUrl() {
      let url = this.form.url;

      if (url) {
        let urlSettings = this.$options.filters.feedParser(
          url,
          this.$store.state.enumerators.enumerators["feed-type"],
          this.$store.state.enumerators.enumerators["feed-format"],
          this.$store.state.enumerators.enumerators["language"]
        );

        if (urlSettings[0] !== null) {
          this.form.type = urlSettings[0];
          this.blink("type");
        }

        if (urlSettings[1] !== null) {
          this.form.format = urlSettings[1];
          this.blink("format");
        }

        if (urlSettings[2] !== null) {
          this.form.lang = urlSettings[2];
          this.blink("lang");
        }
      }
    },

    blink(el) {
      let ref;
      switch (el) {
        case "type":
          ref = this.$refs["blinkType"];
          this.autoSet.type = true;
          break;
        case "format":
          ref = this.$refs["blinkFormat"];
          this.autoSet.format = true;
          break;
        case "lang":
          ref = this.$refs["blinkLang"];
          this.autoSet.lang = true;
          break;
      }
      ref.style.background = "#9aff98";
      ref.style.transition = "all 0.5s";
      setTimeout(() => {
        ref.style.background = "transparent";
        this.autoSet.type = false;
        this.autoSet.format = false;
        this.autoSet.lang = false;
      }, 1500);
    },

    //set edit
    getFeedByID() {
      let self = this;
      crud
        .get("xml-manager/feeds/" + this.$route.params.id)
        .then(function(response) {
          let names = [
            "type",
            "format",
            "language",
            "name",
            "description",
            "url",
            "contact_email"
          ];

          for (let el in names) {
            self.form[names[el]] =
              response.data[names[el]] !== null
                ? String(response.data[names[el]])
                : "";
          }

          self.form.selected.push({
            id: response.data.campaign_id,
            name: response.data.campaign_name
          });

          self.form.secret.checked =
            response.data.is_secret === 1 ? true : false;
          self.form.productService.checked =
            response.data.is_in_product_service === 1 ? true : false;
        });
    },

    getPapCampaign() {
      let self = this;
      crud
        .get(
          "pap/campaigns?filter[rstatus][in][]=A&filter[rstatus][in][]=W&&fields=id,name&per-page=100000"
        )
        .then(function(response) {
          for (const key of Object.values(response.data.items)) {
            self.select_options.push(key);
          }
        });
    },

    submit() {
      this.isSubmitting = true;

      let data = this.isEdit ? new URLSearchParams() : new FormData();

      data.append("campaign_id", this.form.selected.id);
      this.form.name !== null ? data.append("name", this.form.name) : "";
      this.form.description !== null
        ? data.append("description", this.form.description)
        : "";
      data.append("type", this.form.type);
      data.append("format", this.form.format);
      data.append("language", this.form.language);
      data.append("url", this.form.url);
      data.append("is_test", 0);

      let is_secret = this.form.secret.checked ? 1 : 0;
      let is_in_product_service = this.form.productService.checked ? 1 : 0;

      data.append("is_secret", is_secret);
      data.append("is_in_product_service", is_in_product_service);
      data.append("is_test", 0);

      data.append("contact_email", this.form.contact_email);

      if (this.isEdit) {
        //problem with selected, must be [0]
        data.append("campaign_id", this.form.selected[0].id);

        crud
          .put("xml-manager/feeds/" + this.$route.params.id, data)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/xml-manager");
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-updated")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.response.data[0].message
            });
          });
      } else {
        //if (this.form.name) {
        crud
          .post("xml-manager/feeds", data)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/xml-manager");
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-add")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.response.data[0].message
            });
          });
        /*} else {
          this.isSubmitting = false;
          this.addNotification({
            type: "danger",
            message: i18n.t("error.select-box-empty")
          });
        }*/
      }
    }
  },
  created() {
    this.isEdit ? this.getFeedByID() : "";
    this.getPapCampaign();
    this.getUsers();
    this.isEdit ? this.getSelectedUsers() : "";
  },
  computed: {
    filteredList() {
      if (this.userSearch === "") return null;

      let self = this;

      let refid = this.users.filter(user => {
        return user.refid.toLowerCase().includes(self.userSearch.toLowerCase());
      });

      if (refid.length > 0) return refid;

      return this.users.filter(user => {
        return user.username
          .toLowerCase()
          .includes(self.userSearch.toLowerCase());
      });
    }
  }
};
</script>
