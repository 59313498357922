<template>
  <div>
    <div v-if="loading">
      <CSpinner color="info" class="loading" /><br />
      <div class="text-center">
        <CButton @click="reloadPage" color="secondary" size="sm">
          Refresh
        </CButton>
      </div>
    </div>
    <div v-else-if="forbidden">
      <CAlert
        color="warning"
        closeButton
        v-html="$t('messaging.forbidden')"
      ></CAlert>
    </div>
    <div v-else>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="6">
                  <h4 id="traffic" class="card-title mb-0">
                    {{ $t("menu.messaging") }}
                  </h4>
                  <div class="small text-muted">
                    {{ $t("messaging.perex") }}
                  </div>
                </CCol>
                <CCol sm="6" class="text-right pt-2">
                  <CButtonGroup>
                    <CButton
                      color="info"
                      variant="outline"
                      :to="
                        '/pap/messaging?userId=' +
                          this.$route.query.userId +
                          '&session=' +
                          this.$route.query.session +
                          '&role=' +
                          this.$route.query.role
                      "
                    >
                      {{ $t("messaging.inbox") }}
                    </CButton>
                    <CButton
                      color="info"
                      :to="
                        '/pap/messaging/publishers?userId=' +
                          this.$route.query.userId +
                          '&session=' +
                          this.$route.query.session +
                          '&role=' +
                          this.$route.query.role
                      "
                    >
                      {{ $t("system.affiliates") }}
                    </CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <Search ref="search" v-bind:columns="this.search" class="p-0" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CAlert
            v-if="!selectedCampaign"
            color="warning"
            closeButton
            v-html="$t('messaging.publisher_warning_search')"
          ></CAlert>
          <CCard v-else>
            <CCardBody>
              <CRow>
                <CCol sm="12" class="mb-1">
                  <h4 class="card-title">
                    {{ $t("system.affiliates") }}
                  </h4>
                </CCol>
              </CRow>
              {{ this.$route.query.campaignId }}
              <CDataTable :loading="reloading" :items="items" :fields="fields">
                <template v-slot:no-items-view>
                  <div class="text-center my-5">
                    <h2>
                      {{ $t("message.search-empty") }}
                    </h2>
                  </div>
                </template>
                <template #fullname="{item}">
                  <td>
                    <router-link
                      :to="{
                        path:
                          '/pap/messaging/publishers/' +
                          campaignsId +
                          '/' +
                          item.userid +
                          '?userId=' +
                          xPap.userId +
                          '&session=' +
                          xPap.session +
                          '&role=' +
                          xPap.role
                      }"
                      >{{ item.fullname }} ({{ item.userid }})</router-link
                    >
                  </td>
                </template>
                <template #button="{item}">
                  <td class="py-2 text-right">
                    <CButton
                      size="sm"
                      color="info"
                      variant="outline"
                      class="ml-1"
                      :to="{
                        path:
                          '/pap/messaging/publishers/' +
                          campaignsId +
                          '/' +
                          item.userid +
                          '?userId=' +
                          xPap.userId +
                          '&session=' +
                          xPap.session +
                          '&role=' +
                          xPap.role
                      }"
                    >
                      <i class="fa fa-eur" aria-hidden="true"></i>
                    </CButton>
                    <CButton
                      size="sm"
                      color="info"
                      class="ml-1"
                      :to="{
                        path:
                          '/pap/messaging/create' +
                          '?campaignId=' +
                          campaignsId +
                          '&affiliateId=' +
                          item.id +
                          '&userId=' +
                          xPap.userId +
                          '&session=' +
                          xPap.session +
                          '&role=' +
                          xPap.role
                      }"
                    >
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </CButton>
                  </td>
                </template>
              </CDataTable>
              <Pagination
                :totalCount="this.page.totalCount"
                :perPage="this.page.perPage"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Search from "@/components/ui/Search";
import moment from "moment";
import Pagination from "@/components/ui/Pagination";

export default {
  name: "MessagingPapPublishers",
  components: {
    Search,
    Pagination
  },
  data() {
    return {
      loading: false,
      reloading: false,
      forbidden: false,
      isSubmitting: false,
      xPap: {
        userId: null,
        session: null,
        role: null
      },
      campaignsId: null,
      items: [],
      //selectedCampaign: null,
      selectedCampaign: false,
      selectOptions: [],
      page: {
        totalCount: 0,
        perPage: 0
      }
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification",
      setLang: "auth/setLang"
    }),

    reloadPage() {
      window.location.reload();
    },

    dateFormat(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
    },

    /*
    createUrlLoad() {

      if(this.selectedCampaign !== null) {
        for (let key in this.$route.query) {
          if (key) {
            if (!key.includes("filter")) {
              this.$router.push({
                query: Object.assign({}, this.$route.query, {
                  page: 1,
                  "filter[and][][campaign_id]":  this.selectedCampaign,
                  userId: this.xPap.userId,
                  session: this.xPap.session,
                  role: this.xPap.role
                })
              }).catch(() => {});
            }
          }
        }
      }

    },*/

    createUrlXPap() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          userId: this.xPap.userId,
          session: this.xPap.session,
          role: this.xPap.role
        })
      });
    },

    pagination() {
      this.selectedCampaign = true;
      this.getItems();
      this.createUrlXPap();
    },

    sort() {
      this.selectedCampaign = true;
      this.getItems();
      this.createUrlXPap();
    },

    selectSearchOptions() {
      let self = this;

      crud
        .get("messaging/recipients/campaigns?per-page=100000", {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          let options = [];

          for (const value of response.data.items) {
            if (
              !options.some(options => options["id"] === value["campaign_id"])
            ) {
              options.push({
                id: value["campaign_id"],
                name: value["campaign_name"]
              });
            }
          }

          self.selectOptions = options;
          /*if(options.length > 0) {
            self.selectedCampaign = options[0]["id"];
            self.createUrlLoad();
          }*/
        });
    },

    getItems() {
      let self = this;
      this.loading = true;
      this.forbidden = false;
      this.reloading = true;
      this.campaignsId = null;

      let query = this.$refs.search.parseUrlQuery();

      if (self.$route.query["filter[and][][campaign_id]"]) {
        self.campaignsId = self.$route.query["filter[and][][campaign_id]"];
      }

      if (self.campaignsId !== null) {
        crud
          .get("messaging/affiliates" + query, {
            headers: {
              "X-Pap-UserId": this.xPap.userId,
              "X-Pap-SessionId": this.xPap.session,
              "X-Pap-Role": this.xPap.role
            }
          })
          .then(function(response) {
            self.items = [];
            for (const key of Object.values(response.data.items)) {
              self.items.push(key);
            }
            self.page.totalCount = Number(response.data._meta.totalCount);
            self.page.perPage = Number(response.data._meta.perPage);

            self.reloading = false;
            self.loading = false;
          })
          .catch(() => {
            self.forbidden = true;
            self.reloading = false;
            self.loading = false;
          });
      } else {
        self.items = [];
        self.reloading = false;
        self.loading = false;
      }
    }
  },
  created() {
    this.setLang();
  },
  mounted() {
    this.xPap.userId = this.$route.query.userId;
    this.xPap.session = this.$route.query.session;
    this.xPap.role = this.$route.query.role;
    this.selectSearchOptions();
  },
  computed: {
    fields: function() {
      return [
        {
          key: "fullname",
          label: i18n.t("system.affiliate"),
          sorter: false,
          _style: "min-width:230px;"
        },
        {
          key: "total_commissions",
          label: i18n.t("messaging.total_commissions"),
          sorter: false
        },
        {
          key: "sales_count",
          label: i18n.t("messaging.sales_count"),
          sorter: false
        },
        {
          key: "sales_total",
          label: i18n.t("messaging.total_cost"),
          sorter: false
        },
        {
          key: "avg_order_amount",
          label: i18n.t("messaging.average_value"),
          sorter: false
        },
        {
          key: "total_clicks",
          label: i18n.t("messaging.total_clicks"),
          sorter: false
        },
        {
          key: "scr",
          label: i18n.t("messaging.scr"),
          sorter: false
        },
        {
          key: "avg_commission_per_click",
          label: i18n.t("messaging.avg_commission_per_click"),
          sorter: false
        },
        {
          key: "button",
          label: "",
          _style: "min-width:100px;"
        }
      ];
    },
    search: function() {
      let rstatus = this.$store.state.enumerators.enumerators[
        "transaction-rstatus"
      ];
      let status = {};
      for (let i in rstatus) {
        status[i] = i18n.t("enum." + rstatus[i]);
      }

      let erange = this.$store.state.enumerators.enumerators[
        "gpf-filter-range"
      ];
      let range = [];
      for (let i in erange) {
        range.push({ id: i, name: i18n.t("enum." + i) });
      }

      return [
        {
          name: "campaign_id",
          options: this.selectOptions,
          label: i18n.t("integration-process.campaign-name"),
          type: "select2",
          operator: "and",
          like: false
        },
        {
          name: "date_range",
          options: range,
          label: i18n.t("messaging.range"),
          type: "select2",
          operator: "and",
          like: false
        },
        {
          name: "transaction_rstatus",
          label: i18n.t("system.status"),
          options: status,
          type: "checkbox-group",
          operator: "and"
        }
      ];
    }
  }
};
</script>
