<template>
  <div>
    <div
      v-for="(item, index) in commands"
      :key="index"
      class="btn-group"
      :class="{ 'border-right': borderRight(index) }"
    >
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary border-0 rounded-0"
        v-c-tooltip="item.title"
        @click="exec(item.command)"
      >
        <i :class="'fa ' + item.icon"></i>
      </button>
    </div>

    <div
      class="editor border mt-2"
      id="editor"
      contenteditable="true"
      @mouseup="getCurrentTagName"
    ></div>

    <div class="mt-3">
      <button class="btn btn-success mr-2" @click="save">
        <CSpinner color="info" size="sm" v-if="isSubmitting" />
        {{ buttonTitle }}
      </button>
      <button class="btn btn-outline-secondary rounded-0 border" @click="clear">
        {{ buttonClearTitle }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "wysiwyg",
  props: ["buttonTitle", "buttonClearTitle", "isSubmitting"],
  data() {
    return {
      commands: [
        { name: "Bold", title: "Bold", command: "bold", icon: "fa-bold" },

        {
          name: "Italic",
          title: "Italic",
          command: "italic",
          icon: "fa-italic"
        },

        {
          name: "StrikeThrough",
          title: "Strike Through",
          command: "strikeThrough",
          icon: "fa-strikethrough"
        },

        {
          name: "Underline",
          title: "Underline",
          command: "underline",
          icon: "fa-underline"
        },

        {
          name: "HorizontalLine",
          title: "Horizontal Line",
          command: "insertHorizontalRule",
          icon: "fa-minus"
        },

        {
          name: "AlignLeft",
          title: "Align Left",
          command: "justifyLeft",
          icon: "fa-align-left"
        },

        {
          name: "AlignJustify",
          title: "Align Justify",
          command: "justifyFull",
          icon: "fa-align-justify"
        },

        {
          name: "AlignCenter",
          title: "Align Center",
          command: "justifyCenter",
          icon: "fa-align-center"
        },

        {
          name: "AlignRight",
          title: "Align Right",
          command: "justifyRight",
          icon: "fa-align-right"
        },

        {
          name: "Subscript",
          title: "Subscript",
          command: "subscript",
          icon: "fa-subscript"
        },

        {
          name: "Superscript",
          title: "Superscript",
          command: "superscript",
          icon: "fa-superscript"
        },

        {
          name: "OrderedList",
          title: "Ordered List",
          command: "insertOrderedList",
          icon: "fa-list-ol"
        },

        {
          name: "UnorderdList",
          title: "Unorderd List",
          command: "insertUnorderedList",
          icon: "fa-list-ul"
        },

        {
          name: "RemoveFormat",
          title: "Remove Format",
          command: "removeFormat",
          icon: "fa-eraser"
        }
      ],

      currentTagName: ""
    };
  },

  watch: {
    currentTagName() {
      this.getCurrentTagName();
    }
  },

  methods: {
    exec(command, arg) {
      document.execCommand(command, false, arg);
    },

    save() {
      if (!this.isSubmitting) {
        this.$emit("save", document.getElementById("editor").innerHTML);
      }
    },

    clear() {
      document.getElementById("editor").innerHTML = "";
    },

    borderRight(index) {
      var clubs = [5, 9, 13, 15, 18, 20, 22, 24, 26];

      return !!clubs.includes(index + 1);
    },

    getCurrentTagName() {
      if (window.getSelection().baseNode) {
        this.currentTagName = window.getSelection().baseNode.parentNode.tagName;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.editor {
  min-height: 20em;

  padding: 1em;
}

.btn:hover {
  background: lighten(black, 90%);

  color: lighten(black, 10%);
}

[contenteditable]:focus {
  outline: none;
}

[contenteditable] {
  font-size: 120%;
}
</style>
