<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.integration-process") }}:
              <span v-if="viewItems.length > 0">{{ viewItems[1].value }}</span>
            </h4>
            <div class="small text-muted">{{ $t("crud.view") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButton
              color="secondary"
              square
              size="sm"
              to="/integration-process"
            >
              {{ $t("system.cancel") }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <br />
            <span v-if="viewItems.length > 0"
              >{{ viewItems[0].data }}: {{ viewItems[0].value }}</span
            ><br />
            <CBadge color="info mr-1"
              >{{ $t("system.total-count") }}:
              {{ counter.closed + counter.waiting + counter.open }}</CBadge
            >
            <CBadge color="success mr-1"
              >{{ $t("enum." + badgeStatus[3]) }}: {{ counter.closed }}</CBadge
            >
            <CBadge color="warning mr-1"
              >{{ $t("enum." + badgeStatus[2]) }}: {{ counter.waiting }}</CBadge
            >
            <CBadge color="danger"
              >{{ $t("enum." + badgeStatus[1]) }}: {{ counter.open }}</CBadge
            >
          </CCol>
          <CCol sm="6" class="text-right">
            <br />
            <p v-if="viewItems.length > 0">
              {{ viewItems[2].data }}: {{ viewItems[2].value }}
            </p>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CButton
          :to="'/integration-process/edit/' + this.$route.params.id"
          size="sm"
          color="success"
          >{{ $t("system.update") }}</CButton
        >
      </CCardFooter>
    </CCard>

    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 class="card-title mb-0">
              {{ $t("integration-process.steps") }}
            </h4>
          </CCol>
          <CCol sm="6" class="text-right pt-2"> </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <br />
            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable v-else :items="viewSteps" :fields="fieldsSteps" hover>
              <template #type="{item}">
                <td>
                  {{ $t("integration-process." + item.type) }}
                </td>
              </template>
              <template #status="{item}">
                <td>
                  <CBadge :color="getBadge(item.status)">
                    {{ $t("enum." + item.status) }}
                  </CBadge>
                </td>
              </template>
              <template #button="{item}">
                <td class="py-2 text-right">
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    variant="outline"
                    :to="{
                      path:
                        '/integration-process/view/' +
                        $route.params.id +
                        '/step/' +
                        item.type_id
                    }"
                  >
                    {{ $t("system.show") }}
                  </CButton>
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="{
                      path:
                        '/integration-process/view/' +
                        $route.params.id +
                        '/step/' +
                        item.type_id
                    }"
                  >
                    {{ $t("system.update") }}
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";
import moment from "moment";

const fieldsSteps = [
  {
    key: "type",
    label: i18n.t("integration-process.type")
  },
  {
    key: "status",
    label: i18n.t("system.status")
  },
  {
    key: "updated_at",
    label: i18n.t("system.updated_at")
  },
  {
    key: "button",
    label: ""
  }
];

export default {
  name: "Users",
  data() {
    return {
      loading: true,
      items: [],
      itemsSteps: [],
      fieldsSteps,
      status: [],
      selectLanguage: [],
      counter: {
        closed: 0,
        waiting: 0,
        open: 0
      },
      badgeStatus: {
        1: JSON.parse(localStorage.getItem("vuex")).enumerators.enumerators[
          "step-status"
        ][1],
        2: JSON.parse(localStorage.getItem("vuex")).enumerators.enumerators[
          "step-status"
        ][2],
        3: JSON.parse(localStorage.getItem("vuex")).enumerators.enumerators[
          "step-status"
        ][3]
      }
    };
  },
  methods: {
    getCampaignInfo() {
      let self = this;
      crud
        .get(
          "integration-process/campaigns/" +
            this.$route.params.id +
            "?expand=steps"
        )
        .then(function(response) {
          self.items = response.data;
          self.itemsSteps = response.data.steps;
          self.loading = false;
        });
    },
    getBadge(status) {
      switch (status) {
        case this.badgeStatus["3"]:
          return "success";
        case this.badgeStatus["2"]:
          return "warning";
        case this.badgeStatus["1"]:
          return "danger";
        default:
          return "primary";
      }
    }
  },
  computed: {
    viewItems: function() {
      let obj = [];

      Object.keys(this.items).forEach(key => {
        let item = this.items[key];
        let show = false;

        switch (key) {
          case "campaign_id":
            key = i18n.t("integration-process.campaign-id");
            show = true;
            break;
          case "campaign_name":
            key = i18n.t("integration-process.campaign-name");
            show = true;
            break;
          case "created_at":
            key = i18n.t("system.created_at");
            item = moment(item).format("DD.MM.YYYY HH:mm");
            break;
          case "updated_at":
            key = i18n.t("system.updated_at");
            item = moment(item).format("DD.MM.YYYY HH:mm");
            show = true;
            break;
        }

        if (show === true) {
          obj.push({ data: key, value: item });
        }
      });

      return obj;
    },
    viewSteps: function() {
      let self = this;
      let obj = [];
      let count = [0, 0, 0];

      Object.keys(this.itemsSteps).forEach(key => {
        let item = this.itemsSteps[key];

        switch (item.status) {
          case 1:
            count[0] += 1;
            //this.counter.open += 1;
            break;
          case 2:
            count[1] += 1;
            //this.counter.waiting += 1;
            break;
          case 3:
            count[2] += 1;
            //this.counter.closed += 1;
            break;
        }

        obj.push({
          type: JSON.parse(localStorage.getItem("vuex")).enumerators
            .enumerators["step-type"][item.type],
          type_id: item.type,
          status: JSON.parse(localStorage.getItem("vuex")).enumerators
            .enumerators["step-status"][item.status],
          updated_at: moment(item.updated_at).format("DD.MM.YYYY HH:mm")
        });
      });

      self.counter.open = count[0];
      self.counter.waiting = count[1];
      self.counter.closed = count[2];

      return obj;
    }
  },
  created() {
    this.getCampaignInfo();
  }
};
</script>

<style></style>
