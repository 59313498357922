<template>
  <div>
    <div v-if="loading">
      <CSpinner color="info" class="loading" />
    </div>
    <div v-else>
      <ProductServiceTopMenu />
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader class="text-right">
              <CButton
                color="success"
                :to="
                  'product-service/create?userId=' +
                    this.$route.query.userId +
                    '&session=' +
                    this.$route.query.session +
                    '&role=' +
                    this.$route.query.role
                "
              >
                {{ $t("product-service.create-feed") }}
              </CButton>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CSpinner color="info" class="loading" v-if="loading" />
                  <CDataTable v-else :items="items" :fields="fields" hover>
                    <template #url="{item, index}">
                      <td>
                        <div class="form-inline">
                          <input
                            class="form-control"
                            :ref="'xmlUrl' + index"
                            :value="item.effective_url"
                          />
                          <CButton
                            v-on:click="copyText($refs['xmlUrl' + index])"
                            size="sm"
                            class="ml-2"
                            color="info"
                            variant="outline"
                            >{{ $t("system.copy") }} URL</CButton
                          >
                        </div>
                      </td>
                    </template>
                    <template #type="{item}">
                      <td>
                        <CBadge color="info">
                          {{
                            $store.state.enumerators.enumerators["feed-type"][
                              item.settings.type
                            ]
                          }}
                        </CBadge>
                      </td>
                    </template>
                    <template #format="{item}">
                      <td>
                        <CBadge color="default">
                          {{
                            $store.state.enumerators.enumerators["feed-format"][
                              item.settings.format
                            ]
                          }}
                        </CBadge>
                      </td>
                    </template>
                    <template #language="{item}">
                      <td>
                        <CBadge color="default">
                          {{
                            $store.state.enumerators.enumerators["language"][
                              item.settings.language
                            ]
                          }}
                          <small>{{ item.settings.language }}</small>
                        </CBadge>
                      </td>
                    </template>
                    <template #is_secret="{item}">
                      <td>
                        <CBadge color="success" v-if="item.is_secret === 1">
                          <CIcon name="cil-check" />
                        </CBadge>
                        <CBadge color="danger" v-else>
                          <CIcon name="cil-XCircle" />
                        </CBadge>
                      </td>
                    </template>
                    <template #is_in_product_service="{item}">
                      <td>
                        <CBadge
                          color="success"
                          v-if="item.is_in_product_service === 1"
                        >
                          <CIcon name="cil-check" />
                        </CBadge>
                        <CBadge color="danger" v-else>
                          <CIcon name="cil-XCircle" />
                        </CBadge>
                      </td>
                    </template>
                    <template #last_generated_at="{item}">
                      <td>
                        <span v-if="!item.last_generated_at"
                          >{{ $t("product-service.generating") }} ...
                          <CSpinner color="info" size="sm"
                        /></span>
                        <span v-else>{{
                          dateFormat(item.last_generated_at)
                        }}</span>
                      </td>
                    </template>
                    <template #updated_at="{item}">
                      <td>
                        {{ dateFormat(item.updated_at) }}
                      </td>
                    </template>
                    <template #button="{item, index}">
                      <td class="py-2 text-right">
                        <CButton
                          size="sm"
                          color="info"
                          variant="outline"
                          class="ml-1"
                          :to="{
                            path:
                              '/pap/product-service/view/' +
                              item.id +
                              '?userId=' +
                              xPap.userId +
                              '&session=' +
                              xPap.session
                          }"
                        >
                          {{ $t("system.show") }}
                        </CButton>
                        <CButton
                          size="sm"
                          color="info"
                          class="ml-1"
                          :to="{
                            path:
                              '/pap/product-service/edit/' +
                              item.id +
                              '?userId=' +
                              xPap.userId +
                              '&session=' +
                              xPap.session
                          }"
                        >
                          {{ $t("system.update") }}
                        </CButton>
                        <!--CButton
                          size="sm"
                          color="warning"
                          class="ml-1"
                          :to="{
                            path:
                              '/pap/product-service/duplicate/' +
                              item.id +
                              '?userId=' +
                              xPap.userId +
                              '&session=' +
                              xPap.session
                          }"
                        >
                          {{ $t("system.duplicate") }}
                        </CButton-->
                        <CForm class="d-inline">
                          <CButton
                            size="sm"
                            color="danger"
                            class="ml-1"
                            v-on:click="deleteElement(item.id, index)"
                          >
                            {{ $t("system.delete") }}
                          </CButton>
                        </CForm>
                      </td>
                    </template>
                  </CDataTable>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import ProductServiceTopMenu from "@/components/product_service/TopMenu";
import i18n from "@/i18n";
import moment from "moment";
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";

const fields = [
  {
    key: "id",
    label: "ID"
  },
  {
    key: "name",
    label: i18n.t("system.name")
  },
  {
    key: "product_count",
    label: i18n.t("product-service.products-count")
  },
  {
    key: "download_hits",
    label: i18n.t("product-service.download-hits")
  },
  {
    key: "url",
    label: "Url",
    sorter: false,
    filter: false
  },
  {
    key: "type",
    label: i18n.t("integration-process.type")
  },
  {
    key: "format",
    label: i18n.t("xml-manager.format")
  },
  {
    key: "language",
    label: i18n.t("system.language")
  },
  {
    key: "last_generated_at",
    label: i18n.t("product-service.generated_at")
  },
  {
    key: "button",
    label: "",
    _style: "min-width:200px;"
  }
];

export default {
  name: "ProductServicePapIndex",
  components: {
    ProductServiceTopMenu
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      loading: false,
      xPap: {
        userId: null,
        session: null,
        role: null
      },
      items: [],
      fields,
      page: {
        totalCount: 0,
        perPage: 0
      }
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    copyText(ref) {
      let textToCopy = ref;
      textToCopy.select();
      document.execCommand("copy");
    },

    dateFormat(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
    },

    getItems() {
      this.loading = true;
      let self = this;

      crud
        .get("product-service/user-feeds", {
          headers: {
            "X-Pap-UserId": this.xPap.userId,
            "X-Pap-SessionId": this.xPap.session,
            "X-Pap-Role": this.xPap.role
          }
        })
        .then(function(response) {
          self.items = [];
          for (const key of Object.values(response.data.items)) {
            self.items.push(key);
          }
          self.page.totalCount = response.data._meta.totalCount;
          self.page.perPage = response.data._meta.perPage;

          self.loading = false;
        });
    },

    deleteElement(id, index) {
      if (confirm(i18n.t("confirmation.delete"))) {
        let self = this;
        crud
          .delete("product-service/user-feeds/" + id, {
            headers: {
              "X-Pap-UserId": this.xPap.userId,
              "X-Pap-SessionId": this.xPap.session,
              "X-Pap-Role": this.xPap.role
            }
          })
          .then(function(response) {
            if (response.status === 204) {
              self.items.splice(index, 1);
              self.page.totalCount -= 1;

              self.addNotification({
                type: "success",
                message: i18n.t("success.record-deleted")
              });
            }
          })
          .catch(error => {
            self.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  mounted() {
    //window.addEventListener("load", () => {
    this.xPap.userId = this.$route.query.userId;
    this.xPap.session = this.$route.query.session;
    this.xPap.role = this.$route.query.role;

    this.getItems();

    //this.loading = false;
    //});
  }
};
</script>
<style>
.top-menu.nav .nav-item a {
  font-weight: bold;
  color: #ccc;
}
.top-menu.nav .nav-item.active a {
  color: #464646;
}
</style>
