<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-6">
        <div class="card card-default">
          <div class="card-body">
            <h1>{{ $t("login.reset-password") }}</h1>
            <hr />
            <form autocomplete="off" @submit.prevent="submit" method="post">
              <div class="form-group">
                <label for="username">{{ $t("login.username") }}</label>
                <input
                  type="text"
                  id="username"
                  class="form-control"
                  v-model="form.username"
                  required
                />
              </div>
              <button type="submit" class="btn btn-info btn-square">
                {{ $t("login.reset-password-link") }}
              </button>

              <router-link
                :to="{
                  name: 'login'
                }"
                class="btn btn-light ml-1"
                >{{ $t("system.cancel") }}</router-link
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "forgot-password",
  components: {
    //
  },

  data() {
    return {
      form: {
        username: null,
        has_error: false
      }
    };
  },

  methods: {
    ...mapActions({
      forgotPassword: "auth/forgotPassword"
    }),

    submit() {
      this.forgotPassword(this.form)
        .then(() => {
          this.$router.replace({
            name: "login"
          });
        })
        .catch(() => {
          //
        });
    }
  }
};
</script>
