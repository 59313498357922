import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// Containers
import TheContainer from "@/containers/TheContainer";

import Login from "./login.js";
import Users from "./users.js";
import Roles from "./roles.js";
import IntegrationProcess from "./integration_process.js";
import XmlManager from "./xml_manager.js";
import ProductService from "./product_service.js";
import Messaging from "./messaging.js";

let papRoutes = [];
let adminRoutes = [];

//iframe pap - routes
papRoutes = papRoutes.concat(Login[0].data.pap); //login
papRoutes = papRoutes.concat(IntegrationProcess[0].data.pap); //Integration Process
papRoutes = papRoutes.concat(XmlManager[0].data.pap); //Xml Manager
papRoutes = papRoutes.concat(ProductService[0].data.pap); //Product Service
papRoutes = papRoutes.concat(Messaging[0].data.pap); //Messaging

//admin - routes
adminRoutes = adminRoutes.concat(Login[0].data.admin); //dashboard
adminRoutes = adminRoutes.concat(Users[0].data.admin); //Users
adminRoutes = adminRoutes.concat(Roles[0].data.admin); //Roles
adminRoutes = adminRoutes.concat(IntegrationProcess[0].data.admin); //Integration Process
adminRoutes = adminRoutes.concat(XmlManager[0].data.admin); //Xml Manager
adminRoutes = adminRoutes.concat(Messaging[0].data.admin); //Messaging

const merge = [
  {
    path: "/",
    redirect: "dashboard",
    name: "home",
    component: TheContainer,
    meta: {
      requiresAuth: true
    },
    children: adminRoutes
  }
];

Vue.use(VueRouter);

const routes = papRoutes.concat(merge);

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (!store.getters["auth/authenticated"]) {
      next({
        path: "login"
        // query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    if (store.getters["auth/authenticated"] && !to.meta.nonRedirect) {
      next({
        path: "dashboard"
      });
    }
    next(); // make sure to always call next()!
  }
});

export default router;
