<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="12" md="6">
            <CSpinner v-if="userCount === false"></CSpinner>
            <CCallout v-if="userCount" color="info">
              <small class="text-muted">{{ $t("menu.users") }}</small
              ><br />
              <strong class="h4">{{ userCountFormatted }}</strong>
            </CCallout>
          </CCol>
          <CCol sm="12" md="6">
            <CSpinner v-if="campaignCount === false"></CSpinner>
            <CCallout v-if="campaignCount" color="info">
              <small class="text-muted">PAP {{ $t("menu.campaigns") }}</small
              ><br />
              <strong class="h4">{{ campaignCountFormatted }}</strong>
            </CCallout>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";

export default {
  name: "StatsWidget",
  props: {},
  data() {
    return {
      userCount: false,
      campaignCount: false
    };
  },
  methods: {
    /**
     * Gets a total count of items from given endpoint using HEAD
     *
     * @param {String} endpoint
     * @param {String} dataProperty
     * @return {Promise}
     */
    getTotalCount: function(endpoint, dataProperty) {
      let that = this;

      axios
        .head(endpoint, {
          headers: {
            "X-Api-Key": store.state.auth.token
          }
        })
        .then(function(response) {
          that[dataProperty] = response.headers["x-pagination-total-count"];
        })
        .catch(function() {});
    },

    /**
     * Formats a number using Intl.NumberFormat
     *
     * @param {Number} number
     * @param {String} style
     * @param {Object} options
     * @returns {String}
     */
    formatNumber: function(number, style = "decimal", options) {
      let defaultOptions = {
        style: style
      };
      let mergedOptions = Object.assign(defaultOptions, options);

      let formatter = new Intl.NumberFormat(
        process.env.VUE_APP_I18N_LOCALE,
        mergedOptions
      );

      return formatter.format(number);
    }
  },
  computed: {
    userCountFormatted: function() {
      return this.formatNumber(this.userCount, "decimal");
    },
    campaignCountFormatted: function() {
      return this.formatNumber(this.campaignCount, "decimal");
    }
  },
  created() {
    this.getTotalCount("/users", "userCount");
    this.getTotalCount("pap/campaigns", "campaignCount");
  }
};
</script>

<style></style>
