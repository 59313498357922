<template>
  <div>
    <header v-if="!xPap.userId">
      <CRow class="bg-dark-top">
        <CCol sm="12" class="text-right">
          <div class="social-wrapper">
            <ul>
              <li class="contact">
                <a href="https://www.dognet.sk/o-nas/kontakt/" target="_blank"
                  >Contact</a
                >
              </li>
              <li class="social--facebook">
                <a
                  href="https://www.facebook.com/dognet.network/"
                  target="_blank"
                  ><CIcon name="cib-facebook"
                /></a>
              </li>
              <li class="social--twitter">
                <a href="https://twitter.com/dognet_sk" target="_blank"
                  ><CIcon name="cib-twitter"
                /></a>
              </li>
              <li class="social--linkedin">
                <a
                  href="https://www.linkedin.com/company/dognet-sk"
                  target="_blank"
                  ><CIcon name="cib-linkedin"
                /></a>
              </li>
              <li class="social--instagram">
                <a
                  href="https://www.instagram.com/dognetaffiliatenetwork/"
                  target="_blank"
                  ><CIcon name="cib-instagram"
                /></a>
              </li>
            </ul>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" class="title">
          <a
            href="https://www.dognet.sk/o-nas/kontakt/"
            title="Dognet.sk"
            class="logo"
          >
            <img :src="publicPath + 'images/dognet-white.svg'" />
          </a>
        </CCol>
      </CRow>
    </header>
    <div v-if="loading">
      <CSpinner color="info" class="loading" />
    </div>
    <CCol v-else>
      <div class="container">
        <CRow>
          <CCol sm="12" class="title">
            <h1>XML Feed tester</h1>

            <div class="skew">
              <div class="content-wrapper">
                <CRow class="p-5">
                  <CCol sm="2">
                    <div id="move" v-if="svgMove">
                      <object
                        type="image/svg+xml"
                        :data="publicPath + 'svg/dognet_rocket_move.svg'"
                      ></object>
                    </div>
                    <object
                      type="image/svg+xml"
                      :data="publicPath + 'svg/dognet_rocket.svg'"
                    ></object>
                  </CCol>
                  <CCol sm="10">
                    <h4 class="mb-5">{{ $t("xml-manager.validate-url") }}</h4>

                    <CForm>
                      <div v-if="!submitted">
                        <CRow
                          class="form-group"
                          v-for="(input, k) in form.emails"
                          :key="k"
                        >
                          <CCol lg="5">
                            <label :class="{ 'text-danger': error.email }"
                              >E-mail:<br />
                              <small
                                >{{ $t("xml-manager.email-sub-text") }}.</small
                              >

                              <CBadge v-if="error.email" color="danger">{{
                                $t("system.is-required")
                              }}</CBadge>
                            </label>
                            <CInput
                              name="email"
                              type="email"
                              v-model="input.email"
                            />
                          </CCol>
                          <CCol lg="7" class="mt-4">
                            <span
                              class="circle"
                              @click="addInput(k)"
                              v-show="k == form.emails.length - 1"
                            >
                              <CIcon name="cil-plus" />
                            </span>
                            <span
                              class="circle"
                              @click="removeInput(k)"
                              v-show="k || (!k && form.emails.length > 1)"
                            >
                              <CIcon name="cil-trash" />
                            </span>
                          </CCol>
                        </CRow>

                        <label :class="{ 'text-danger': error.url }">
                          {{ $t("xml-manager.paste-url") }}:
                          <CBadge v-if="error.url" color="danger">{{
                            $t("system.is-required")
                          }}</CBadge>
                        </label>
                        <CInput
                          id="url"
                          name="url"
                          required="true"
                          type="url"
                          v-model="form.url"
                        />

                        <!--
                        <CInput
                          id="url"
                          name="url"
                          required="true"
                          type="url"
                          v-model="form.url"
                          v-on:change="parseUrl"
                        />


                        <CRow>
                          <CCol lg="3" ref="blinkType">
                            <label :class="{ 'text-danger': error.type }">
                              {{ $t("integration-process.type") }}:

                              <CBadge v-if="error.type" color="danger">{{
                                $t("system.is-required")
                              }}</CBadge>

                              <CBadge color="success" v-if="autoSet.type">{{
                                $t("xml-manager.auto-set")
                              }}</CBadge>
                            </label>
                            <CSelect
                              id="type"
                              name="type"
                              :options="
                                $options.filters.objectToSelectBox(feedType)
                              "
                              :value.sync="form.type"
                            />
                          </CCol>
                          <CCol lg="3" ref="blinkFormat">
                            <label :class="{ 'text-danger': error.format }">
                              {{ $t("xml-manager.format") }}:

                              <CBadge v-if="error.format" color="danger">{{
                                $t("system.is-required")
                              }}</CBadge>

                              <CBadge color="success" v-if="autoSet.format">{{
                                $t("xml-manager.auto-set")
                              }}</CBadge>
                            </label>
                            <CSelect
                              id="format"
                              name="format"
                              :options="
                                $options.filters.objectToSelectBox(feedFormat)
                              "
                              :value.sync="form.format"
                            />
                          </CCol>
                        </CRow>
                        -->

                        <CAlert
                          color="danger"
                          v-if="error.email || error.url || error.message"
                        >
                          <span v-if="error.message">{{ error.message }}</span>
                          <span v-else>{{ $t("error.fill-form") }}.</span>
                        </CAlert>

                        <br />
                        <invisible-recaptcha
                          sitekey="6LcVIj4aAAAAAGeM0kQMjA3Qw_TWUiN1H7RzoTcQ"
                          :validate="prepare"
                          :callback="validated"
                          class="btn"
                          type="submit"
                          id="submit-btn"
                          :disabled="isSubmitting"
                        >
                          {{ $t("xml-manager.start-test") }}
                        </invisible-recaptcha>
                        <CSpinner
                          color="grey"
                          size="sm"
                          class="ml-2"
                          v-if="isSubmitting"
                        />
                      </div>
                    </CForm>
                    <div v-if="submitted">
                      <CAlert color="success">
                        {{ $t("xml-manager.submit-test") }}.
                      </CAlert>
                      <a
                        href="javascript:window.location.reload(true)"
                        class="btn"
                      >
                        {{ $t("xml-manager.submit-test-new") }}
                      </a>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </div>
          </CCol>
        </CRow>
      </div>

      <footer v-if="!xPap.userId">
        <p>Join Us on Our Social Networks</p>
        <nav class="social--wrapper social--circle">
          <ul>
            <li class="social--facebook">
              <a href="https://www.facebook.com/dognet.network/" target="_blank"
                ><CIcon name="cib-facebook"
              /></a>
            </li>
            <li class="social--twitter">
              <a href="https://twitter.com/dognet_sk" target="_blank"
                ><CIcon name="cib-twitter"
              /></a>
            </li>
            <li class="social--linkedin">
              <a
                href="https://www.linkedin.com/company/dognet-sk"
                target="_blank"
                ><CIcon name="cib-linkedin"
              /></a>
            </li>
            <li class="social--instagram">
              <a
                href="https://www.instagram.com/dognetaffiliatenetwork/"
                target="_blank"
                ><CIcon name="cib-instagram"
              /></a>
            </li>
          </ul>
        </nav>
        <p class="copy">
          <a href="https://www.dognet.sk/o-nas/kontakt/" title="Dognet.sk">
            © Dognet.sk
          </a>
        </p>
        <hr />
        <p class="recaptcha-text">
          You are allowed to hide the badge as long as you include the reCAPTCHA
          branding visibly in the user flow. Please include the following text:
          <br />
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy" target="_blank"
            >Privacy Policy</a
          >
          and
          <a href="https://policies.google.com/terms" target="_blank"
            >Terms of Service</a
          >
          apply.
        </p>
      </footer>
    </CCol>
  </div>
</template>

<script>
//import crud from "@/store/modules/crud";
//import i18n from "@/i18n";
import InvisibleRecaptcha from "vue-invisible-recaptcha";
import crud from "@/store/modules/crud";

export default {
  name: "XmlManagerTestFeedIndex",
  components: {
    "invisible-recaptcha": InvisibleRecaptcha
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      loading: false,
      isSubmitting: false,
      submitted: false,
      svgMove: false,
      error: {
        email: false,
        url: false,
        type: false,
        format: false,
        message: null
      },
      xPap: {
        userId: null,
        session: null,
        role: null
      },
      form: {
        emails: [
          {
            email: ""
          }
        ],
        url: "",
        type: "1",
        format: "1"
      },
      autoSet: {
        type: false,
        format: false
      }
    };
  },
  methods: {
    /*
    parseUrl() {
      let url = this.form.url;

      if (url) {
        let urlSettings = this.$options.filters.feedParser(
          url,
          this.feedType,
          this.feedFormat,
          this.$store.state.enumerators.enumerators["language"]
        );

        if (urlSettings[0] !== null) {
          this.form.type = urlSettings[0];
          this.blink("type");
        }

        if (urlSettings[1] !== null) {
          this.form.format = urlSettings[1];
          this.blink("format");
        }
      }
    },
    */

    addInput() {
      this.form.emails.push({
        email: ""
      });
    },

    removeInput(index) {
      this.form.emails.splice(index, 1);
    },

    /*
    blink(el) {
      let ref;
      switch (el) {
        case "type":
          ref = this.$refs["blinkType"];
          this.autoSet.type = true;
          break;
        case "format":
          ref = this.$refs["blinkFormat"];
          this.autoSet.format = true;
          break;
      }
      ref.style.background = "#9aff98";
      ref.style.transition = "all 0.5s";
      setTimeout(() => {
        ref.style.background = "transparent";
        this.autoSet.type = false;
        this.autoSet.format = false;
        this.autoSet.lang = false;
      }, 1500);
    },
    */

    prepare() {
      this.isSubmitting = true;

      this.error.email = false;
      this.error.url = false;
      this.error.type = false;
      this.error.format = false;

      let email = [];
      let re = /\S+@\S+\.\S+/;
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );

      for (const [key] of Object.entries(this.form.emails)) {
        if (this.form.emails[key].email && re.test(this.form.emails[key].email))
          email.push(true);
      }

      if (email.length !== this.form.emails.length) this.error.email = true;
      if (!this.form.url && !pattern.test(this.form.url)) this.error.url = true;
      if (this.form.type === "0") this.error.type = true;
      if (this.form.format === "0") this.error.format = true;

      this.isSubmitting = false;
    },

    validated() {
      this.isSubmitting = true;
      if (
        !this.error.email &&
        !this.error.url &&
        !this.error.type &&
        !this.error.format
      )
        this.submit();
      this.isSubmitting = false;
    },

    submit() {
      this.isSubmitting = true;

      let data = new FormData();

      data.append("url", this.form.url);
      data.append("type", this.form.type);
      data.append("format", this.form.format);

      for (const [key] of Object.entries(this.form.emails)) {
        if (this.form.emails[key].email)
          data.append("recipients[]", this.form.emails[key].email);
      }

      crud
        .post("xml-manager/test-feeds", data)
        .then(() => {
          this.isSubmitting = false;
          this.svgMove = true;
          this.submitted = true;
        })
        .catch(error => {
          this.isSubmitting = false;
          this.error.message = error;
        });

      this.isSubmitting = false;
    }
  },
  computed: {
    feedType() {
      return Object.assign(
        { 0: "-" },
        this.$store.state.enumerators.enumerators["feed-type"]
      );
    },
    feedFormat() {
      return Object.assign(
        { 0: "-" },
        this.$store.state.enumerators.enumerators["feed-format"]
      );
    }
  },
  mounted() {
    window.addEventListener("load", () => {
      this.xPap.userId = this.$route.query.userId;
      this.xPap.session = this.$route.query.session;
      this.xPap.role = this.$route.query.role;

      this.loading = false;
    });
    this.loading = false;
  }
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700&amp;subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Khand:400,700&amp;subset=latin-ext");
header .bg-dark-top {
  background: #0c191b;
  height: 50px;
}
.social-wrapper ul {
  margin: 0;
  padding: 0;
}
.social-wrapper ul li {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-wrapper ul li a {
  color: #fff;
  height: 50px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
}
.social-wrapper ul li a:hover {
  color: #00a0b8;
  background: #263a43;
  text-decoration: none;
}
.social-wrapper ul li.contact a {
  font-family: "Noto Sans", sans-serif;
  color: #00a0b8;
  text-transform: uppercase;
  font-weight: bold;
}
header .logo img {
  padding: 13px 0 10px 20px;
}
header .flags a {
  padding: 8px 5px 11px 5px;
  display: inline-block;
}
header .flags a:hover {
  background: #0c3d3f;
}
header .flags a img {
  max-width: 16px;
}
header .title {
  background: #00a0b8;
}
.title h1 {
  font-family: "Noto Sans", sans-serif;
  color: #00a0b8;
  font-weight: normal;
  text-align: center;
  font-size: 28px;
  margin-top: 40px;
}
.skew {
  padding: 80px 0;
  background-color: #ebedef;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 60px;
}
.skew:after {
  width: 100%;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 10%;
  -webkit-transform: skewY(-4deg);
  -moz-transform: skewY(-4deg);
  -ms-transform: skewY(-4deg);
  -o-transform: skewY(-4deg);
  transform: skewY(-4deg);
  background-color: #fff;
  z-index: 2;
}
.skew .content-wrapper {
  position: relative;
  z-index: 3;
}
.skew .content-wrapper #move {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding-left: 15px;
  padding-right: 15px;
}
footer {
  text-align: center;
}
footer p {
  color: #00a0b8;
  font-family: "Khand", sans-serif;
  font-size: 28px;
}
footer p.copy {
  color: #676868;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  padding-top: 80px;
}
footer p.copy a {
  color: #676868;
}
footer .social--wrapper ul {
  list-style: none;
  margin: 30px 0 0 0;
  padding: 0;
  display: block;
}
footer .social--wrapper li {
  display: inline-block;
  padding: 0 10px;
  margin: 0;
}
footer .social--wrapper li a {
  color: #3b4d53;
  padding: 13px 15px;
  background: #fff;
  border-radius: 30px;
}
footer .social--wrapper li a:hover {
  background: #00a0b8;
  color: #fff;
}
footer .social--wrapper li a svg {
  margin-top: -3px;
}
.btn {
  background: #00a0b8;
  border-radius: 30px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 30px;
  font-weight: bold;
}
.circle {
  border-radius: 15px;
  background: #00a0b8;
  color: #fff;
  cursor: pointer;
  padding: 3px 10px 3px 10px;
  line-height: 8px;
  display: inline-block;
  margin-top: 32px;
}
.circle:hover {
  background: #0c3d3f;
}
.recaptcha-text {
  color: #b4b4b4;
  font-family: "Noto Sans", sans-serif;
  font-size: 10px;
}
.recaptcha-text a {
  color: #b4b4b4;
  font-family: "Noto Sans", sans-serif;
  font-size: 10px;
  text-decoration: underline;
}
.recaptcha-text a:hover {
  color: #00a0b8;
}
</style>
<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
