<template>
  <div>
    <CToaster position="bottom-right">
      <CToast
        :key="'toast'"
        :show="true"
        :class="notification.type"
        :header="headerText"
      >
        {{ notification.message }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import i18n from "@/i18n";

export default {
  props: ["notification"],
  data() {
    return {
      headerText: null
    };
  },
  methods: {
    getHeaderText(text) {
      if (text === "success") {
        this.headerText = i18n.t("success.success");
      } else if (text === "danger") {
        this.headerText = i18n.t("error.danger");
      }
    }
  },
  created: function() {
    this.getHeaderText(this.notification.type);
  }
};
</script>

<style lang="scss">
.toast {
  border-radius: 0 !important;
  &.success {
    .toast-header {
      background: #2eb85c;
      color: #fff;
      border-radius: 0;
    }
    .toast-body {
      background: rgba(46, 184, 92, 0.5);
      color: #000;
    }
  }
  &.danger,
  &.error {
    .toast-header {
      background: #e55353;
      color: #fff;
    }
    .toast-body {
      background: rgba(229, 83, 83, 0.5);
      color: #000;
    }
  }
}
</style>
