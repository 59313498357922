<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("menu.users") }}
            </h4>
            <div class="small text-muted">{{ $t("crud.list") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButton color="success" square size="sm" to="/users/create">
              +
              {{
                $t("system.add") +
                  " " +
                  $t("system.new") +
                  " " +
                  $t("users.user")
              }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <Search ref="search" v-bind:columns="this.search" />
          </CCol>
          <CCol sm="12">
            <Sort ref="sort" />
            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable
              v-else
              :items="items"
              :fields="fields"
              :sorter="{ external: true, resetable: false }"
              hover
              @update:sorter-value="$refs.sort.sortTable"
            >
              <template #status="{item}">
                <td>
                  <CBadge :color="getBadge(item.status)">
                    {{
                      $t(
                        "enum." +
                          $store.state.enumerators.enumerators["user-status"][
                            item.status
                          ]
                      )
                    }}
                  </CBadge>
                </td>
              </template>
              <template #button="{item, index}">
                <td class="py-2 text-right">
                  <CButton
                    size="sm"
                    color="info"
                    variant="outline"
                    class="ml-1"
                    :to="{ path: '/users/view/' + item.id }"
                  >
                    {{ $t("system.show") }}
                  </CButton>
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    :to="{ path: '/users/edit/' + item.id }"
                  >
                    {{ $t("system.update") }}
                  </CButton>
                  <CForm
                    @submit.prevent="submit"
                    class="d-inline"
                    v-if="$store.state.auth.user"
                  >
                    <CButton
                      size="sm"
                      color="secondary"
                      class="ml-1"
                      disabled="disabled"
                      v-if="
                        Number($store.state.auth.user.id) === Number(item.id)
                      "
                    >
                      {{ $t("system.delete") }}
                    </CButton>
                    <CButton
                      size="sm"
                      color="danger"
                      class="ml-1"
                      v-on:click="deleteElement(item.id, index)"
                      v-else
                    >
                      {{ $t("system.delete") }}
                    </CButton>
                  </CForm>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
        <Pagination
          :totalCount="this.page.totalCount"
          :perPage="this.page.perPage"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Pagination from "@/components/ui/Pagination";
import Search from "@/components/ui/Search";
import Sort from "@/components/ui/Sort";

const fields = [
  {
    key: "username",
    label: i18n.t("login.username"),
    _style: "min-width:200px"
  },
  "email",
  {
    key: "status",
    label: i18n.t("system.status"),
    _style: "min-width:100px;"
  },
  {
    key: "button",
    label: ""
  }
];

export default {
  name: "Users",
  components: {
    Pagination,
    Search,
    Sort
  },
  data() {
    return {
      loading: true,
      items: [],
      fields,
      enumerators: {
        userStatus: []
      },
      page: {
        totalCount: 0,
        perPage: 0
      },
      search: [
        {
          name: "username",
          label: i18n.t("login.username"),
          type: "text",
          operator: "or",
          like: true
        },
        {
          name: "email",
          label: "E-mail",
          type: "text",
          operator: "or",
          like: true
        },
        {
          name: "status",
          label: i18n.t("system.status"),
          type: "select",
          operator: "or",
          like: false,
          enum: "user-status"
        }
      ]
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    pagination() {
      this.getUsers();
    },

    getUsers() {
      var self = this;

      let query = this.$refs.search.parseUrlQuery();

      crud.get("users" + query).then(function(response) {
        //get data with deleted or without
        let filter = self.$options.filters.parseUrlQueryFilter(
          self.$route.query
        );

        if (!filter) {
          self.items = response.data.items.filter(function(e) {
            return e.status !== 0;
          });
        } else {
          self.items = response.data.items;
        }

        self.page.totalCount = response.data._meta.totalCount;
        self.page.perPage = response.data._meta.perPage;

        self.loading = false;
      });
    },

    getBadge(status) {
      switch (status) {
        case 9:
          return "warning";
        case 0:
          return "danger";
        default:
          return "info";
      }
    },

    deleteElement(id, index) {
      if (confirm(i18n.t("confirmation.delete"))) {
        let self = this;
        crud
          .delete("users/" + id)
          .then(function(response) {
            if (response.status === 204) {
              self.items.splice(index, 1);
              self.page.totalCount -= 1;

              self.addNotification({
                type: "success",
                message: i18n.t("success.record-deleted")
              });
            }
          })
          .catch(error => {
            self.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>

<style></style>
