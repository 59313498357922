<template>
  <div>
    <div v-if="forbidden">
      <CAlert color="warning" closeButton v-html="$t('messaging.forbidden')">
      </CAlert>
    </div>
    <div v-else>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="12">
                  <h4 id="traffic" class="card-title mb-0">
                    {{ $t("menu.messaging") }}
                  </h4>
                  <div class="small text-muted">
                    {{ $t("messaging.perex") }}
                  </div>
                </CCol>
              </CRow>
            </CCardHeader>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="6" class="mb-1">
                  <span class="skeleton-loader" v-if="loading"></span>
                  <h4 class="card-title mb-0" v-else>
                    {{ item.campaignName
                    }}<small>({{ item.campaignId }})</small> /
                    {{ item.affiliateName }}
                    <small>({{ item.affiliateId }})</small>
                  </h4>
                </CCol>
                <CCol sm="6" class="mb-1 text-right">
                  <AutoRefresh :refresh="60" @reload="getItem" />
                  <CButton
                    size="sm"
                    color="secondary"
                    class="ml-1"
                    :to="
                      '/pap/messaging?userId=' +
                        this.$route.query.userId +
                        '&session=' +
                        this.$route.query.session +
                        '&role=' +
                        this.$route.query.role
                    "
                  >
                    {{ $t("system.close") }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="6">
                  <CButton
                    @click="collapse = !collapse"
                    color="info"
                    class="mb-2"
                  >
                    <template v-if="!collapse">{{
                      $t("messaging.reply")
                    }}</template>
                    <template v-else>{{ $t("system.close") }}</template>
                  </CButton>
                  <CCollapse :show="collapse">
                    <hr />
                    <WysiwygEditor
                      ref="editor"
                      :button-title="$t('system.send')"
                      :button-clear-title="$t('system.clear')"
                      @save="send"
                    />
                    <hr />
                  </CCollapse>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6" id="chat">
                  <div
                    v-for="item in items"
                    :key="item.id"
                    class="container"
                    :class="
                      (item.sender_type === 1 && xPap.role === 'M') ||
                      (item.sender_type === 2 && xPap.role === 'A')
                        ? 'sender-type-right'
                        : 'sender-type-left'
                    "
                  >
                    <div class="bubble">
                      <p class="sender">
                        {{
                          item.sender_type === 1
                            ? item.campaign_name
                            : item.affiliate_name
                        }}
                      </p>
                      <div class="body" v-html="item.body"></div>
                      <div v-if="item.seen_at === null">
                        <small
                          ><i class="fa fa-eye-slash" aria-hidden="true"></i
                        ></small>
                      </div>
                      <div v-else>
                        <small
                          ><i class="fa fa-eye" aria-hidden="true"></i>
                          {{ dateFormat(item.seen_at) }}</small
                        >
                      </div>
                    </div>
                    <p class="date">
                      <small
                        >{{ $t("system.sent") }}:
                        {{ dateFormat(item.created_at) }}</small
                      >
                    </p>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import crud from "@/store/modules/crud";
import WysiwygEditor from "@/components/ui/WysiwygEditor";
import i18n from "@/i18n";
import moment from "moment";
import AutoRefresh from "@/components/ui/AutoRefresh";

export default {
  name: "MessagingPapCreate",
  components: {
    AutoRefresh,
    WysiwygEditor
  },
  data() {
    return {
      loading: false,
      forbidden: false,
      isSubmitting: false,
      xPap: {
        userId: null,
        session: null,
        role: null
      },
      items: [],
      item: {
        campaignId: null,
        campaignName: null,
        affiliateName: null,
        affiliateId: null
      },
      collapse: false
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification",
      setLang: "auth/setLang"
    }),

    reloadPage() {
      window.location.reload();
    },

    dateFormat(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
    },

    getItem() {
      this.loading = true;
      let self = this;

      crud
        .get(
          "messaging/messages?filter[campaign_id]=" +
            this.$route.params.campaignId +
            "&filter[affiliate_id]=" +
            this.$route.params.affiliateId +
            "&sort=-created_at&per-page=100000",
          {
            headers: {
              "X-Pap-UserId": this.xPap.userId,
              "X-Pap-SessionId": this.xPap.session,
              "X-Pap-Role": this.xPap.role
            }
          }
        )
        .then(function(response) {
          self.items = response.data.items;
          if (response.data.items.length > 0) {
            self.item.campaignId = response.data.items[0].campaign_id;
            self.item.campaignName = response.data.items[0].campaign_name;
            self.item.affiliateId = response.data.items[0].affiliate_id;
            self.item.affiliateName = response.data.items[0].affiliate_name;
          }
          self.loading = false;
        });
    },

    send(editor) {
      let self = this;

      let obj = {
        campaign_id: this.item.campaignId,
        affiliate_id: this.item.affiliateId,
        body: editor
      };

      if (
        obj.campaign_id !== null &&
        obj.affiliate_id !== null &&
        obj.body !== null
      ) {
        crud
          .post("messaging/messages", obj, {
            headers: {
              "X-Pap-UserId": this.xPap.userId,
              "X-Pap-SessionId": this.xPap.session,
              "X-Pap-Role": this.xPap.role
            }
          })
          .then(function(response) {
            if (response.status >= 200 && response.status < 300) {
              self.$refs.editor.clear();
              self.collapse = false;
              self.getItem();

              self.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-add")
                },
                { root: true }
              );
            } else {
              this.addNotification({
                type: "danger",
                message: i18n.t("error.oops")
              });
            }
          })
          .catch(() => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          });
      } else {
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-form")
        });
      }
    }
  },
  created() {
    this.setLang();
  },
  mounted() {
    this.xPap.userId = this.$route.query.userId;
    this.xPap.session = this.$route.query.session;
    this.xPap.role = this.$route.query.role;
    this.getItem();
  }
};
</script>
