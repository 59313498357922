// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = [
  "556 134",
  `
<path fill="#FFFFFF" d="M150.127,31.936h-27.025c-10.295,0-17.02,6.862-17.02,18.73v37.897c0,6.433,1.575,11.295,4.865,14.586
\tc3,3.003,7.149,4.431,12.439,4.431h26.883c12.729,0,18.877-5.577,18.877-20.306V3.621h-19.02V31.936z M150.127,83.844
\tc0,2.29-1.426,4.573-4.717,4.573h-15.588c-2.432,0-5.007-1.714-5.007-4.004l0.146-27.881c0-1.718,0.716-3.149,2.145-4.291
\tc1.001-0.719,1.859-1.146,2.861-1.146h14.584c3.72,0,5.576,1.719,5.576,5.29V83.844z"/>
<path fill="#FFFFFF" d="M218.053,31.936h-25.454c-11.44,0-18.875,7.718-18.875,16.873v41.757c0,10.294,7.289,17.016,18.875,17.016
\th25.454c11.581,0,18.875-6.721,18.875-17.016V48.809C236.928,39.229,229.492,31.936,218.053,31.936 M218.053,83.128
\tc0,2.717-2.575,5.29-4.292,5.29h-16.729c-2.147,0-4.434-2.426-4.434-5.29V56.242c0-2.714,2.287-5.146,4.434-5.146h16.729
\tc1.858,0,4.292,2.863,4.292,5.146V83.128z"/>
<path fill="#FFFFFF" d="M299.847,34.652c-2.433-1.859-6.863-2.716-13.443-2.716h-25.308c-13.586,0-19.306,6.006-19.306,20.736
\tv37.461c0,10.298,7.291,17.447,19.161,17.447h24.74v3.292c0,3.287-1.862,5.862-5.723,5.862h-38.465v19.161h43.9
\tc11.869,0,19.306-7.006,19.306-19.449V50.094C304.709,42.229,303.135,37.082,299.847,34.652 M285.691,83.274
\tc0,3.287-2.006,5.144-4.865,5.144h-15.013c-1.857,0-5.148-2.426-5.148-5.144V56.1c0-2.284,2.714-5.004,4.862-5.004h15.16
\tc2.857,0,5.004,2.29,5.004,5.72V83.274z"/>
<path fill="#FFFFFF" d="M353.473,31.936H327.16c-11.583,0-17.877,7.005-17.877,18.019v57.627h18.878V56.386
\tc0-2.715,1.999-5.29,6.148-5.29h11.87c2.146,0,3.717,0.576,4.862,1.858c0.857,1.005,1.288,2.146,1.288,3.432v51.196h19.015V49.955
\tC371.344,38.514,365.342,31.936,353.473,31.936"/>
<path fill="#FFFFFF" d="M420.249,31.936h-25.741c-11.581,0-18.588,6.862-18.588,17.588v38.467c0,13.156,5.58,19.59,20.304,19.59
\th41.757V88.417h-36.751c-2.858,0-4.717-1.143-5.721-3.287c-0.283-0.714-0.424-1.717-0.424-3.29l43.04-16.445v-15.16
\tC438.125,37.651,432.691,31.936,420.249,31.936 M394.796,64.681v-7.719c0-3.291,3.433-4.862,6.151-4.862h13.297
\tc1.001,0,2.144,0.57,3.288,1.717c0.285,0.285,0.716,0.855,1.289,1.571L394.796,64.681z"/>
<polygon fill="#FFFFFF" points="473.463,31.985 473.443,31.985 473.443,31.936 460.574,31.936 460.574,3.621 441.696,3.621 
\t441.696,107.581 460.574,107.581 460.574,56.795 473.463,51.859 "/>
<path fill="#2F95A1" d="M61.284,31.019c-21.643,0-39.186,17.543-39.186,39.186c0,2.256,0.192,4.462,0.559,6.612
\tc1.158-1.84,11.995-18.054,32.518-15.095c0,0,6.692-12.965,16.731-11.498c10.04,1.459,9.201,9.824,8.574,11.291
\tc-0.625,1.463-4.81,5.438-8.993,1.879c-4.181-3.554-7.738-6.063-10.457-2.51c-2.718,3.559,2.51,8.785,5.231,11.503
\tc2.714,2.717,9.201,8.993,11.711,10.667c2.507,1.673,13.591,14.013-22.798,25.304l-0.713,0.442c2.215,0.389,4.497,0.594,6.823,0.594
\tc21.643,0,39.189-17.544,39.189-39.189C100.473,48.563,82.926,31.019,61.284,31.019"/>
<path fill="#2F95A1" d="M64.279,82.692c-4.755-2.598-16.643,0.863-18.803,8.859c-2.162,7.996,9.466,7.783,9.466,7.783
\ts3.935,0.212,9.552-3.892C70.115,91.335,69.032,85.284,64.279,82.692 M64.844,85.087c-0.165,0.771-1.304,1.183-2.538,0.917
\tc-1.235-0.26-2.105-1.101-1.943-1.872c0.169-0.774,1.301-1.185,2.538-0.92C64.136,83.475,65.006,84.317,64.844,85.087"/>
`
];
