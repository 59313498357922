<template>
  <div>
    <CForm @submit.prevent="submit">
      <CRow>
        <CCol lg="6">
          <CInput
            :label="$t('roles.roleName')"
            id="name"
            name="name"
            required="true"
            v-model="form.name"
            @keydown.space.prevent
          />
        </CCol>

        <CCol lg="6">
          <CInput
            :label="$t('system.description')"
            id="description"
            name="description"
            required="true"
            v-model="form.description"
          />
        </CCol>
      </CRow>

      <hr />

      <CButton
        v-if="isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
        ><CSpinner color="white" size="sm" v-if="isSubmitting" />
        {{ $t("system.update") }}</CButton
      >
      <CButton
        v-if="!isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
      >
        <CSpinner color="white" size="sm" v-if="isSubmitting" />
        {{ $t("crud.create") }}
      </CButton>
      <CButton size="sm" color="secondary" class="ml-2" to="/users/roles">{{
        $t("system.cancel")
      }}</CButton>
    </CForm>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
//import axios from "axios";
//import store from "@/store";
import i18n from "@/i18n";

export default {
  name: "UserCreate",
  data() {
    return {
      form: {
        name: "",
        description: "",
        type: 1,
        rule_name: null,
        data: null
      },
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getRole() {
      let self = this;
      crud
        .get("auth-roles/" + this.$route.params.name)
        .then(function(response) {
          self.form.name = response.data.name;
          self.form.description = response.data.description;
        });
    },

    meUser() {
      if (
        this.isEdit &&
        Number(this.$store.state.auth.user.id) === Number(this.$route.params.id)
      ) {
        this.myProfile = true;
      }
    },

    submit() {
      this.isSubmitting = true;

      if (this.isEdit) {
        crud
          .put("auth-roles/" + this.$route.params.name, this.form)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/users/roles/view/" + this.form.name);
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-updated")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.message
            });
          });
      } else {
        crud
          .post("auth-roles", this.form)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/users/roles/view/" + this.form.name);
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-add")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  computed: {
    isEdit() {
      return typeof this.$route.params.name === "string";
    }
  },
  created() {
    this.meUser();
    this.isEdit ? this.getRole() : "";
    //this.getRoles();
  }
};
</script>

<style>
.psw {
  padding-top: 28px;
}
</style>
