<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol lg="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("integration-process.step") }}: {{ step }} /
              {{ items.campaign_name }}
            </h4>
            <div class="small text-muted">
              {{ $t("menu.integration-process") }}
            </div>
          </CCol>
          <CCol lg="6" class="text-right pt-2">
            <CButton
              color="secondary"
              square
              size="sm"
              :to="{ path: '/integration-process/view/' + $route.params.id }"
            >
              {{ $t("system.cancel") }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CForm @submit.prevent="submit">
          <CRow>
            <CCol sm="4">
              <label for="status">{{ $t("system.status") }}</label>
              <select class="form-control" id="status" v-model="form.selected">
                <option
                  v-bind:value="index"
                  v-for="(value, index) in form.options"
                  v-bind:key="value"
                  >{{ $t("enum." + value) }}</option
                >
              </select>
            </CCol>
          </CRow>
          <br />
          <CButton
            type="submit"
            size="sm"
            color="success"
            :disabled="isSubmitting"
            >{{ $t("system.update") }}</CButton
          >
          <CButton
            size="sm"
            color="secondary"
            class="ml-2"
            :to="{ path: '/integration-process/view/' + $route.params.id }"
            >{{ $t("system.cancel") }}</CButton
          >
          <CSpinner
            v-if="this.isSubmitting === true"
            class="ml-2"
            color="info"
            size="sm"
          />
        </CForm>
        <br /><br />
        <Step
          :stepId.sync="itemsSteps.id"
          :status="itemsSteps.status"
          :campaign-name="items.campaign_name"
          :step-name="step"
        />
      </CCardBody>
      <template v-if="$route.params.type === '8' || $route.params.type === '15'">
      </template>
      <template v-else>
        <CCardFooter>
          <CButton
              size="sm"
              color="info"
              class="ml-2"
              :to="{ path: '/integration-process/view/' + $route.params.id }"
          >{{ $t("system.close") }}</CButton
          >
        </CCardFooter>
      </template>
    </CCard>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import i18n from "@/i18n";
import { mapActions } from "vuex";
import Step from "./IntegrationProcessStepsEditSteps.vue";

export default {
  name: "IntegrationProcessEditSteps",
  components: {
    Step
  },
  data() {
    return {
      isSubmitting: false,
      items: [],
      itemsSteps: [],
      step: JSON.parse(localStorage.getItem("vuex")).enumerators.enumerators[
        "step-type"
      ][this.$route.params.type],
      form: {
        selected: "",
        options: JSON.parse(localStorage.getItem("vuex")).enumerators
          .enumerators["step-status"]
      }
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getCampaignInfo() {
      let self = this;
      crud
        .get(
          "integration-process/campaigns/" +
            this.$route.params.id +
            "?expand=steps"
        )
        .then(function(response) {
          self.items = response.data;
          self.itemsSteps = response.data.steps.reduce((result, item) => {
            if (Number(item.type) === Number(self.$route.params.type)) {
              result = item;
            }
            return result;
          }, null);
          self.form.selected = self.itemsSteps.status;
          self.loading = false;
        });
    },

    submit() {
      this.isSubmitting = true;

      let data = { status: this.form.selected };

      crud
        .put("integration-process/steps/" + this.itemsSteps.id, data)
        .then(() => {
          this.isSubmitting = false;
          this.$router.push(
            "/integration-process/view/" + this.$route.params.id
          );
          this.addNotification(
            {
              type: "success",
              message: i18n.t("success.record-updated")
            },
            { root: true }
          );
        })
        .catch(error => {
          this.isSubmitting = false;
          this.addNotification({
            type: "danger",
            message: error.response.data[0].message
          });
        });

      this.isSubmitting = false;
    }
  },
  created() {
    this.getCampaignInfo();
  }
};
</script>

<style></style>
