<template>
  <div>
    <CForm @submit.prevent="isEdit ? beforeSubmit() : submit()">
      <CRow>
        <CCol lg="6" v-if="isEdit">
          <p>
            {{ $t("integration-process.campaign-id") }}:<br /><strong>{{
              active_campaign_id[0]
            }}</strong>
          </p>
          <p>
            {{ $t("integration-process.campaign-name") }}:<br /><strong>{{
              active_campaign_id[1]
            }}</strong>
          </p>
        </CCol>
        <CCol lg="6" v-else>
          <label for="campaign">{{
            $t("integration-process.campaign-id")
          }}</label>
          <v-select
            :options="select_options"
            label="name"
            v-model="form.selected"
            id="campaign"
            required
          ></v-select>
        </CCol>

        <CCol lg="6">
          <p>{{ $t("integration-process.type") }}</p>

          <div v-for="(value, key) in form.steps" v-bind:key="key">
            <CSwitch
              type="checkbox"
              :id="'switch' + key"
              color="info"
              shape="pill"
              :checked.sync="form.steps[key].checked"
            />
            <label :for="'switch' + key" class="switchCheckBox"
              ><strong>{{
                $t("integration-process." + value.name)
              }}</strong></label
            >
          </div>
        </CCol>
      </CRow>

      <hr />

      <CModal
        :title="$t('system.alert')"
        color="warning"
        :show.sync="warningModal"
      >
        <p>{{ $t("integration-process.modal-delete-alert-1") }}.</p>
        <p>{{ $t("integration-process.modal-delete-alert-2") }}.</p>
        <CInput v-model="deleteText" />
        <p v-if="deleteError">
          <span class="text-danger">{{ $t("error.oops") }}</span>
        </p>
        <CButton
          size="sm"
          color="success"
          :disabled="isSubmitting"
          v-on:click="submitDel"
          ><CSpinner color="white" size="sm" v-if="isSubmitting" />
          {{ $t("system.save") }}</CButton
        >
      </CModal>

      <CButton
        v-if="isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
        @click="warningModal = true"
        ><CSpinner color="white" size="sm" v-if="isSubmitting" />
        {{ $t("system.update") }}</CButton
      >
      <CButton
        v-if="!isEdit"
        type="submit"
        size="sm"
        color="success"
        :disabled="isSubmitting"
      >
        <CSpinner color="white" size="sm" v-if="isSubmitting" />
        {{ $t("crud.create") }}
      </CButton>
      <CButton
        size="sm"
        color="secondary"
        class="ml-2"
        to="/integration-process"
        >{{ $t("system.cancel") }}</CButton
      >
    </CForm>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import i18n from "@/i18n";

export default {
  name: "IntegrationProcessCreate",
  props: ["isEdit"],
  components: {
    vSelect
  },
  data() {
    return {
      warningModal: false,
      deleteText: "",
      deleteError: false,
      form: {
        selected: [],
        steps: []
      },
      active_campaign_id: [],
      select_options: [],
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getActiveCampaigns() {
      let self = this;
      crud
        .get("integration-process/campaigns?per-page=100000")
        .then(function(response) {
          for (const key of Object.values(response.data.items)) {
            self.active_campaign_id.push(key.campaign_id);
          }
        });
    },

    getCampaignByID() {
      let self = this;
      crud
        .get("integration-process/campaigns/" + this.$route.params.id)
        .then(function(response) {
          self.active_campaign_id.push(
            response.data.campaign_id,
            response.data.campaign_name
          );
          self.getStepsByID();
        });
    },

    getStepsByID() {
      let self = this;
      crud
        .get(
          "integration-process/steps?filter[campaign_id]=" +
            this.$route.params.id
        )
        .then(function(response) {
          let arr = [];
          let temp = [];

          for (const key of Object.values(response.data.items)) {
            arr.push(key.type);
          }
          for (const key of self.form.steps) {
            if (arr.includes(Number(key.type))) {
              temp.push({ type: key.type, name: key.name, checked: true });
            } else {
              temp.push({ type: key.type, name: key.name, checked: false });
            }
          }
          self.form.steps = temp;
        });
    },

    getPapCampaign() {
      let self = this;
      crud
        .get(
          "pap/campaigns?filter[rstatus][in][]=A&filter[rstatus][in][]=W&&fields=id,name&per-page=100000"
        )
        .then(function(response) {
          for (const key of Object.values(response.data.items)) {
            if (!self.active_campaign_id.includes(key.id)) {
              self.select_options.push(key);
            }
          }
        });
    },

    getType() {
      let val = this.steps;
      if (val) {
        this.form.steps = [];
        Object.entries(val).forEach(type => {
          this.form.steps.push({ type: type[0], name: type[1], checked: true });
        });
      }
    },

    beforeSubmit() {
      this.warningModal = true;
    },
    submitDel() {
      if (this.deleteText === "UPDATE") {
        this.submit();
        this.deleteError = false;
      } else {
        this.deleteError = true;
      }
    },

    submit() {
      this.isSubmitting = true;

      if (this.isEdit) {
        let data = new URLSearchParams();
        data.append("campaign_id", this.active_campaign_id[0]);

        Object.values(this.form.steps).forEach(value => {
          if (value.checked) {
            data.append("steps[]", value.type);
          }
        });

        crud
          .put("integration-process/campaigns/" + this.$route.params.id, data)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/integration-process");
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-updated")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.response.data[0].message
            });
          });
      } else {
        if (this.form.selected.id) {
          let data = new FormData();

          data.append("campaign_id", this.form.selected.id);

          Object.values(this.form.steps).forEach(value => {
            if (value.checked) {
              data.append("steps[]", value.type);
            }
          });

          crud
            .post("integration-process/campaigns", data)
            .then(() => {
              this.isSubmitting = false;
              this.$router.push("/integration-process");
              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-add")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;
              this.addNotification({
                type: "danger",
                message: error.response.data[0].message
              });
            });
        } else {
          this.isSubmitting = false;
          this.addNotification({
            type: "danger",
            message: i18n.t("error.select-box-empty")
          });
        }
      }
    }
  },
  mounted() {
    this.getType();
  },
  watch: {
    steps() {
      this.getType();
    }
  },
  computed: {
    steps() {
      return this.$store.state.enumerators.enumerators["step-type"];
    }
  },
  created() {
    this.isEdit ? this.getCampaignByID() : this.getActiveCampaigns();
    this.getPapCampaign();
  }
};
</script>

<style>
.switchCheckBox {
  padding-left: 10px;
  font-size: 16px;
  display: inline-block;
  position: relative;
  top: -7px;
  cursor: pointer;
}
.modal-footer .btn-warning {
  display: none;
}
</style>
