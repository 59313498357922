import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import minimalize from "./modules/minimalize";
import crud from "./modules/crud";
import enumerators from "./modules/enumerators";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["enumerators"]
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    notifications,
    minimalize,
    crud,
    enumerators
  },
  plugins: [vuexLocal.plugin]
});
